/* eslint-disable promise/param-names */

import {
PRESENCE_GET_WEEK,
PRESENCE_SET_DAY,
} from './mutations-types'
import Api from '../../src/api'

const state = {
}

const getters = {
}

const actions = {

  // Process

  [PRESENCE_GET_WEEK]:({}, data) => {
    return Api.presence.index(data.worldId, data.year, data.week)
    .then(resp => {
      return resp.data.success
    })
  },

  [PRESENCE_SET_DAY]:({}, data) => {
    return Api.presence.set(data.worldId, data)
    .then(resp => {
      return resp.data.success
    })
  },

}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
