import store from './store'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

let locale = 'fr'
if (store.getters['auth/getProfile'] && store.getters['auth/getProfile'].language_code) {
    locale = store.getters['auth/getProfile'].language_code
}
else if (localStorage.getItem('user-language') && localStorage.getItem('user-language') !== "undefined") {
    locale = localStorage.getItem('user-language')
}

if (locale.length === 2) {
  locale = `${locale}`
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    let keyPath = key.split('/')
    keyPath.shift()
    let fileName = keyPath[keyPath.length - 1]
    let lang = fileName.split('.')[0]

    if (keyPath.length == 1) {
      messages[lang] = locales(key)
    } else if (keyPath[0] === 'modules') {
      if (!messages[lang][keyPath[0]]) {
        messages[lang][keyPath[0]] = {}
      }
      messages[lang][keyPath[0]][keyPath[1]] = locales(key)
    }
  })

  // Load other modules local files
  const requireLocales = require.context(
    // The relative path of the folder
    '../Modules',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /locales\/[\w-]+\.json$/
  )

  requireLocales.keys().forEach(fileName => {
    // Get content
    const translation = requireLocales(fileName)
    const moduleName = _.camelCase(fileName.split('/')[1])
    const lang = fileName.split('/')[fileName.split('/').length - 1].replace('.json', '')

    if (!messages[lang]) {
        messages[lang] = {}
    }
    if (!messages[lang]['modules']) {
        messages[lang]['modules'] = {}
    }
    messages[lang]['modules'][moduleName] = translation
  })


  return messages
}

Vue.use(VueI18n)

export default new VueI18n({
  silentTranslationWarn: true,
  locale: locale,
  fallbackLocale: 'en',
  postTranslation: (str, key) => {
      let overridenTranslations = store.getters['global/getTranslations']
      if (overridenTranslations && overridenTranslations[locale] && overridenTranslations[locale][key]) {
          return overridenTranslations[locale][key]
      }
      return str
  },
  messages: loadLocaleMessages(),
  dateTimeFormats: {
    'en': {
      classicText: {
        weekday: 'long',
        month: 'long',
        year: 'numeric',
        day: 'numeric'
      },
      classicTextWoYear: {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      },
      shortText: {
        month: 'short',
        year: '2-digit',
        day: 'numeric'
      },
      shortTextWoYear: {
        month: 'short',
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      },
      shorttime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    },
    'fr': {
      classicText: {
        weekday: 'long',
        month: 'long',
        year: 'numeric',
        day: 'numeric'
      },
      classicTextWoYear: {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      },
      shortText: {
        month: 'short',
        year: '2-digit',
        day: 'numeric'
      },
      shortTextWoYear: {
        month: 'short',
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      shorttime: {
        month: 'long',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  },
  numberFormats: {
    'en': {
      currency: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }
    },
    'fr': {
      currency: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
      }
    }
  }
})
