import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// Icons
import './assets/icons/icomoon/style.scss'

// Limitless
import './assets/global_assets/scss/layouts/layout_1/default/compile/global.scss'

require('bootstrap')

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_DSN != '') {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
}

Vue.config.productionTip = false

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import TableComponent from 'vue-table-component';
Vue.use(TableComponent);

import VCalendar from 'v-calendar';
Vue.use(VCalendar)

import ImageUploader from 'vue-image-upload-resize'
Vue.use(ImageUploader);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueTextareaAutosize from 'vue-textarea-autosize'
Vue.use(VueTextareaAutosize)

import InputTag from 'vue-input-tag'
Vue.component('input-tag', InputTag)

import vueKanban from 'vue-kanban'
Vue.use(vueKanban)

import SlideUpDown from 'vue-slide-up-down'
Vue.component('slide-up-down', SlideUpDown)

import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);

import Snotify, { SnotifyPosition } from 'vue-snotify'
Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 2000
  }
})

import _ from 'lodash'

import VueAnalytics from 'vue-analytics'
if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID && process.env.VUE_APP_GOOGLE_ANALYTICS_ID != '') {
    Vue.use(VueAnalytics, {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      checkDuplicatedScript: true,
      router: router,
      debug: {
        sendHitTask: process.env.NODE_ENV === 'production'
      }
    })
}
import VueMatomo from 'vue-matomo'

if (
    process.env.VUE_APP_MATOMO_SITE_ID && process.env.VUE_APP_MATOMO_SITE_ID != '' &&
    process.env.VUE_APP_MATOMO_TRACKER_URL && process.env.VUE_APP_MATOMO_TRACKER_URL != ''
) {
    Vue.use(VueMatomo, {
        // Configure your matomo server and site by providing
        host: process.env.VUE_APP_MATOMO_TRACKER_URL,
        siteId: process.env.VUE_APP_MATOMO_SITE_ID,

        // Changes the default .js and .php endpoint's filename
        // Default: 'matomo'
        // trackerFileName: 'matomo',

        // Overrides the autogenerated tracker endpoint entirely
        // Default: undefined
        // trackerUrl: process.env.VUE_APP_MATOMO_TRACKER_URL,

        // Enables automatically registering pageviews on the router
        router: router,

        // Enables link tracking on regular links. Note that this won't
        // work for routing links (ie. internal Vue router links)
        // Default: true
        enableLinkTracking: true,

        // Require consent before sending tracking information to matomo
        // Default: false
        requireConsent: false,

        // Whether to track the initial page view
        // Default: true
        trackInitialView: true,

        // Run Matomo without cookies
        // Default: false
        // disableCookies: false,

        // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
        // Default: false
        // enableHeartBeatTimer: false,

        // Set the heartbeat timer interval
        // Default: 15
        // heartBeatTimerInterval: 15,

        // Whether or not to log debug information
        // Default: false
        debug: process.env.NODE_ENV !== 'production',

        // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
        // Default: undefined
        userId: store.getters['auth/isAuthenticated'] ? store.getters['auth/getProfile'].id : undefined
    });
}


// Global component registration
// Allow to sub-use them
let requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /_[\w-]+\.vue$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  let tag = componentConfig.default.tag

  // Register component globally
  Vue.component(
    tag,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

// Module component registration
// Allow to sub-use them
requireComponent = require.context(
  // The relative path of the components folder
  '../Modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /components\/_[\w-]+\.vue$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  let tag = componentConfig.default.tag

  // Register component globally
  Vue.component(
    tag,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

// Layouts
import Anonymous from './layouts/Anonymous.vue'
Vue.component('anonymous-layout', Anonymous)
import Auth from './layouts/Auth.vue'
Vue.component('auth-layout', Auth)
import Authfake from './layouts/Authfake.vue'
Vue.component('authfake-layout', Authfake)

import i18n from './i18n'

import moment from 'moment-timezone';

if (store.getters['auth/getProfile']) {
  if (store.getters['auth/getProfile'].language_code) {
    moment.locale(store.getters['auth/getProfile'].language_code)
  }
  if (store.getters['auth/getProfile'].timezone_id) {
    moment.tz.setDefault(store.getters['auth/getProfile'].timezone_id)
  }
}

import VueMoment from 'vue-moment';
Vue.use(VueMoment, {
    moment
});

const $ = require('jquery')

Vue.mixin({
  filters: {
    round: function(value) {
        return Math.round(value)
    },
    filesize: function(value) {
      if (!value) {
        return 0
      }
      let s = value / (1024 * 1024)
      if (parseInt(s, 10) > 0) {
        return s.toFixed(1) + 'M'
      } else {
        return (value / 1024).toFixed(1) + 'Kb'
      }
    }
  },
  computed: {
    alreadyOnboarded () {
      return this.$store.getters['auth/getProfile'].metadata && this.$store.getters['auth/getProfile'].metadata.onboarding && this.$store.getters['auth/getProfile'].metadata.onboarding.includes(this.currentRouteName)
    },
    alreadyOnboardedGlobal () {
      return this.$store.getters['auth/getProfile'].metadata && this.$store.getters['auth/getProfile'].metadata.onboarding && this.$store.getters['auth/getProfile'].metadata.onboarding.includes('*')
    },
    currentRouteName () {
      let routeName = this.$route.name
      if (routeName == 'module.index') {
        routeName = `module.${this.$route.params.module}.index`
      }
      return routeName
    },
    isAuthFake () {
      return this.$route.meta && this.$route.meta.layout && this.$route.meta.layout == 'authfake'
    },
  },
  methods: {
    getBanners (page, placement = null) {
      if (!store.getters['auth/hasWorld']) {
        return null
      }
      let banners = this.$store.getters['global/getBanners']
      if (!banners) {
        return null
      }

      if (!placement) {
        if (!banners[page] || banners[page].length == 0) {
          return null
        }
        return banners[page]
    } else {
        if (!banners[page] || !banners[page][placement] || banners[page][placement].length == 0) {
          return null
        }
        return banners[page][placement]
      }
    },
    currency (price, format = false, suffix = true) {
      if (!format) {
        format = this.$root.$i18n.locale
      }
      let formattedPrice = this.$n(price, 'currency', format)
      formattedPrice.replace('')
      if (format === 'fr' && suffix) {
        formattedPrice += '<small>&nbsp;HT</small>'
      }
      return formattedPrice
    },
    pluralize(str, count) {
      if (count && count > 1 && str.slice(-1) != 's') {
          str+= 's'
      }
      return str.replace('Attraction', 'Module')
    },
    isAuth (realAuth = false) {
      if (realAuth) {
        // If user owns the world, need phone to be verified
        let phoneOK = false
        if (this.$store.getters['auth/isWorldOwner'] === false) {
          phoneOK = true
        } else if (this.$store.getters['auth/isWorldOwner'] && this.$store.getters['auth/isPhoneVerified']) {
          phoneOK = true
        }
        return this.$store.getters['auth/isAuthenticated'] && phoneOK;
      } else {
        return this.$store.getters['auth/isAuthenticated'];
      }
    },
    isProfileLoaded () {
      return this.$store.getters['auth/isProfileLoaded'];
    },
    userCanManageTribe (tribeId) {
      if (!this.isProfileLoaded()) {
        return false
      }
      else if (this.$store.getters['auth/isWorldOwner']) {
        return true
      }
      else if (this.$store.getters['auth/getProfile'].tribes.find((element) => {
        return element.id == tribeId && element.pivot.owner == 1
      })) {
        return true
      }
    },
    userIsInTribe (tribeId) {
      if (!this.isProfileLoaded()) {
        return false
      }
      else if (this.$store.getters['auth/isWorldOwner']) {
        return true
      }
      else if (this.$store.getters['auth/getProfile'].tribes.find((element) => {
        return element.id == tribeId
      })) {
        return true
      }
    },
    userTribes (userId = null) { // If null, set it to current
      if (!this.isProfileLoaded()) {
        return []
      }
      let profile
      if (userId === null) {
          profile = this.$store.getters['auth/getProfile']
      } else {
          let profileIndex = _.findIndex(this.$store.getters['world/getUsers'], ['id', userId])
          if (!profileIndex) {
              return null
          }
          profile = this.$store.getters['world/getUsers'][profileIndex]
      }
      return profile.tribes.map((element) => {
        return element.id
      })
    },
    userHasPermission (module, permission, userId = null) {
      let profile

      if (this.$store.getters['auth/isWorldInternal']) {
        let profileIndex = userId ? _.findIndex(this.$store.getters['world/getUsers'], ['id', userId]) : _.findIndex(this.$store.getters['world/getUsers'], ['id', this.$store.getters['auth/getId']])
        if (profileIndex === false || profileIndex < 0 || !this.$store.getters['auth/getWorld']) {
            return false
        }
        profile = this.$store.getters['world/getUsers'][profileIndex]
        if (profile.pivot.owner == true) {
          return true
        }
      } else {
        profile = this.$store.getters['auth/getProfile']
      }

      let permissions = this.$store.getters['auth/getWorld'].permissions

      if (!permissions[module]) {
        return false
      }

      let hasPermission = false
      let userTribesToCheck = this.userTribes(userId)
      if (userTribesToCheck) {
        userTribesToCheck.forEach( (tribeId) => {
          if (permissions[module][tribeId]) {
            if (permissions[module][tribeId][permission]) {
              if (permissions[module][tribeId][permission] === true) {
                hasPermission = true
              }
            }
          }
        });
      }

      return hasPermission
    },
    renderUsername (profile) {
      let username = ''
      if (profile.first_name) {
        username+= profile.first_name
      }
      if (profile.last_name) {
        username+= ' ' + profile.last_name
      }
      if (username == '' && profile.email) {
        username = profile.email
      }
      return username;
    },
    openLink (url) {
      window.open(url)
    },
    openModal (id, cantClose = false) {
      let opts = 'show'
      if (cantClose) {
        opts = {backdrop: 'static', keyboard: false}
      }
      $('#' + id).modal(opts)
      this.$store.commit('global/openModal', id)
    },
    closeModal (id) {
      $('#' + id).modal('hide')
      this.$store.commit('global/openModal', null)
    },
    closeAllModals () {
      $('.modal').modal('hide')
    },
    closeAllModalsButMandatory () {
      $('.modal')
          .not('[id^="banner-onboarding-"]')
          .not('[id^="auth-fake-modal"]')
          .modal('hide')
    },
    notif (type, data) {
      // console.info({
      //   content: data.content ? data.content : null,
      //   html: data.html ? data.html : null,
      //   title: data.title ? data.title : null,
      //   options: data.options ? data.options : null
      // })
      this.$snotify[type](
        data.content ? data.content : null,
        data.title ? data.title : null,
        data.options ? data.options : null
      )
    },
    notifSuccess (message, timeout = null, buttons = []) {
      if (!timeout) {
        timeout = message.length * 90
      }
      this.notif('success', {
        content: message,
        options: {
          buttons: buttons,
          timeout: timeout,
        }
      })
    },
    notifInfo (message, timeout = null, buttons = []) {
      if (!timeout) {
        timeout = message.length * 90
      }
      this.notif('info', {
        content: message,
        options: {
          buttons: buttons,
          timeout: timeout,
        }
      })
    },
    notifError (errors) {
      if (!errors.response) {
          this.notif('error', {
            content: 'ERROR',
          })
      }
      else if (errors.response.data.errors && errors.response.data.errors.data) {
        _.toArray(errors.response.data.errors.data).forEach( element => {
          this.notif('error', {
            content: element[0],
            options: {
              timeout: (element[0].length * 90),
            }
          })
        })
      }
      else if (errors.response.data.errors) {
        _.toArray(errors.response.data.errors).forEach( element => {
          this.notif('error', {
            content: element[0],
            options: {
              timeout: (element[0].length * 90)
            }
          })
        })
      } else if (errors.response.data.message) {
        this.notif('error', {
          content: errors.response.data.message,
          options: {
            timeout: (errors.response.data.message.length * 90)
          }
        })
      }
    },
    getFilterOptions (object, filterKey, filterLabels = {}, emptyTitle = null) {
      let filterOptions = {}
      const currentFilters = this.$store.getters['global/currentFilters']

      _.forEach(object, (value, k) => {

        // Transform everything in array to process thr same way
        if (!Array.isArray(value[filterKey])) {
          value[filterKey] = [_.cloneDeep(value[filterKey])]
        }

        _.forEach(value[filterKey], (optionKey) => {

          if (optionKey === null || optionKey === '') {
            optionKey = '_'
          }

          if (!filterOptions[optionKey]) {
            let title = this.$t('common.empty')
            if (emptyTitle) {
                title = emptyTitle
            }
            if (optionKey !== '_' && optionKey != false) {
                if (filterLabels[optionKey] && filterLabels[optionKey] != '') {
                    title = filterLabels[optionKey]
                } else {
                    title = optionKey
                }
            }
            let value = currentFilters[filterKey] && currentFilters[filterKey].includes(optionKey)
            if(title && title != '') {
                filterOptions[optionKey] = {
                  raw_values: optionKey !== '_' ? [optionKey] : ['', null],
                  title: title,
                  count: 1,
                  value: value
                }
            }
          } else {
            filterOptions[optionKey].count++
          }

        })
      })

      return _.sortBy(filterOptions, ['count']).reverse()
    },
    applyDataFilters (data, filters) {
      let filteredData = _.cloneDeep(data)

      let activeFilters = {}

      _.forEach(filters, (filter) => {
        let tmp = _.filter(filter.options, 'value', true)
        let activeValues = []
        _.forEach(_.map(tmp, 'raw_values'), (item) => {
          _.forEach(item, (itemInner) => {
            activeValues.push(itemInner)
          })
        })

        activeFilters[filter.key] = activeValues

        if (activeValues.length > 0) {
          filteredData = _.filter(filteredData, item => {
            // Transform everything in array to process thr same way
            let values = _.cloneDeep(item[filter.key])
            if (!Array.isArray(values)) {
              values = [values]
            }
            let result = false
            _.forEach(values, val => {
              if (_.includes(activeValues, val)) {
                result = true
              }
            })
            return result
          });
        }

      })
      this.$store.commit('global/changeSidebarFilters', activeFilters)
      return filteredData
    },
    nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    isApp () {
        return typeof process.env.CORDOVA_PLATFORM !== 'undefined'
    },
    hasStripeKey () {
        return typeof process.env.VUE_APP_STRIPE_KEY !== 'undefined' && process.env.VUE_APP_STRIPE_KEY != ''
    },
    getStripeKey () {
        if (!this.hasStripeKey()) {
            return null
        }
        return process.env.VUE_APP_STRIPE_KEY
    },
  }
})

import V_Echarts from 'vue-echarts-directive';

let vueApp = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  directives: {
    'echarts': V_Echarts,
  },
  render: h => h(App)
}).$mount('#app')

// Loading Hubspot Script
if (process.env.VUE_APP_HUBSPOT_ID && process.env.VUE_APP_HUBSPOT_ID != '') {
    var script = document.createElement('script');
    // script.onload = function () {
        //do stuff with the script
    // };
    script.src = `//js.hs-scripts.com/${process.env.VUE_APP_HUBSPOT_ID}.js`;

    document.head.appendChild(script); //or something of the likes
}

export default vueApp

