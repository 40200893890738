/* eslint-disable promise/param-names */

import {
HOSTINGS_REQUEST,
HOSTING_GET,
HOSTING_STORE,
HOSTING_UPDATE,
HOSTING_REMOVE,
HOSTING_BACKUP_NEW,
HOSTING_REVISIONS,
} from './mutations-types'
import Api from '../../src/api'
import Vue from 'vue'
import _ from 'lodash'

const state = {
}

const getters = {
}

const actions = {
  [HOSTINGS_REQUEST]: ({commit}, worldId) => {
    return Api.hosting.list(worldId)
      .then(resp => {
        return resp.data.success.data
      })
  },
  [HOSTING_STORE]: ({}, data) => {
    return Api.hosting.add(data)
      .then(resp => {
        return resp.data.success
      })
  },
  [HOSTING_GET]: ({}, data) => {
    return Api.hosting.get(data.world_id, data.id)
      .then(resp => {
        return resp.data.success
      })
  },
  [HOSTING_REVISIONS]: ({}, data) => {
    let page = 1
    if (data.page) {
        page = data.page
    }
    return Api.hosting.revisions(data.world_id, data.id, page)
      .then(resp => {
        return resp.data.success
      })
  },
  [HOSTING_UPDATE]: ({commit}, data) => {
    return Api.hosting.edit(data)
      .then(resp => {
        return resp.data.success
      })
  },
  // [HOSTING_REMOVE]: ({}, data) => {
  //   return Api.hosting.remove(data.worldId, data.hostingId)
  //     .then(resp => {
  //       return resp.data.success
  //     })
  // },
  [HOSTING_BACKUP_NEW]: ({}, data) => {
    return Api.hosting.backup_add(data)
      .then(resp => {
        return resp.data.success
      })
  },


}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
