<template>

  <div class="card text-center py-2">
    <div class="card-header">
      <el-card-title :title="$t('moduleentity.needaccess.title')" icon="icon-lock2" />
    </div>
    <div class="card-body">
      <p class="mb-3">{{ $t('moduleentity.needaccess.text') }}</p>
      <div class="btn btn-success" v-if="!asked" @click="askAccess()">
        <span v-if="!loading"><i class="icon-unlocked2"></i> {{ $t('moduleentity.needaccess.btn') }}</span>
        <loader v-else />
      </div>
      <div v-else class="font-weight-bold text-success">
        {{ $t('moduleentity.needaccess.asked') }}
      </div>
    </div>
  </div>

</template>

<script>


import {
  GLOBAL_ACCESS,
} from '@/store/mutations-types'

export default {
  tag: 'module-entity-need-access',
  name: 'ModuleEntityNeedAccess',
  props: {
    entityId: {
      type: Number,
      default: null, // if null = request module access
    },
    entityModule: {
      required: true,
      type: String,
    },
    entityType: {
      type: String,
      default: null, // if null = request module access
    },
  },
  data () {
    return {
      asked: false,
      loading: false,
    }
  },
  mounted () {
  },
  methods: {
    askAccess () {

      if (this.loading) {
        return
      }

      this.loading = true

      this.$store.dispatch('global/' + GLOBAL_ACCESS, {
        user_id: this.currentUser.id,
        world_id: this.currentWorld.id,
        entity: this.entityType,
        entity_id: this.entityId,
        module: this.entityModule,
      }).then(response => {
        this.asked = true
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
  },
  computed: {
    currentWorld () {
      return this.$store.getters['auth/getWorld']
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    isLoading () {
      return this.loading
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
