export const PARTNERSHIP_USERS_REQUEST = 'Partnership Request'
export const PARTNERSHIP_STORE = 'Partnership Store'
export const PARTNERSHIP_UPDATE = 'Partnership Update'
export const PARTNERSHIP_MAIL = 'Partnership Email'
export const PARTNERSHIP_VERIFY_TOKEN = 'Partnership Register'
export const PARTNERSHIP_UPDATE_CLIENT = 'Partnership Update Client'
export const PARTNERSHIP_REMOVE = 'Partnership remove Client'
export const PARTNERSHIP_PROFILE = 'Partnership profile'
export const PARTNERSHIP_PROFILE_REQUEST = 'Partnership profile request'
export const PARTNERSHIP_PREFERENCE_ANNUAIRE = 'Partnership preference annuaire'
export const PARTNERSHIP_PREFERENCE_ANNUAIRE_STORE = 'Partnership preference annuaire STORE AND UPDATE'
export const PARTNERSHIP_MRR_REQUEST = 'Partnership request get MRR vente et gestion'
export const CURRENCIE_DATA = 'Get currencie for world '
