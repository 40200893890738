import { render, staticRenderFns } from "./_hosting-header.vue?vue&type=template&id=13309b78&scoped=true&"
import script from "./_hosting-header.vue?vue&type=script&lang=js&"
export * from "./_hosting-header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13309b78",
  null
  
)

export default component.exports