export const PROCESS_GET_PROCESSTEMPLATES = 'PROCESS_GET_PROCESSTEMPLATES'
export const PROCESS_GET_PROCESSTEMPLATE = 'PROCESS_GET_PROCESSTEMPLATE'
export const PROCESS_GET_PROCESSTEMPLATERESULTS = 'PROCESS_GET_PROCESSTEMPLATERESULTS'
export const PROCESS_CREATE_PROCESSTEMPLATE = 'PROCESS_CREATE_PROCESSTEMPLATE'
export const PROCESS_UPDATE_PROCESSTEMPLATE = 'PROCESS_UPDATE_PROCESSTEMPLATE'
export const PROCESS_ADD_MEDIA_PROCESSTEMPLATE = 'PROCESS_ADD_MEDIA_PROCESSTEMPLATE'

export const PROCESS_GET_PROCESSS = 'PROCESS_GET_PROCESSS'
export const PROCESS_GET_PROCESS = 'PROCESS_GET_PROCESS'
export const PROCESS_CREATE_PROCESS = 'PROCESS_CREATE_PROCESS'
export const PROCESS_UPDATE_PROCESS = 'PROCESS_UPDATE_PROCESS'



export const PROCESS_GET_FORMTEMPLATES = 'PROCESS_GET_FORMTEMPLATES'
export const PROCESS_GET_FORMTEMPLATE = 'PROCESS_GET_FORMTEMPLATE'
export const PROCESS_GET_FORMTEMPLATERESULTS = 'PROCESS_GET_FORMTEMPLATERESULTS'
export const PROCESS_CREATE_FORMTEMPLATE = 'PROCESS_CREATE_FORMTEMPLATE'
export const PROCESS_UPDATE_FORMTEMPLATE = 'PROCESS_UPDATE_FORMTEMPLATE'
export const PROCESS_ADD_MEDIA_FORMTEMPLATE = 'PROCESS_ADD_MEDIA_FORMTEMPLATE'

export const PROCESS_GET_FORMS = 'PROCESS_GET_FORMS'
export const PROCESS_GET_FORM = 'PROCESS_GET_FORM'
export const PROCESS_CREATE_FORM = 'PROCESS_CREATE_FORM'
export const PROCESS_UPDATE_FORM = 'PROCESS_UPDATE_FORM'
