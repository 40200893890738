import actions from './actions'
import store from '../store'
import i18n from '@/i18n'

// Global routes
let routes = [
  {
    path: '/',
    redirect: to => {

      let isAuthenticated = store.getters['auth/isAuthenticated']
      if (!isAuthenticated) {
        return 'login'
      }

      let isWorldOwner = store.getters['auth/isWorldOwner']
      let worldHasSubscription = store.getters['auth/worldHasSubscription']
      let worldOnTrial = store.getters['auth/worldOnTrial']

      let world = store.getters['auth/getWorld']

      if (world.id && world.modules && world.modules.actionsboard) {
        return `/world/${world.id}/actionsboard/actions`
      }if (world.id && world.modules && world.modules.actionsboard) {
        return `/world/${world.id}/actionsboard/actions`
      } else if (isWorldOwner && world.modules.quest) {
        return `/world/${world.id}/quest`
      } else {
        return `/world/${world.id}/modules`
      }

    },
    name: 'Home',
    component: () => import(/* webpackChunkName: "app-global" */ '@/pages/Index.vue'),
    beforeEnter: actions.needAuthComplete,
  },
  {
    path: '/error/401',
    name: 'error.401',
    component: () => import(/* webpackChunkName: "app-global" */ '@/pages/error/401.vue'),
    beforeEnter: actions.needAuthComplete,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "app-anonymous" */ '@/pages/auth/LoginForm.vue'),
    beforeEnter: actions.needAnonymous,
    meta: {
      title: i18n.t('user.login.login_title'),
      layout: 'authfake'
    }
  },
  {
    path: '/login/password',
    name: 'Password Reset',
    component: () => import(/* webpackChunkName: "app-anonymous" */ '@/pages/auth/PasswordResetForm.vue'),
    beforeEnter: actions.needAnonymous,
    meta: {
      title: i18n.t('user.password.title'),
      layout: 'authfake'
    }
  },
  {
    path: '/login/password/:token',
    name: 'Password Renew',
    component: () => import(/* webpackChunkName: "app-anonymous" */ '@/pages/auth/PasswordRenewForm.vue'),
    beforeEnter: actions.needAnonymous,
    meta: {
      title: i18n.t('user.password.title_new'),
      layout: 'authfake'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "app-anonymous" */ '@/pages/auth/RegisterForm.vue'),
    beforeEnter: actions.needAnonymous,
    meta: {
      title: i18n.t('user.register.title'),
      layout: 'authfake'
    }
  },
  {
    path: '/phone_verification',
    name: 'Phone Verification',
    component: () => import(/* webpackChunkName: "app-global" */ '@/pages/auth/PhoneVerificationForm.vue'),
    beforeEnter: actions.needAuthWithoutPhone,
    meta: {
      title: i18n.t('user.phone_verification.title'),
      layout: 'anonymous'
    }
  },
  {
    path: '/profile',
    name: 'user.profile',
    component: () => import(/* webpackChunkName: "app-authenticated" */ '@/pages/user/ProfileForm.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('titles.user_profile'),
      icon: 'user'
    }
  },
  {
    path: '/world/:world/modules',
    name: 'attractions.index',
    component: () => import(/* webpackChunkName: "app-authenticated" */ '@/pages/world/AttractionsList.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('common.attractions'),
      icon: 'grid5'
    }
  },
  {
    path: '/world/:world/notifications',
    name: 'notifications.index',
    component: () => import(/* webpackChunkName: "app-authenticated" */ '@/pages/world/NotificationsPage.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('notifications.title'),
      icon: 'bell3'
    }
  },
  {
    path: '/security',
    name: 'user.security',
    component: () => import(/* webpackChunkName: "app-settings" */ '@/pages/user/SecurityForm.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('titles.user_security'),
      icon: 'lock2'
    }
  },
  {
    path: '/world/:world/settings',
    name: 'world.settings',
    component: () => import(/* webpackChunkName: "app-settings" */ '@/pages/world/SettingsForm.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('titles.world_settings'),
      icon: 'cogs'
    }
  },
  {
    path: '/world/:world/users',
    name: 'world.users',
    component: () => import(/* webpackChunkName: "app-world" */ '@/pages/world/UsersList.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('titles.world_users'),
      icon: 'users4',
    }
  },
  {
    path: '/world/:world/users/add',
    name: 'world.users.add',
    component: () => import(/* webpackChunkName: "app-world" */ '@/pages/world/UserCreateForm.vue'),
    beforeEnter: actions.needWorldOwner,
    meta: {
      title: i18n.t('user.list.create_user'),
      icon: 'users4',
    }
  },
  {
    path: '/world/:world/users/:user',
    name: 'world.users.profile',
    component: () => import(/* webpackChunkName: "app-settings" */ '@/pages/world/ProfilePage.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('titles.world_users'),
      icon: 'user',
      sidebar: true,
    }
  },
  {
    path: '/world/:world/subscription',
    name: 'world.subscription',
    component: () => import(/* webpackChunkName: "app-world" */ '@/pages/world/Subscription.vue'),
    beforeEnter: actions.needWorldOwner,
    meta: {
      title: i18n.t('titles.world_subscription'),
      icon: 'credit-card',
    }
  },
  {
    path: '/world_invitation/:token',
    name: 'World Invitation',
    component: () => import(/* webpackChunkName: "app-world" */ '@/pages/world_invitation/Action.vue'),
    meta: {
      layout: 'anonymous'
    }
  },
  {
    path: '/world/:world/tribes',
    name: 'world.tribes',
    component: () => import(/* webpackChunkName: "app-world" */ '@/pages/world/TribesList.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('titles.world_tribes'),
      icon: 'collaboration',
    }
  },
  {
    path: '/world/:world/permissions',
    name: 'world.permissions',
    component: () => import(/* webpackChunkName: "app-world" */ '@/pages/world/PermissionsPage.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('titles.world_permissions'),
      icon: 'key',
    }
  },
  {
    path: '/world/:world/tribes/:tribe',
    name: 'world.tribe',
    component: () => import(/* webpackChunkName: "app-world" */ '@/pages/world/TribesUsers.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      title: i18n.t('titles.world_tribes'),
      icon: 'collaboration',
    }
  },
  {
    path: '/world/:world/m/:module/:page',
    name: 'module.index',
    component: () => import(/* webpackChunkName: "app-module" */ '@/pages/modules/Module.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      module: true,
    }
  },
  {
    path: '/world/:world/m/:module/:page/archives',
    name: 'module.archives',
    component: () => import(/* webpackChunkName: "app-module" */ '@/pages/modules/Module.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      module: true,
    }
  },
];

// Module routes

const requireRoutes = require.context(
  // The relative path of the components folder
  './../../Modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /router.js$/
)

requireRoutes.keys().forEach(fileName => {
  // Get module routes
  const routesConfig = requireRoutes(fileName)

  routes = routes.concat(routesConfig.default)
})

export default routes
