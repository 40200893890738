import instance from '../../src/api/_instance'

export default {

    index (worldId) {
        return instance
        .get(`worlds/${worldId}/templates`)
    },
    use (worldId, templateId) {
        return instance
        .get(`worlds/${worldId}/templates/${templateId}`)
    },

}
