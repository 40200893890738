import instance from '../../src/api/_instance'

export default {
  /**
  * @vuese
  * get all tag
  */
  getTags (data) {
    return instance
      .get(`worlds/${data.id}/actionsboard/tags/${data.entity}/${data.type}`)
  },
  /**
  * @vuese
  * update tag
  */
  updateTags (data) {
    return instance
      .put(`worlds/${data.id}/actionsboard/tags/${data.entity}/${data.type}/${data.tag.id}`, data.tag)
  },
  /**
  * @vuese
  * destroy tag
  */
  destroyTags (data) {
    return instance
      .delete(`worlds/${data.id}/actionsboard/tags/${data.entity}/${data.type}/${data.tag.id}`)
  },
  /**
  * @vuese
  * store tag
  */
  storeTags (data) {
    return instance
      .post(`worlds/${data.id}/actionsboard/tags/${data.entity}/${data.type}`, data.tag)
  },
  /**
  * @vuese
  * get all tribus in world
  */
  getTribes (data) {
    return instance
      .get(`worlds/${data}/actionsboard/tribes`)
  },
  /**
  * @vuese
  * get all action user
  */
  getActionCreator (data) {
    let page = 1
    if (data.page) {
      page = data.page
    }
    return instance
      .get(`worlds/${data.id}/actionsboard/${data.entity}?page=${page}`)
  },
  /**
  * @vuese
  * get user belong to world
  */
  getWorldUsers (data) {
    return instance
      .get(`worlds/${data.id}/actionsboard/users`)
  },
  /**
  * @vuese
  * store action
  */
  storeAction (data) {
    return instance
      .post(`worlds/${data.id}/actionsboard/${data.entity}`, data.action)
  },
  /**
  * @vuese
  * edit action
  */
  editAction (data) {
    return instance
      .put(`worlds/${data.id}/actionsboard/${data.entity}/${data.action.id}`, data.action)
  },
  /**
  * @vuese
  * edit action
  */
  editActionDate (data) {
    return instance
      .put(`worlds/${data.id}/actionsboard/${data.entity}/date/${data.action.id}`, data.action)
  },
  /**
  * @vuese
  * delete action
  */
  deletetAction (data) {
    return instance
      .delete(`worlds/${data.id}/actionsboard/${data.entity}/${data.action}`)
  },
  /**
  * @vuese
  *  store commonet to action
  */
  commentAction (data) {
    return instance
      .post(`worlds/${data.id}/actionsboard/${data.entity}/comment`, data.action)
  },
  /**
  * @vuese
  * send action if action or not by owner
  */
  activeAction (data) {
    return instance
      .post(`worlds/${data.id}/actionsboard/${data.action}/activated-action`, data)
  },
  /**
  * @vuese
  * get actions assign to me
  */
  getActionCreatorAssign (data) {
    return instance
      .get(`worlds/${data.id}/actionsboard/assign/${data.entity}`)
  },
  /**
  * @vuese
  * update tag in actions
  */
  updateTagAction (data) {
    return instance
      .put(`worlds/${data.id}/actionsboard/tag/${data.entity}/${data.action.id}`, data.action)
  },

  fast: {
    get (data) {
      return instance
        .get(`worlds/${data.world_id}/actionsboard/fast`)
    },
    update (data) {
      return instance
        .put(`worlds/${data.world_id}/actionsboard/fast/${data.id}`, data)
    },
    destroy (data) {
      return instance
        .delete(`worlds/${data.world_id}/actionsboard/fast/${data.id}`)
    },
    store (data) {
      return instance
        .post(`worlds/${data.world_id}/actionsboard/fast`, data)
    },
  }
}
