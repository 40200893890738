<template>

  <div class="form-group-feedback form-group-feedback-left">
    <input type="search" class="form-control border-0" :placeholder="$t('filters.search')" v-model="searchValue">
    <div class="form-control-feedback">
      <i class="icon-search4 font-size-base text-muted"/>
    </div>
  </div>

</template>

<script>

export default {
  tag: 'el-filter-search',
  name: 'FilterSearch',
  props: [],
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    searchValue: {
      get () {
        return this.$store.state.global.sidebarSearch
      },
      set (value) {
        this.$store.commit('global/changeSidebarSearch', value)
      }
    }
  }
}
</script>

<style scoped>
.form-group-feedback {
  width: 210px;
}
</style>
