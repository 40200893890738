import { render, staticRenderFns } from "./_Global-message-modules.vue?vue&type=template&id=606660a8&scoped=true&"
import script from "./_Global-message-modules.vue?vue&type=script&lang=js&"
export * from "./_Global-message-modules.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "606660a8",
  null
  
)

export default component.exports