<template>

  <div>
    <div class="py-1 mb-2 w-100" v-if="taskValues">
      <div class="d-flex align-items-center w-100 my-2" v-for="(task, index) in data">
        <label class="m-0 cursor-pointer" v-if="task != ''">
          <i v-if="Array.isArray(taskValues) && taskValues.includes(index)" :class="`handle icon-checkbox-checked2 text-${classColorName} mr-1`"></i>
          <i v-else :class="`icon-checkbox-unchecked2 text-${classColorName} mr-1`"></i>
          <input class="d-none" type="checkbox" v-model="taskValues" :id="index" :value="index">
          {{ task }}
        </label>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'process-template-element-checklist',
  props: ['value', 'data', 'classColorName'],
  data () {
    return {
      taskValues: false
    }
  },
  mounted () {
    this.taskValues = this.value ? this.value : []
  },
  methods: {
    changeVal () {
      this.$emit('input', this.taskValues)
    }
  },
  watch: {
    taskValues () {
      this.changeVal()
    },
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  width: 300px;
  max-width: 100%;
}
</style>
