import actions from '../../src/router/actions'

const routes = [
  {
    path: '/world/:world/wiki',
    name: 'wiki.index',
    component: () => import(/* webpackChunkName: "module-wiki-content-index" */ './pages/ContentIndex.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      icon: 'book',
    }
  },
  {
    path: '/world/:world/wiki/content/:content',
    name: 'wiki.content',
    component: () => import(/* webpackChunkName: "module-wiki-content-page" */ './pages/ContentPage.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      sidebar: true,
      sidebar_close: true,
      icon: 'book',
    }
  },
  {
    path: '/world/:world/wiki/tokens',
    name: 'wiki.tokens',
    component: () => import(/* webpackChunkName: "module-wiki-tokens" */ './pages/TokensIndex.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      icon: 'code',
    }
  },
  {
    path: '/world/:world/onboarding',
    name: 'wiki.onboarding',
    component: () => import(/* webpackChunkName: "module-wiki-onboarding" */ './pages/OnboardingIndex.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      icon: 'medal2',
    }
  },
];

export default routes
