/* eslint-disable promise/param-names */

import {
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REGISTER,
  AUTH_REFRESH,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_VALIDATE_PHONE,
  AUTH_UNVALIDATE_PHONE,
  AUTH_PASSWORD_RENEW,
  AUTH_PASSWORD_RESET,
  // USER_REQUEST,
  USER_SUCCESS,
  USER_UPDATE,
  USER_ERROR,
  USER_LOGOUT,
  USER_REFRESH,
  USER_SET_WORLD,
  WORLD_MODULES_RESET,
  WORLD_USERS_RESET,
  WORLD_TRIBES_RESET,
  USER_NOTIFICATIONS_GET_ALL,
  USER_NOTIFICATIONS_GET_UNREAD,
  USER_NOTIFICATIONS_POST_READ,
} from '../mutations-types'

import moment from 'moment'
import _ from 'lodash'
import Api from '../../api'
import Vue from 'vue'
import router from '../../router'

const state = {
  phoneVerified: false,
  token: '',
  status: '',
  hasLoadedOnce: false,
  profile: {},
  world: {},
  notifications: null,
  notificationsRefreshTime: null,
}

const getters = {
  isAuthenticated: state => !!state.token,
  isPhoneVerified: state => !!state.phoneVerified,
  authStatus: state => state.status,
  authToken: state => state.token,
  getId: state => state.profile ? state.profile.id : null,
  getEmail: state => state.profile ? state.profile.email : null,
  getProfile: state => state.profile,
  getWorld: state => state.world,
  hasWorld: state => Object.keys(state.world).length == 0 ? false : true,
  getInvitations: state => state.profile ? state.profile.world_invitations : {},
  hasInvitations: state => state.profile && !!state.profile.world_invitations ? (Object.keys(state.profile.world_invitations).length == 0 ? false : true) : false,
  isProfileLoaded: state => state.profile && !!state.profile.email,
  isWorldOwner: state => state.world && state.world.pivot && !!state.world.pivot.owner,
  isWorldInternal: state => state.world && state.world.pivot && !!state.world.pivot.internal,
  worldOnTrial: state => state.world && state.world.plan && state.world.plan.trial,
  worldHasCard: state => state.world && state.world.plan && state.world.plan.card && state.world.plan.card.length > 0,
  worldHasSubscription: state => state.world && state.world.plan && state.world.plan.subscriptions && state.world.plan.subscriptions.length > 0,
  worldCanManageTemplates: state => !!(state.world.privilege && state.world.privilege.template_world),
  getNotifications: state => state.notifications,
  getNotificationsByWorld(state) {
      let notifs = {}
      if (!state.notifications) {
          return {}
      }
      state.notifications.forEach(notif => {
          if (!notifs[notif.data.world_id]) {
              notifs[notif.data.world_id] = []
          }
          notifs[notif.data.world_id].push(notif)
      })
      return notifs
  },
  notificationsRefreshTime: state => state.notificationsRefreshTime,
}

const actions = {
  [AUTH_REGISTER]: ({commit, dispatch}, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      Api.auth.register(user.email, user.password, user.company, user.phone_number ? user.phone_number : null)
        .then(resp => {
          const userToken = resp.data.success.token
          // Here set the header of your ajax library to the token value.
          commit(AUTH_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(AUTH_ERROR, err)
          reject(err)
        })
    })
  },
  [AUTH_REQUEST]: ({commit, dispatch}, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      Api.auth.login(user.email, user.password)
        .then(resp => {
          const userToken = resp.data.success.token

          if (resp.data.success.user.phone_verified_at != null) {
            commit(AUTH_VALIDATE_PHONE)
          }
          // Here set the header of your ajax library to the token value.
          commit(AUTH_SUCCESS, resp)

          resolve(resp)
        })
        .catch(err => {
          commit(AUTH_ERROR, err)
          reject(err)
        })
    })
  },
  [AUTH_VALIDATE_PHONE]: ({commit, dispatch}, code) => {
    return new Promise((resolve, reject) => {
      Api.auth.validate_phone(code)
        .then(resp => {
          commit(AUTH_VALIDATE_PHONE)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  [AUTH_PASSWORD_RESET]: ({commit, dispatch}, email) => {
    return new Promise((resolve, reject) => {
      Api.auth.password_reset(email)
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  [AUTH_PASSWORD_RENEW]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      Api.auth.password_renew(data)
        .then(resp => {
          if (resp.data.success.user.phone_verified_at != null) {
            commit(AUTH_VALIDATE_PHONE)
          }

          commit(AUTH_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  [AUTH_REFRESH]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      Api.auth.profile()
        .then(resp => {

          if (resp.data.success.phone_verified_at != null) {
            commit(AUTH_VALIDATE_PHONE)
          } else {
            commit(AUTH_UNVALIDATE_PHONE)
          }

          dispatch('auth/' + USER_REFRESH, resp.data.success, { root: true })
          resolve(resp)
        })
        .catch(err => {
          dispatch('auth/' + AUTH_LOGOUT, {}, { root: true })
          router.push('/')
          window.location.reload()
          // reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT)
      commit(USER_LOGOUT)
      dispatch('world/' + WORLD_MODULES_RESET, null, { root: true })
      dispatch('world/' + WORLD_USERS_RESET, null, { root: true })
      dispatch('world/' + WORLD_TRIBES_RESET, null, { root: true })
      resolve()
    })
  },


  // [USER_REQUEST]: ({commit, dispatch}) => {
  //   commit(USER_REQUEST)
  //   return Api.auth.profile()
  //     .then(resp => {
  //       commit(USER_SUCCESS, resp)
  //       return resp.data.success
  //     })
  //     .catch(resp => {
  //       commit(USER_ERROR)
  //       commit(USER_LOGOUT)
  //       // if resp is unauthorized, logout, to
  //       dispatch('auth/' + AUTH_LOGOUT, null, { root: true })
  //       return false
  //     })
  // },
  [USER_UPDATE]: ({commit, dispatch}, user) => {
    return Api.auth.update(user)
      .then(resp => {
        commit(USER_SUCCESS, resp)

        if (resp.data.success.phone_verified_at != null) {
          commit(AUTH_VALIDATE_PHONE)
        } else {
          commit(AUTH_UNVALIDATE_PHONE)
        }

        return resp.data.success
      })
  },
  [USER_REFRESH]: ({commit, dispatch}, user) => {
    commit(USER_REFRESH, user)
  },
  [USER_LOGOUT]: ({commit, dispatch}) => {
    commit(USER_LOGOUT)
  },
  [USER_SET_WORLD]: ({commit, dispatch}, world) => {
    commit(USER_SET_WORLD, world)
  },


  [USER_NOTIFICATIONS_GET_UNREAD]: ({commit}) => {
    return Api.auth.notifications.unread()
      .then(resp => {
        commit(USER_NOTIFICATIONS_GET_UNREAD, resp.data.success)
        return resp.data.success
      })
  },

  [USER_NOTIFICATIONS_POST_READ]: ({commit}, notifId) => {
    return Api.auth.notifications.read(notifId)
      .then(resp => {
        commit(USER_NOTIFICATIONS_GET_UNREAD, resp.data.success)
        return resp.data.success
      })
  },

  [USER_NOTIFICATIONS_GET_ALL]: ({commit}, data) => {
    return Api.auth.notifications.all(data.worldId, data.page)
      .then(resp => {
        return resp.data.success
      })
  },

}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.phoneVerified = (resp.data.success.user.phone_verified_at != null)
    state.token = resp.data.success.token
    state.profile = resp.data.success.user
    state.hasLoadedOnce = true



    if (resp.data.success.user.worlds[0]) { // If world in the account
      if (!state.world.id) { // If no previous world, set the first world found as default
        Vue.set(state, 'world', resp.data.success.user.worlds[0])
      } else {
        let findWorld = state.profile.worlds.find(elem => { // try to find if the user has access to it
          return elem.id == state.world.id
        })
        if (findWorld) {
          state.world = findWorld // if access, re-use the previous world as default
        } else {
          Vue.set(state, 'world', resp.data.success.user.worlds[0]) // if no access, set the first world as default
        }

      }
    } else { // If no world assigned to this account, reset value
      Vue.set(state, 'world', {})
    }
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_VALIDATE_PHONE]: (state) => {
    state.phoneVerified = true
    state.profile.phone_verified_at = moment().format('YYYY/MM/DD kk:mm:ss')
  },
  [AUTH_UNVALIDATE_PHONE]: (state) => {
    state.phoneVerified = false
    state.profile.phone_verified_at = null
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
    state.phoneVerified = false
    state.hasLoadedOnce = false
    state.profile = {}
  },


  // [USER_REQUEST]: (state) => {
  //   state.status = 'loading'
  // },
  [USER_REFRESH]: (state, user) => {
    state.status = 'success'
    Vue.set(state, 'profile', user)

    if (user.worlds[0]) { // If world in the account
      if (!state.world.id) { // If no previous world, set the first world found as default
        Vue.set(state, 'world', user.worlds[0])
      } else {
        let findWorld = state.profile.worlds.find(elem => { // try to find if the user has access to it
          return elem.id == state.world.id
        })
        if (findWorld) {
          state.world = findWorld // if access, re-use the previous world as default
        } else {
          Vue.set(state, 'world', user.worlds[0]) // if no access, set the first world as default
        }

      }
    } else { // If no world assigned to this account, reset value
      Vue.set(state, 'world', {})
    }

  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'profile', resp.data.success)

    if (resp.data.success.worlds[0]) { // If world in the account
      if (!state.world.id) { // If no previous world, set the first world found as default
        Vue.set(state, 'world', resp.data.success.worlds[0])
      } else {
        let findWorld = state.profile.worlds.find(elem => { // try to find if the user has access to it
          return elem.id == state.world.id
        })
        if (findWorld) {
          state.world = findWorld // if access, re-use the previous world as default
        } else {
          Vue.set(state, 'world', resp.data.success.worlds[0]) // if no access, set the first world as default
        }

      }
    } else { // If no world assigned to this account, reset value
      Vue.set(state, 'world', {})
    }
  },
  [USER_ERROR]: (state) => {
    state.status = 'error'
  },
  [USER_LOGOUT]: (state) => {
    Vue.set(state, 'profile', {})
    state.status = ''
    state.profile = {}
    state.world = {}
  },
  [USER_SET_WORLD]: (state, world) => {
    if (state.world.pivot && !world.pivot) {
      world.pivot = state.world.pivot
    }
    state.world = world
  },

  [USER_NOTIFICATIONS_GET_UNREAD]: (state, val) => {
    state.notifications = val
    state.notificationsRefreshTime = new Date().getTime()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
