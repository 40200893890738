<template>

  <div v-if="currentUser.id">
    <div class="py-1 mb-2  w-100">
      <div
        :class="[{
          'form-control pr-2 w-100 d-flex justify-content-between align-items-center': true,
          'shadow cursor-pointer': hover && !loading && value == null
        }, 'border-' + classColorName]"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        @click="createAction"
      >
        <div class="">
          <i :class="`icon-check text-${classColorName} mr-2`" />
          <span>
            {{ entity.data.name }} : {{ data }}
          </span>
        </div>
        <div :class="`badge badge-${classColorName}`" v-if="value == null">
          <span v-if="!loading">
            {{ $t('modules.process.process.process.create_action') }} <i class="icon-arrow-right13" />
          </span>
          <span v-else>
            <loader></loader>
          </span>
        </div>
        <div class="badge badge-success text-white" v-else>
          <i class="icon-check2" /> {{ $t('modules.process.process.process.action_created') }}
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import { ACTION_REQUEST_ACTIONS_STORE } from '../../../Actionsboard/mutations-types'

export default {
  name: 'process-template-element-action',
  props: ['data', 'entity', 'value', 'classColorName'],
  data () {
    return {
      loading: false,
      hover: false,
    }
  },
  mounted () {
  },
  methods: {
    changeVal(actionId) {
      this.$emit('input', actionId)
    },
    createAction () {
      if (this.loading || this.value != null) {
        return
      }
      this.loading = true
      let newToDoTask = {
        name: `${this.entity.data.name} : ${this.data}`,
        worldId: this.$route.params.world,
        owner: this.currentUser.id,
        startDate: null,
        endDate: null,
        pdca: 'plan',
        priority: 'none',
        favorite: false,
        creator: null,
        done:false,
        dueDate: null,
        timeToDo: null,
        reminderDate: null,
        place: null,
        project: [],
        tribus: [],
        tag: [],
        list: [],
        model: [],
        description: null,
        comment: null,
        source: 'local',
        recurrence: null,
        langue: 'fr',
        ownerActivated: false,
        actionOrigin: null,
        actionRecurrence: false,
        position: null
      }

      this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_STORE, {
        id: this.$route.params.world,
        entity: 'Actions',
        action: newToDoTask,
      }).then(resp => {
        this.loading = false
        let newActionId = resp[1][0].id
        this.changeVal(newActionId)
      })
      .catch(error => {
        this.notifError(error)
        this.loading = false
      })

    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
