<template>
  <div v-if="worldSubscriptionEnded && $route.name != 'world.subscription'">

    <div class="d-block bg-grey px-3 py-2 text-center" v-if="isCurrentWorldOwner">
      <div class="font-weight-semibold m-0">
        <div>{{ $t('subscription.no_sub_alert') }}</div>
      </div>
      <router-link :to="{ name: 'world.subscription', params: { world: world.id } }">
        <div class="btn btn-success btn-sm mt-1 rounded text-white">{{ $t('subscription.no_sub_btn_view') }}</div>
      </router-link>
      <div class="mt-1" v-if="!world.plan.trial_extended">
        <span class="small cursor-pointer text-greylight" v-if="!trial_loading" @click="trialExtend">{{ $t('subscription.no_sub_trial_extend') }}</span>
        <loader v-else />
      </div>
    </div>

    <div class="d-block bg-warning p-3" v-else>
      <div @click.prevent="null">{{ $t('subscription.no_sub_alert_user_text') }}</div>
    </div>

  </div>
</template>

<script>
/**
 * @group Core
 * Display WorldSubEnded message
 */

import { WORLD_TRIAL_EXTEND } from '../../store/mutations-types'

export default {
  name: 'WorldSubEnded',
  tag: 'el-world-sub-ended',
  data () {
    return {
      trial_loading: false,
    }
  },
  methods: {
    trialExtend () {
      this.trial_loading = true
      this.$store.dispatch('world/' + WORLD_TRIAL_EXTEND, this.world.id)
      .catch(error => {
        this.trial_loading = false
        this.notifError(error)
      })
    }
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    worldSubscriptionEnded () {
      return !this.$store.getters['auth/worldHasSubscription'] && !this.$store.getters['auth/worldOnTrial']
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
  }
}
</script>

<style scoped lang="scss">
img {
  max-width: 100%;
}
.button-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  .btn {
    font-size: 1.2em;
  }
}
</style>
