<template>

  <container
    :is="isInvitation ? 'div' : 'router-link'"
    v-if="user"
    :class="{
      'card': true,
      'cursor-pointer': !isInvitation,
    }"
    tag="div"
    :to="isInvitation ? null : $router.resolve({ name:'world.users.profile', params: { world: world.id, user: user.id } }).href"
  >
    <div class="card-body">
        <div class="row align-items-center">
          <div class="col-md-2 col-lg-2 col-xl-1 text-center avatar">
            <i class="icon-hour-glass2 text-grey" v-if="user.invitation"/>
            <div v-else>
              <div
                v-if="!user.invitation"
                v-tooltip="$t('user.list.view_profile')"
              >
                <el-avatar :src="user.avatar.thumb || user.avatar"/>
              </div>
            </div>
          </div>
          <div class="col-md">
            <div class="font-weight-bold" v-if="user.first_name || user.owner">
              {{ user.first_name }} {{ user.last_name }}
              <span class="text-white badge badge-info" v-if="user.pivot.owner">{{ $t('user.list.owner') }}</span>
              <span class="badge badge-yellow" v-if="!isInternal">{{ $t('common.external') }}</span>
            </div>
            <div class="font-weight-bold text-grey" v-else-if="user.invitation">
              <div class="badge badge-secondary">{{ $t('user.list.invited') }}</div>
              <span class="badge badge-yellow ml-1" v-if="!user.pivot.internal">{{ $t('common.external') }}</span>
            </div>
            <div class="font-weight-bold text-grey" v-else>-</div>
            <div class="mt-1 text-grey" v-if="user.position">{{ user.position }}</div>
          </div>
          <div class="col-md">
            <div class="d-flex align-items-center " v-if="user.email"><i class="text-grey mr-1 icon-envelop3" /> <span>{{ user.email }}</span></div>
            <div class="d-flex align-items-center mt-1 " v-if="user.phone_number"><i class="text-grey mr-1 icon-phone2" /> <span>{{ user.phone_number }}</span></div>
          </div>
          <div class="col-md text-center">
            <div class="avatar-line">
              <span :key="tribe.id" v-for="tribe in userDetails.tribes" class="avatar">
                <el-avatar :src="tribe.slug ? require('@/assets/images/categories/' + tribe.slug + '.svg') : tribe.avatar.thumb" v-tooltip="tribe.name"/>
              </span>
            </div>
          </div>
          <div class="col-md text-right">

            <slot name="actions"></slot>
            <i class="icon-arrow-right8 p-2" v-if="!isInvitation"/>

          </div>
        </div>
      </div>
    </div>
  </container>

</template>

<script>
export default {
  tag: 'el-user-teaser',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    isInvitation () {
      return this.user.id ? false : true
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    },
    currentUserId () {
      return this.$store.getters['auth/getId']
    },
    isInternal () {
      return this.user && this.user.pivot && this.user.pivot.internal
    },
    userDetails () {
      let profileIndex = _.findIndex(this.$store.getters['world/getUsers'], ['id', this.user.id])

      if (profileIndex === false || profileIndex < 0) {
          return false
      }

      return this.$store.getters['world/getUsers'][profileIndex]
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
