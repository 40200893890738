import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']
let user = store.getters['auth/getProfile']

let links = [
  {
    to: { name: 'saas.kpi.index', params: { world: world.id } },
    module: 'saas',
    permission: {
        'module': 'saas',
        'name': 'view_kpi',
    },
    title: 'modules.saas.kpi.title',
    weight: 1,
    group: 'strategy',
    image: require('./assets/images/kpi.svg'),
    condition: (user && user.roles_slugs && user.roles_slugs.includes('admin')) ? true : false,
    description: 'modules.saas.kpi.module_desc',
  },
]

export default links
