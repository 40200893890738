import store from '../../src/store'

let world = store.getters['auth/getWorld']

let links = [
  {
    to: { name: 'quest.index', params: { world: world.id } },
    module: 'quest',
    owner_only: true,
    title: 'modules.quest.title',
    weight: 2,
    image: require('./assets/images/module.svg'),
    description: 'modules.quest.module_desc',
  },
]

export default links
