import actions from '../../src/router/actions'

const routes = [
  {
    path: '/world/:world/academy',
    name: 'academy.index',
    component: () => import(/* webpackChunkName: "module-academy" */ './pages/AcademyIndex.vue'),
    beforeEnter: actions.needWorldOwner,
    meta: {
      icon: 'graduation2',
    }
  },
];

export default routes
