<template>

  <el-notification
    :notification="notification"
    :module="$t('modules.reminder.title')"
    :date="notification.created_at"
    :title="$t('modules.reminder.notifications.reminder', { content: notification.data.data.content_name })"
  >
    <div slot="actions">
      <el-notification-button
        :notification="notification"
        :callback="goToContent"
        :title="$t('modules.reminder.notifications.btn_reminder')"
      />
    </div>
  </el-notification>

</template>

<script>

export default {
  tag: 'el-notification-reminder-reminder',
  name: 'NotifreminderContentNew',
  props: {
    notification: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    goToContent (notification) {
      return this.$router.push({
        name: 'module.index',
        params: {
          world: this.world.id,
          module: 'reminder',
          page: 'reminders',
        },
        hash: '#' + notification.data.data.content_id,
      })
    },
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
  },
}
</script>

<style scoped lang="scss">

</style>
