import instance from '../../src/api/_instance'

export default {

    kpi: {
        index (worldId) {
            return instance
            .get(`worlds/${worldId}/saas/kpi`)
        },
        expenses (data) {
            return instance
            .post(`worlds/${data.world_id}/saas/kpi/expenses`, data)
        },
    },

}
