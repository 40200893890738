<template>
  <div v-if="isAuthenticated" class="auth-content">
    <slot name="header"></slot>
    <div class="content p-2 p-md-3">

      <div class="row no-gutters">

        <div :class="mainClass">
          <transition name="slide-right">
            <div v-if="showButtonOpenSidebarRight" class="bg-white shadow d-inline-block circle show-sidebar button-sidebar text-center mb-2 cursor-pointer" @click="changeSidebarRight(true)">
              <i class="icon-arrow-left7"></i>
            </div>
          </transition>

          <div class="mb-3" v-if="getBanners(currentRouteName, 'CENTER_TOP')"><el-banners :banners="getBanners(currentRouteName, 'CENTER_TOP')"/></div>

          <div class="mb-3" v-if="getBanners(currentRouteName, 'HELP_TEXT')">
            <el-alert type="help">
              <el-banners :banners="getBanners(currentRouteName, 'HELP_TEXT')"/>
            </el-alert>
          </div>

          <slot></slot>

          <div class="mt-3" v-if="getBanners(currentRouteName, 'CENTER_BOTTOM')"><el-banners :banners="getBanners(currentRouteName, 'CENTER_BOTTOM')"/></div>

        </div>

        <div v-show="showSidebarRight" class="col-md-4 col-xl-3 col-xxl-2 pl-0 pl-md-3 bg-transparent border-0 sidebar-light sidebar-component-right order-1 order-md-2">
          <div class="sidebar-content sidebar-right-content">
            <div v-if="showButtonCloseSidebarRight" class="d-none d-md-inline-block bg-white shadow circle hide-sidebar button-sidebar text-center mb-2 cursor-pointer" @click="changeSidebarRight(false)">
              <i class="icon-arrow-right7"></i>
            </div>

            <div class="mb-3" v-if="getBanners(currentRouteName, 'RIGHT_TOP')"><el-banners :banners="getBanners(currentRouteName, 'RIGHT_TOP')"/></div>

            <slot name="sidebar"></slot>

            <div class="mt-3" v-if="getBanners(currentRouteName, 'RIGHT_BOTTOM')"><el-banners :banners="getBanners(currentRouteName, 'RIGHT_BOTTOM')"/></div>

          </div>
        </div>

      </div>
    </div>


    <div class="wrapper-banner-onboarding-global" v-if="getBanners('*', 'ONBOARDING') && !alreadyOnboardedGlobal">
      <el-modal
        size="md"
        class="banner-onboarding"
        :id="'banner-onboarding-' + getBanners('*', 'ONBOARDING')[0].id"
        :noTitle="true"
        bodyClasses="p-0"
      >
        <el-banners :banners="[getBanners('*', 'ONBOARDING')[0]]"/>
      </el-modal>
    </div>

    <div class="wrapper-banner-onboarding-page" v-if="getBanners(currentRouteName, 'ONBOARDING')">
      <el-modal
        size="md"
        class="banner-onboarding"
        :id="'banner-onboarding-' + getBanners(currentRouteName, 'ONBOARDING')[0].id"
        :noTitle="true"
        bodyClasses="p-0"
      >
        <el-banners :banners="[getBanners(currentRouteName, 'ONBOARDING')[0]]"/>
      </el-modal>
    </div>

    <div v-if="getBanners(currentRouteName, 'CHECKLIST')">
      <el-banner-checklist
        :title="getBanners(currentRouteName, 'CHECKLIST')[0].title"
        :checklist="getBanners(currentRouteName, 'CHECKLIST')[0].checklist"
      />
    </div>

  </div>
</template>

<script>
export default {
  name: 'AuthContent',
  tag: 'auth-content',
  props: {
    forceShowSidebarToggleButton: {
      default: null,
    },
  },
  methods: {
    changeSidebarRight(val = null) {
      if (val === null) {
        val = !this.isSidebarRightOpened
      }
      this.$store.commit('global/changeSidebarRight', val)
    }
  },
  mounted () {
    if (this.getBanners(this.currentRouteName, 'ONBOARDING') && !this.alreadyOnboarded) {
      this.openModal('banner-onboarding-' + this.getBanners(this.currentRouteName, 'ONBOARDING')[0].id, true)
    }
    if (this.getBanners('*', 'ONBOARDING') && !this.alreadyOnboardedGlobal) {
      this.openModal('banner-onboarding-' + this.getBanners('*', 'ONBOARDING')[0].id, true)
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
    getCurrentUser () {
      return this.$store.getters['auth/getProfile']
    },
    mainClass () {
      if (this.$route.meta.sidebar && this.isSidebarRightOpened) {
        return "col-md-8 col-xl-9 col-xxl-10"
      } else {
        return "col-12"
      }
    },
    isSidebarRightOpened () {
      if (!this.$route.meta.sidebar_close) {
        return true
      }
      return this.$store.getters['global/isSidebarRightOpened']
    },
    showSidebarRight () {
      if (this.$route.meta.sidebar && !this.$route.meta.sidebar_close) {
        return true
      }
      return this.$route.meta.sidebar && this.isSidebarRightOpened
    },
    showButtonSidebarRight () {
      if (this.forceShowSidebarToggleButton === null) {
        if (typeof this.$route.meta.sidebar_close_btn == 'undefined') {
          return true
        } else {
          return this.$route.meta.sidebar_close_btn
        }
      } else {
        return this.forceShowSidebarToggleButton
      }
    },
    showButtonCloseSidebarRight () {
      return this.$route.meta.sidebar && this.$route.meta.sidebar_close && this.showButtonSidebarRight && this.isSidebarRightOpened && window.innerWidth >= 768
    },
    showButtonOpenSidebarRight () {
      return this.$route.meta.sidebar && this.$route.meta.sidebar_close && this.showButtonSidebarRight && !this.isSidebarRightOpened && window.innerWidth >= 768
    }
  },
}
</script>

<style scoped lang="scss">
.button-sidebar {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.show-sidebar {
  position: absolute;
  right: -30px;
  top: 0px;
  z-index: 2;
}

.slide-right-enter-active {
  transition: all .3s ease;
}
.slide-right-leave-active {
  transition: none;
}
.slide-right-enter, .slide-right-leave-to {
  right: -60px;
}

.wrapper-banner-onboarding-global {
  + .wrapper-banner-onboarding-page {
    display: none;
  }
}
</style>
