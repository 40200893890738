import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']

let links = [
  {
    to: { name: 'orgchart.index', params: { world: world.id } },
    module: 'orgchart',
    title: 'modules.orgchart.title',
    weight: 15,
    group: 'people',
    image: require('./assets/images/module.svg'),
    description: 'modules.orgchart.module_desc',
    condition: store.getters['auth/isWorldInternal'],
  },
]

export default links
