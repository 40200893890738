<template>

  <div class="py-2 px-3">
    <div class="small text-grey mb-1">
      <span class="font-weight-bold text-secondary">
        {{ createdAtAgo }}
      </span>
      <span>
        <span v-if="module"> — {{ module }}</span>
      </span>
    </div>
    {{ title }}
    <div class="text-right">
      <el-notification-button
        color="link text-grey"
        :title="$t('notifications.mark_as_read')"
        :notification="notification" v-if="notification.read_at == null"
      />
      <div class="d-inline-block">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'

export default {
  tag: 'el-notification',
  name: 'Notification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
    module: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
    }
  },
  computed: {
    createdAtAgo () {
      if (!this.date) {
        return null
      }
      return moment(this.date).fromNow()
    },
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">

</style>
