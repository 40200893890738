export const WIKI_GET_CONTENTS = 'Wiki Get Contents'
export const WIKI_CREATE_CONTENT = 'Wiki Create Content'
export const WIKI_ADD_MEDIA_CONTENT = 'WIKI_ADD_MEDIA_CONTENT'
export const WIKI_GET_CONTENT = 'Wiki Get Content'
export const WIKI_GET_HISTORY_CONTENT = 'Wiki Get History'
export const WIKI_CLONE_CONTENT = 'Wiki Clone Content'
export const WIKI_USE_CONTENT_TEMPLATE = 'Wiki Use Content Template'
export const WIKI_GET_CATEGORIES = 'Wiki Get Categories'
export const WIKI_SET_CONTENT = 'Wiki Set Content'
export const WIKI_SEND_CONTENT = 'Wiki Send Content'
export const WIKI_DELETE_CONTENT = 'Wiki Delete Content'

export const WIKI_GET_TOKENS = 'Wiki Get Tokens'
export const WIKI_SET_TOKEN = 'Wiki Set Token'
export const WIKI_CREATE_TOKEN = 'Wiki Create Token'
export const WIKI_DELETE_TOKEN = 'Wiki Delete Token'

export const EVENT_WIKI_EDIT_TOKEN = 'Event Wiki Edit Token'

export const WIKI_GET_ONBOARDINGS = 'Wiki Get Onboardings'
export const WIKI_GET_ONBOARDING = 'Wiki Get Onboarding'
export const WIKI_SET_ONBOARDING = 'Wiki Set Onboarding'
export const WIKI_CREATE_ONBOARDING = 'Wiki Create Onboarding'
export const WIKI_EDIT_ONBOARDING = 'Wiki Edit Onboarding'
export const WIKI_DELETE_ONBOARDING = 'Wiki Delete Onboarding'
export const WIKI_COMPLETE_ONBOARDING = 'Wiki Complete Onboarding'
export const WIKI_GET_COMPLETED_ONBOARDINGS = 'Wiki Get Completed Onboardings'
export const WIKI_ONBOARDING_GET_SHEETS = 'Wiki Onboarding Get Sheets'
export const WIKI_USE_ONBOARDING_TEMPLATE = 'Wiki Use Onboarding Template'
