/* eslint-disable promise/param-names */

import {
SAAS_GET_KPI,
SAAS_SAVE_EXPENSES,
} from './mutations-types'
import Api from '../../src/api'

const state = {
}

const getters = {
}

const actions = {

  [SAAS_GET_KPI]:({}, worldId) => {
    return Api.saas.kpi.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

  [SAAS_SAVE_EXPENSES]:({}, data) => {
    return Api.saas.kpi.expenses(data)
    .then(resp => {
      return resp.data.success
    })
  },

}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
