import { render, staticRenderFns } from "./_Empty.vue?vue&type=template&id=1648ef27&scoped=true&"
import script from "./_Empty.vue?vue&type=script&lang=js&"
export * from "./_Empty.vue?vue&type=script&lang=js&"
import style0 from "./_Empty.vue?vue&type=style&index=0&id=1648ef27&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1648ef27",
  null
  
)

export default component.exports