<template>
  <div>
    <el-navbar />

    <!-- Page content -->
    <div class="page-content">

      <div class="content-wrapper position-relative">
        <div class="content pt-sm-5">
          <div class="row align-items-center justify-content-center flex-column flex-md-row">
            <div class="card col-sm-10 col-md-10 col-lg-8 col-xl-6">
              <div class="card-body">
                <slot></slot>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { AUTH_REFRESH } from '../store/mutations-types'
export default {
  mounted () {
    if (this.isAuthenticated) {
      this.$store.dispatch('auth/' + AUTH_REFRESH)
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
}
</script>
