import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']

let links = [
  {
    to: { name: 'templates.index', params: { world: world.id } },
    owner_only: true,
    module: 'templates',
    title: 'modules.templates.title',
    weight: 50,
    description: 'modules.templates.module_desc',
  },
]

export default links
