import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import _ from 'lodash'

const vuexPersist = new VuexPersist({
  key: process.env.VUE_APP_TITLE,
  storage: localStorage
})

// Modules
import _global from './global/global'
import auth from './global/auth'
import world from './global/world'
import world_invitation from './global/world_invitation'
import module from './global/module'

Vue.use(Vuex)

let modules = {
  global: _global,
  auth,
  world,
  world_invitation,
  module,
}

// Load other odules
const requireModules = require.context(
  // The relative path of the components folder
  './../../Modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /store.js$/
)

requireModules.keys().forEach(fileName => {
  // Get module Modules
  const moduleConfig = requireModules(fileName)

  const moduleName = _.camelCase(fileName.split('/')[1])
  modules[moduleName] = moduleConfig.default
})



export default new Vuex.Store({
  modules: modules,
  strict: false,
  plugins: [vuexPersist.plugin]
})
