<template>
  <ul class="nav nav-tabs nav-tabs-element nav-tabs-bottom border-bottom-0 mb-0 w-100">
    <slot></slot>
  </ul>
</template>

<script>
/**
 * @group Core
 * Display Tabs Wrapper
 * https://getbootstrap.com/docs/4.0/components/navs/#tabs
 */
export default {
  name: 'Tabs',
  tag: 'el-tabs',
  props: {
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.nav-tabs {
    overflow-y: auto;
}
</style>
