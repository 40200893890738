/* eslint-disable promise/param-names */

import {
ACADEMY_COURSE_INDEX,
ACADEMY_COURSE_GET,
ACADEMY_COURSE_SET,
ACADEMY_COURSE_CREATE,
ACADEMY_COURSE_EDIT,
ACADEMY_COURSE_DELETE,
ACADEMY_COURSE_COMPLETE,
ACADEMY_COURSE_GET_COMPLETED,
ACADEMY_COURSE_GET_SHEETS,
} from './mutations-types'
import Api from '../../src/api'

const state = {
}

const getters = {
}

const actions = {

  // ACADEMY
  [ACADEMY_COURSE_CREATE]:({}, data) => {
    return Api.academy.course.create(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACADEMY_COURSE_EDIT]:({}, data) => {
    return Api.academy.course.edit(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACADEMY_COURSE_INDEX]:({}, worldId) => {
    return Api.academy.course.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACADEMY_COURSE_GET]:({}, data) => {
    return Api.academy.course.get(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACADEMY_COURSE_SET]:({}, data) => {
    return Api.academy.course.set(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACADEMY_COURSE_DELETE]:({}, data) => {
    return Api.academy.course.delete(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACADEMY_COURSE_COMPLETE]:({}, data) => {
    return Api.academy.course.complete(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACADEMY_COURSE_GET_COMPLETED]:({}, worldId) => {
    return Api.academy.course.getCompleted(worldId)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACADEMY_COURSE_GET_SHEETS]:({}, data) => {
    return Api.academy.course.getSheets(data)
    .then(resp => {
      return resp.data.success
    })
  },

}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
