<template>

    <div class="dropdown">
      <button class="btn border-0 rounded btn-grey dropdown-toggle" type="button" :id="`filter-${id}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span
          :class="{
            'text-dark': true,
          }"
        >
          <span
            :class="{
              'font-weight-semibold text-primary': selectedOptions.length > 0,
            }"
          >{{ title }}</span>
          <span class="small">
            <span v-if="selectedOptions.length > 0">
              <span v-if="type == 'user' && usersByKey[selectedOptions[0].title]">
                <span class="pl-1 text-grey">:</span> {{ usersByKey[selectedOptions[0].title].full_name || usersByKey[selectedOptions[0].title].email }}
              </span>
              <span v-else>
                <span class="pl-1 text-grey">:</span> {{ selectedOptions[0].title }}
              </span>
            </span>
            <span class="text-slate font-weight-semibold" v-if="selectedOptions.length > 1">
               + {{ selectedOptions.length - 1 }}
            </span>
          </span>
        </span>
      </button>
      <div class="dropdown-menu" :aria-labelledby="`filter-${id}`">
        <slot></slot>
      </div>
    </div>

</template>

<script>

export default {
  tag: 'el-filter',
  name: 'FilterWrap',
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    type: {
      default: null,
      type: [String],
    },
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    selectedOptions () {
      return this.options.filter(option => {
        return option.value === true
      });
    },
    users () {
      return this.$store.getters['world/getUsers']
    },
    usersByKey () {
      return _.keyBy(this.users, 'id')
    },
  },
}
</script>

<style scoped>
.dropdown {
  position: relative;
  top: .5px;
}
</style>
