<template>

  <div>
    <div
      class="d-flex justify-content-between align-items-top"
      v-for="(contentPart) in process.data.processTemplateContent"
    >
      <container
        :is="`process-template-element-${contentPart.type}`"
        v-model="process.data.processValues[contentPart.id]"
        :class="{'d-flex content p-0': true, 'pl-3': contentPart.type != 'step'}"
        :entity="process"
        :data="contentPart.data"
        :id="contentPart.id"
        :classColorName="classColorName"
        :wikiContent="wikiContent"
      />
    </div>
  </div>

</template>

<script>
import store from '@/store'
import _ from 'lodash'

import {
  WIKI_GET_CONTENTS,
} from '../../Wiki/mutations-types'

import {
  PROCESS_GET_PROCESS,
  PROCESS_UPDATE_PROCESS,
} from '../mutations-types'

export default {
  name: 'ProcessEntity',
  tag: 'process-entity',
  props: ['process', 'classColorName'],
  components: {
    'process-template-element-textfield': require('../components/elements/Textfield.vue').default,
    'process-template-element-textarea': require('../components/elements/Textarea.vue').default,
    'process-template-element-editor': require('../components/elements/Editor.vue').default,
    'process-template-element-checklist': require('../components/elements/Checklist.vue').default,
    'process-template-element-selectlist': require('../components/elements/Selectlist.vue').default,
    'process-template-element-step': require('../components/elements/Step.vue').default,
    'process-template-element-image': require('../components/elements/Image.vue').default,
    'process-template-element-action': require('../components/elements/Action.vue').default,
    'process-template-element-wikilink': require('../components/elements/WikiLink.vue').default,
    'process-template-element-rating': require('../components/elements/Rating.vue').default,
  },
  data () {
    return {
      loading: true,
      wikiContent: [],
      processName: '',
      filters: false
    }
  },
  mounted () {
    this.loadWikiContent()
  },
  methods: {
    loadWikiContent () {
      if (this.isAuth()) {
        this.$store.dispatch('wiki/' + WIKI_GET_CONTENTS, this.$route.params.world).then(content => {
          this.wikiContent = content
        })
      }
    },
  },
  computed: {
    saving () {
      return this.loading
    },
    hasContent () {
      return this.process && this.process.data && this.process.data.processTemplateContent && this.process.data.processTemplateContent.length > 0
    },
    processValues () {
      if (!this.hasContent) {
        return null
      }
      return {...this.process.data.processValues}
    },
  },
}
</script>

<style lang="scss" scoped>
.form-control-title {
  font-size: 1.6em;
}
.d-flex.content {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
</style>
