<template>

    <label
      :class="{
        'cursor-pointer d-block d-flex justify-content-between px-2 py-1 w-auto mb-0': true,
        'bg-greylight text-dark': hovered,
      }"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
    >
      <div class="d-flex align-items-start">
        <i v-if="option.value" class="small mt-1 filter-checkbox icon-checkbox-checked2 mr-1 text-success"></i>
        <i v-if="!option.value" class="small mt-1 filter-checkbox icon-checkbox-unchecked2 mr-1 text-grey"></i>
        <span class="pr-1" v-if="type == 'user' && usersByKey[option.title]">
          <el-avatar :src="usersByKey[option.title].avatar.thumb" size="xsmall" />
          {{ usersByKey[option.title].full_name || usersByKey[option.title].email }}
        </span>
        <span class="pr-1" v-else>{{ option.title }}</span>
      </div>
      <div>
        <span class="badge badge-light" v-if="option.count">{{ option.count }}</span>
      </div>
    </label>

</template>

<script>

export default {
  tag: 'el-filter-checkbox',
  name: 'FilterCheckbox',
  props: {
    option: {
      required: true,
      type: [Array, Object],
    },
    type: {
      default: null,
      type: [String],
    },
  },
  data () {
    return {
      hovered: false,
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    users () {
      return this.$store.getters['world/getUsers']
    },
    usersByKey () {
      return _.keyBy(this.users, 'id')
    },
  }
}
</script>

<style scoped>
label {
  font-size: 0.9em;
  border-radius: .25em;
}
.filter-checkbox {
  padding-top: 2px;
}
</style>
