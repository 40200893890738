import instance from './_instance'

export default {

  list (worldId, moduleId, entityType, page) {
    return instance
      .get(`worlds/${worldId}/module/${moduleId}/${entityType}?page=${page}`)
  },

  archives (worldId, moduleId, entityType, page) {
    return instance
      .get(`worlds/${worldId}/module/${moduleId}/${entityType}/archives?page=${page}`)
  },

  add (worldId, moduleId, entityType, data) {
    return instance
      .post(`worlds/${worldId}/module/${moduleId}/${entityType}`, data)
  },

  edit (worldId, moduleId, entityType, data) {
    let id = data.get ? data.get('id') : data.id
    return instance
      .post(`worlds/${worldId}/module/${moduleId}/${entityType}/${id}`, data)  // multipart issue fix, should be PUT method
  },

  remove (worldId, moduleId, entityType, id) {
    return instance
      .delete(`worlds/${worldId}/module/${moduleId}/${entityType}/${id}`)
  },

  autocomplete (worldId, moduleId, entityType, data) {
    return instance
      .post(`worlds/${worldId}/module/${moduleId}/${entityType}/autocomplete`, data)
  },

  refreshAccessKey (worldId, entityId) {
    return instance
      .get(`worlds/${worldId}/data/${entityId}/accesskey/refresh`)
  },

  refreshPublicKey (worldId, entityId) {
    return instance
      .get(`worlds/${worldId}/data/${entityId}/publickey/refresh`)
  },

  cancelPublicKey (worldId, entityId) {
    return instance
      .get(`worlds/${worldId}/data/${entityId}/publickey/cancel`)
  },

  getByPublicKey (worldId, publickey) {
    return instance
      .get(`worlds/${worldId}/data/${publickey}`)
  },

  setTemplate (worldId, moduleId, entityId, data) {
    return instance
      .put(`worlds/${worldId}/data/${entityId}/template`, data)
  },

  setStatus (worldId, moduleId, entityId, data) {
    return instance
      .put(`worlds/${worldId}/data/${entityId}/status`, data)
  },

  comments: {
    index (worldId, moduleId, entityType, entityId) {
      return instance
        .get(`worlds/${worldId}/module/${moduleId}/${entityType}/${entityId}/comments`)
    },
    store (worldId, moduleId, entityType, entityId, comment) {
      return instance
        .post(`worlds/${worldId}/module/${moduleId}/${entityType}/${entityId}/comments`, comment)
    },
    update (worldId, moduleId, entityType, entityId, commentId, comment) {
      return instance
        .put(`worlds/${worldId}/module/${moduleId}/${entityType}/${entityId}/comments/${commentId}`, comment)
    },
    destroy (worldId, moduleId, entityType, entityId, commentId) {
      return instance
        .delete(`worlds/${worldId}/module/${moduleId}/${entityType}/${entityId}/comments/${commentId}`)
    },
  },

}
