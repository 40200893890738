export const ACTION_REQUEST_TAGS =  'Get all tag'
export const ACTION_UPDATE_TAGS = 'update tag'
export const ACTION_DESTROY_TAGS = 'destroy tag'
export const ACTION_STORE_TAGS = 'store tag'
export const WORLD_TRIBES_REQUEST = 'get tribes world'
export const ACTION_REQUEST_USERS = 'Get usrs in World'
export const ACTION_REQUEST_ACTIONS_STORE = 'Store new Action'
export const ACTION_REQUEST_ACTIONS_UPDATE = 'Update action'
export const ACTION_REQUEST_ACTIONS_UPDATE_DATE = 'Update action date'
export const ACTION_REQUEST_ACTIONS_DELETE = 'Delete action'
export const ACTION_STORE_COMMENT = 'Add commentaire for action'
export const ACTION_ACTIVE_OWNER = 'active une action by owner'
export const ACTION_REQUEST_ACTIONS_CREATOR = 'ACTION_REQUEST_ACTIONS_CREATOR'
export const ACTION_REQUEST_ACTIONS_CREATOR_PROCESS = 'ACTION_REQUEST_ACTIONS_CREATOR_PROCESS'
export const ACTION_REQUEST_ACTIONS_CREATOR_OWN = 'ACTION_REQUEST_ACTIONS_CREATOR_OWN'
export const ACTION_REQUEST_ACTIONS_CREATOR_RECCURENCE = 'ACTION_REQUEST_ACTIONS_CREATOR_RECCURENCE'
export const ACTION_REQUEST_ACTIONS_CREATOR_HISTORIQ = 'ACTION_REQUEST_ACTIONS_CREATOR_HISTORIQ'
export const ACTION_REQUEST_ACTIONS_CREATOR_FAVORITE = 'ACTION_REQUEST_ACTIONS_CREATOR_FAVORITE'
export const ACTION_REQUEST_ACTIONS_CREATOR_ASSIGN = 'ACTION_REQUEST_ACTIONS_CREATOR_ASSIGN'
export const ACTION_REQUEST_ACTIONS_PARTAGES = 'ACTION_REQUEST_ACTIONS_PARTAGES'
export const ADD_TAG_TO_ACTION = 'add tag to action'
export const ACTION_VIEW_MODE_SET = 'ACTION_VIEW_MODE_SET'

export const ACTIONBOARD_REQUEST_FAST = 'ACTIONBOARD_REQUEST_FAST'
export const ACTIONBOARD_UPDATE_FAST = 'ACTIONBOARD_UPDATE_FAST'
export const ACTIONBOARD_DESTROY_FAST = 'ACTIONBOARD_DESTROY_FAST'
export const ACTIONBOARD_STORE_FAST = 'ACTIONBOARD_STORE_FAST'
