import instance from '../../src/api/_instance'

export default {

    process: {
        template: {
            index (worldId) {
                return instance
                .get(`worlds/${worldId}/process/template`)
            },
            get (worldId, templateId) {
                return instance
                .get(`worlds/${worldId}/process/template/${templateId}`)
            },
            results (worldId, templateId) {
                return instance
                .get(`worlds/${worldId}/process/template/${templateId}/results`)
            },
            create (worldId, data) {
                return instance
                .post(`worlds/${worldId}/process/template`, data)
            },
            update (worldId, templateId, data) {
                return instance
                .put(`worlds/${worldId}/process/template/${templateId}`, data)
            },
            addMedia (worldId, templateId, base64, replace) {
                return instance
                .post(`worlds/${worldId}/process/template/${templateId}/media`, {base64, replace})
            },
        },

        process: {
            index (worldId) {
                return instance
                .get(`worlds/${worldId}/process/process`)
            },
            get (worldId, templateId) {
                return instance
                .get(`worlds/${worldId}/process/process/${templateId}`)
            },
            create (worldId, data) {
                return instance
                .post(`worlds/${worldId}/process/process`, data)
            },
            update (worldId, templateId, data) {
                return instance
                .put(`worlds/${worldId}/process/process/${templateId}`, data)
            },
        },
    },

    form: {
        template: {
            index (worldId) {
                return instance
                .get(`worlds/${worldId}/form/template`)
            },
            get (worldId, templateId) {
                return instance
                .get(`worlds/${worldId}/form/template/${templateId}`)
            },
            results (worldId, templateId) {
                return instance
                .get(`worlds/${worldId}/form/template/${templateId}/results`)
            },
            create (worldId, data) {
                return instance
                .post(`worlds/${worldId}/form/template`, data)
            },
            update (worldId, templateId, data) {
                return instance
                .put(`worlds/${worldId}/form/template/${templateId}`, data)
            },
            addMedia (worldId, templateId, base64, replace) {
                return instance
                .post(`worlds/${worldId}/form/template/${templateId}/media`, {base64, replace})
            },
        },

        form: {
            index (worldId) {
                return instance
                .get(`worlds/${worldId}/form/form`)
            },
            get (worldId, templateId) {
                return instance
                .get(`worlds/${worldId}/form/form/${templateId}`)
            },
            create (worldId, data) {
                return instance
                .post(`worlds/${worldId}/form/form`, data)
            },
            update (worldId, templateId, data) {
                return instance
                .put(`worlds/${worldId}/form/form/${templateId}`, data)
            },
        },
    },


}
