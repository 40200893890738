import _global from './global'
import auth from './auth'
import world from './world'
import world_invitation from './world_invitation'
import module from './module'

let api = {
  global: _global,
  auth,
  world,
  world_invitation,
  module,
}

// Load other odules
const requireModules = require.context(
  // The relative path of the components folder
  './../../Modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /api.js$/
)

requireModules.keys().forEach(fileName => {
  // Get module Modules
  const moduleConfig = requireModules(fileName)

  const moduleName = _.camelCase(fileName.split('/')[1])
  api[moduleName] = moduleConfig.default
})

export default api
