import instance from '../../src/api/_instance'

export default {

  list (worldId, moduleId, entityType) {
    return instance
      .get(`worlds/${worldId}/hostings`)
  },

  add (data) {
    return instance
      .post(`worlds/${data.world_id}/hostings`, data)
  },

  get (worldId, id) {
    return instance
      .get(`worlds/${worldId}/hostings/${id}`)
  },

  edit (data) {
    return instance
      .put(`worlds/${data.world_id}/hostings/${data.id}`, data)
  },

  // remove (worldId, moduleId, entityType, id) {
  //   return instance
  //     .delete(`worlds/${worldId}/hostings/${id}`)
  // },

  backup_add (data) {
    return instance
      .post(`worlds/${data.world_id}/hostings/${data.hosting_id}/backup`, data)
  },

  revisions (worldId, id, page = 1) {
    return instance
      .get(`worlds/${worldId}/hostings/${id}/revisions?page=${page}`)
  },

}
