<template>

  <el-notification
    :notification="notification"
    :module="notification.module"
    :date="notification.created_at"
    :title="$t('notifications.fill_profile', {appName})"
  >
    <div slot="actions">
      <el-notification-button
        :notification="notification"
        :callback="goToProfile"
        :title="$t('notifications.fill_profile_btn')"
      />
    </div>
  </el-notification>

</template>

<script>

export default {
  tag: 'el-notification-core-fill_profile',
  name: 'NotifCoreFillProfile',
  props: {
    notification: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      appName: process.env.VUE_APP_TITLE,
    }
  },
  mounted () {
  },
  methods: {
    goToProfile () {
      return this.$router.push({name: 'user.profile'})
    },
  },
  computed: {
  },
}
</script>

<style scoped lang="scss">

</style>
