<template>

  <div>

    <a
      class="w-100 py-1 mb-2"
      v-if="content"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      :href="$router.resolve({name: 'wiki.content', params: { world: $route.params.world, content: data } }).href"
      target="_new"
    >
      <div :class="{'form-control cursor-pointer bg-light d-flex align-items-center justify-content-between': true, 'shadow': hover}">
        <div>
          <i class="icon-book mr-2 text-grey"></i>
          <span class="font-weight-bold">
            {{ content.name }}
          </span>
        </div>
        <div class="badge badge-light">
          {{ $t('modules.process.process.process.wikilink_go') }} <i class="icon-new-tab2"></i>
        </div>
      </div>
    </a>

  </div>

</template>

<script>

import _ from 'lodash'

export default {
  name: 'process-template-addon-wikilink',
  props: ['data', 'wikiContent', 'classColorName'],
  data () {
    return {
      hover: false,
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    content () {
      return _.find(this.wikiContent, content => {
        return content.id == this.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
