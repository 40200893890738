<template>

  <div>

    <div class="" v-if="hosting">

      <el-header :title="title" :titleSuffix="titleSuffix" :link="hosting.domain ? hosting.domain : null" :back="returnToHostingPage">
        <div slot="left">
          <el-header-back />
        </div>
      </el-header>
      <router-link tag="h5" :to="{ name: 'hosting.overview', params: { world: $route.params.world, hosting: hosting.id } }" class="cursor-pointer mx-3">
        <hosting-status v-if="hosting && !hosting.parent" :status="hosting.status" />
      </router-link>

      <el-tabs v-if="tabs">
        <el-tab v-for="tab in tabs" :key="tab.title">
          <router-link
            class="nav-link"
            :to="{ name: tab.title, params: { world: $route.params.world, hosting: hosting.id } }"
            active-class="active"
            data-toggle="tab"
          >
            {{ $t('modules.' + tab.title) }}
          </router-link>
        </el-tab>
      </el-tabs>

    </div>


    <div class="container-fluid">
      <div class="row px-md-2">
        <div class="col">
          <div class="card mb-0 mt-2" v-show="showChecklist" v-if="hosting && hosting.status === 'DRAFT' && !hosting.parent">
            <div class="card-header">
              <el-card-title :title="$t('modules.hosting.checklist.title')" />
            </div>
            <div class="card-body">

              <div class="checklist">
                <div class="pl-2">
                  <router-link
                    :to="checklistLink(task)"
                    v-for="(done, task) in hosting.checklist"
                    :key="task"
                    class="h6 my-1 d-block"
                  >
                    <i class="h4 m-0 icon-checkmark-circle text-success mr-1" v-if="done" />
                    <i class="h4 m-0 icon-radio-unchecked text-grey mr-1" v-if="!done" />
                    {{ $t('modules.hosting.checklist.task.' + task) }}
                  </router-link>
                  <!-- Card -->
                  <router-link
                    v-if="hosting.billing === 'STRIPE'"
                    :to="checklistLink('card')"
                    class="h6 my-1 d-block"
                  >
                    <i class="h4 m-0 icon-checkmark-circle text-success mr-1" v-if="worldHasCard" />
                    <i class="h4 m-0 icon-radio-unchecked text-grey mr-1" v-if="!worldHasCard" />
                    {{ $t('modules.hosting.checklist.task.card', { world: world.name }) }}
                  </router-link>
                  <div v-if="canGoOnline">
                    <a @click.prevent="goOnline()" v-if="!loading" class="btn btn-primary btn-lg mt-1 text-white">
                      <span class="h5">
                        <i class="icon-arrow-right7 mr-1" />
                        {{ $t('modules.hosting.go_online') }}
                      </span>
                    </a>
                    <a v-if="loading" class="btn btn-primary btn-lg mt-1 text-white">
                      <span class="h5">
                        <loader />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { HOSTING_UPDATE, CURRENT_HOSTINGID_SET, CURRENT_SPACEID_SET } from '../mutations-types'

export default {
  tag: 'hosting-header',
  name: 'Hosting-header',
  props: ['hosting', 'showChecklist'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    tabs () {
      let links = []
      links.push({ title: 'hosting.overview', icon: 'home' })
      links.push({ title: 'hosting.backups', icon: 'server' })
      links.push({ title: 'hosting.performance', icon: 'stats-growth2' })
      links.push({ title: 'hosting.seo', icon: 'file-text2' })
      links.push({ title: 'hosting.access', icon: 'key' })
      links.push({ title: 'hosting.advanced', icon: 'wrench' })
      links.push({ title: 'hosting.spaces', icon: 'cog' })

      if (this.isWorldOwner) {
        links.push({ title: 'hosting.users', icon: 'users' })
      }
      return links
    },
    world () {
      return this.$store.getters['auth/getWorld']
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    },
    hostings () {
      return this.$store.getters['hosting/getHostings']
    },
    hostingClone () {
      return {...this.$store.getters['hosting/getCurrentHosting']()}
    },
    title () {
      if (!this.hosting) {
        return ''
      } else if (!this.hosting.parent) {
        return this.hosting.name
      } else {
        return this.hostings.find((el) => {
          return el.id == this.hosting.parent
        }).name
      }
    },
    titleSuffix () {
      if (!this.hosting || !this.hosting.parent) {
        return ''
      } else {
        return `${this.hosting.name}`
      }
    },
    worldHasCard () {
      return this.$store.getters['auth/worldHasCard']
    },
    canGoOnline () {
      if (!this.hosting.checklist || this.hosting.checklist.length == 0) {
        return false
      }
      let canGoOnline = true
      Object.keys(this.hosting.checklist).map((task) => {
        let done = this.hosting.checklist[task]
        if (!done) {
          canGoOnline = false
        }
      })

      if (!this.worldHasCard && this.hosting.billing === 'STRIPE') {
        canGoOnline = false
      }

      return canGoOnline
    }
  },
  methods: {
    returnToHostingPage () {
      this.$router.push({name: 'hosting.index', params: {world: this.$route.params.world}})
    },
    checklistLink (task) {
      if (task === "domain" ||task === "dns") {
        return {
          name: 'hosting.overview',
          params: { id: this.$route.params.id, hosting: this.$route.params.hosting }
        }
      }
      else if (task === "files") {
        return {
          name: 'hosting.access.ftp',
          params: { id: this.$route.params.id, hosting: this.$route.params.hosting }
        }
      }
      else if (task === "card") {
        return {
          name: 'World Subscription',
          params: { id: this.$route.params.id }
        }
      }
      return ''
    },
    goOnline () {
      this.loading = true
      this.hostingClone.status = "ONLINE"
      this.$store.dispatch('hosting/' + HOSTING_UPDATE, this.hostingClone).then(data => {
        this.loading = false
        this.notifSuccess(this.$t('modules.hosting.go_online_message_success'))
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    }
  },
  mounted () {
    this.$store.dispatch('hosting/' + CURRENT_HOSTINGID_SET, this.$route.params.hosting)
  },
  watch: {
    '$route.params.hosting': {
      initial: true,
      handler (hostingId) {
        this.$store.dispatch('hosting/' + CURRENT_HOSTINGID_SET, hostingId)
      },
    },
    '$route.params.space': {
      initial: true,
      handler (spaceId) {
        this.$store.dispatch('hosting/' + CURRENT_SPACEID_SET, spaceId)
      }
    },
  },
}
</script>

<style scoped>
</style>
