<template>

  <div class="card" v-if="entity && isCurrentUserWorldInternal || hasPublicAccess">
    <div class="card-header bg-transparent border-0 pb-0 font-weight-semibold h5 m-0 text-slate">
      <i class="icon-link2 mr-2"></i>
      <span class="card-title font-weight-semibold">{{ $t('moduleentity.publicaccess.title') }}</span>
    </div>
    <div class="card-body pt-2">
      <div v-if="isCurrentUserWorldInternal">
        <slot />
      </div>
      <div v-if="loading && isCurrentUserWorldInternal">
        <span class="text-gray mr-1"><loader /></span>
        {{ $t('moduleentity.publicaccess.enable') }}
      </div>
      <div v-else-if="hasPublicAccess">
        <div v-if="isCurrentUserWorldInternal" class="cursor-pointer" @click="disablePublicAccess()">
          <i class="icon-checkbox-checked2 text-success mr-1 "></i>
          {{ $t('moduleentity.publicaccess.enable') }}
        </div>
        <div class="border bg-light form-control form-control-sm mt-1 py-1 px-2 cursor-pointer public-link-wrapper" @click="copyPublicLink()" >
          {{ publicUrl }}
          <span v-if="copied" class="border border-white text-uppercase bg-success rounded font-weight-bold copied">{{ $t('common.copied') }}</span>
        </div>
        <div class="qr mt-2 text-center">
          <vue-qr
            :text="publicUrl"
            :size="240"
            :margin="1"
          />
        </div>

        <input type="text" class="clip" ref="publiclinkinput" :value="publicUrl" readonly @click="copyPublicLink()" />
      </div>
      <div v-else class="cursor-pointer" @click="enablePublicAccess()">
        <i class="icon-checkbox-unchecked2 mr-1 "></i>
        {{ $t('moduleentity.publicaccess.enable') }}
      </div>
    </div>
  </div>

</template>

<script>


import VueQr from 'vue-qr'

import {
  MODULE_REFRESH_PUBLICKEY,
  MODULE_CANCEL_PUBLICKEY,
} from '@/store/mutations-types'

export default {
  tag: 'module-entity-public-access',
  name: 'ModuleEntityPublicAccess',
  props: ['entity', 'saving', 'publicLink'],
  components: {
    VueQr,
  },
  data () {
    return {
      publickey: null,
      loading: false,
      copied: false,
    }
  },
  mounted () {
  },
  methods: {
    enablePublicAccess () {
      this.loading = true

      this.$store.dispatch('module/' + MODULE_REFRESH_PUBLICKEY, {
        worldId: this.$route.params.world,
        entityId: this.entity.id,
      }).then(publickey => {
        this.loading = false
        this.publickey = publickey
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
    disablePublicAccess () {
      this.loading = true

      this.$store.dispatch('module/' + MODULE_CANCEL_PUBLICKEY, {
        worldId: this.$route.params.world,
        entityId: this.entity.id,
      }).then(publickey => {
        this.loading = false
        this.publickey = null
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
    copyPublicLink () {
      this.$refs.publiclinkinput.select()
      document.execCommand("copy")
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 1500)
    },
  },
  computed: {
    isCurrentUserWorldInternal () {
      return this.$store.getters['auth/isWorldInternal']
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    hasPublicAccess () {
      return this.publickey != null
    },
    publicUrl () {
      if (!this.hasPublicAccess) {
        return ''
      }
      return this.publicLink
    },
  },
  watch: {
    entity: {
      handler (newVal) {
        this.publickey = newVal.publickey
      },
      immediate: true,
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.clip {
  position: fixed;
  right: -10000px;
  bottom: -10000px;
}
.public-link-wrapper {
  position: relative;
  .copied {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 26px;
    line-height: 24px;
    text-align: center;
    width: 50%;
    margin-left: -25%;
    margin-top: -13px;
  }
}
.qr img {
  max-width: 120px;
  height: auto;
}
</style>
