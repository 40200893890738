<template>

  <component
    :is="to ? 'router-link' : 'a'"
    :to="to"
    href="#"
    class="button-icon-action"
    :class="classes"
    v-tooltip="title"
  >
    <i :class="icon" />
  </component>

</template>

<script>

export default {
  tag: 'el-table-action',
  name: 'TableAction',
  props: ['icon', 'title', 'to', 'classes'],
  data () {
    return {
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.button-icon-action {
  display: inline-block;
  border-radius: 100px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  text-align:center;
  background: #eee;
  margin-left:6px;
  cursor: pointer;
  transition: .1s all ease;
  color: #333;
  &.view-link {
    background: none;
    margin-right: -10px;
  }
  &:hover {
    background: #333;
    i {
      color: #eee;
    }
  }
}
</style>
