import actions from '../../src/router/actions'

const routes = [
  {
    path: '/dev',
    name: 'dev.index',
    component: () => import('./pages/Index.vue'),
    beforeEnter: actions.needSuperAdmin,
    meta: {
        sidebar: false,
        icon: 'hammer-wrench',
    }
  }
];

export default routes
