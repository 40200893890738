<template>
    <h4 class="mb-0 text-slate font-weight-semibold">
        <i :class="icon + ' mr-1'" v-if="icon"></i>
        {{ title }}
        <span v-if="suffix"> <span class="font-weight-normal">—</span> <span v-html="suffix"></span></span>
    </h4>
</template>

<script>
export default {
  tag: 'el-card-title',
  props: {
    icon: {
      type: String,
    },
    suffix: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
