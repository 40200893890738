/* eslint-disable promise/param-names */

import {
TEMPLATES_GET_LIST,
TEMPLATES_USE,
} from './mutations-types'
import Api from '../../src/api'

const state = {
}

const getters = {
}

const actions = {

  [TEMPLATES_GET_LIST]:({}, worldId) => {
    return Api.templates.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

  [TEMPLATES_USE]:({}, data) => {
    return Api.templates.use(data.worldId, data.templateId)
    .then(resp => {
      return resp.data.success
    })
  },

}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
