import instance from './_instance'

export default {

  get (worldId) {
    return instance
      .get(`worlds/${worldId}`)
  },

  update (world) {
    let worldId = world instanceof FormData ? world.get('id') : world.id
    return instance
      .post(`worlds/${worldId}`, world)
  },

  users (worldId) {
    return instance
      .get(`worlds/${worldId}/users`)
  },

  user (worldId, userId) {
    return instance
      .get(`worlds/${worldId}/users/${userId}`)
  },

  tribes (worldId) {
    return instance
      .get(`worlds/${worldId}/tribes`)
  },

  bills (worldId) {
    return instance
      .get(`worlds/${worldId}/bills`)
  },

  tribes_add (worldId, name) {
    return instance
      .post(`worlds/${worldId}/tribes`, {
        name: name
      })
  },

  tribes_edit (worldId, tribeId, name) {
    return instance
      .put(`worlds/${worldId}/tribes/${tribeId}`, {
        name: name
      })
  },

  tribes_remove (worldId, tribeId) {
    return instance
      .delete(`worlds/${worldId}/tribes/${tribeId}`)
  },

  tribes_details (worldId, tribeId) {
    return instance
      .get(`worlds/${worldId}/tribes/${tribeId}`)
  },

  tribes_owner (worldId, tribeId, userId, ownerStatus) {
    return instance
      .put(`worlds/${worldId}/tribes/${tribeId}/owner`, {
        user_id: userId,
        owner: ownerStatus
      })
  },

  invitations (worldId) {
    return instance
      .get(`worlds/${worldId}/invitations`)
  },

  invite (worldId, data) {
    return instance
      .post(`worlds/${worldId}/invitations`, data)
  },

  invitation_response (worldId, accept) {
    return instance
      .put(`worlds/${worldId}/invitations`, {
        accept: accept,
      })
  },

  owner (worldId, userId, ownerStatus) {
    return instance
      .put(`worlds/${worldId}/owner`, {
        user_id: userId,
        owner: ownerStatus,
      })
  },

  internal (worldId, userId, internalStatus) {
    return instance
      .put(`worlds/${worldId}/internal`, {
        user_id: userId,
        internal: internalStatus,
      })
  },

  user_infos (worldId, managerId) {
    return instance
      .put(`worlds/${worldId}/user_infos`, {
        manager_id: managerId,
      })
  },

  tribes_user_add (worldId, tribeId, userId) {
    return instance
      .post(`worlds/${worldId}/tribes/${tribeId}/add_user/${userId}`)
  },

  tribes_user_remove (worldId, tribeId, userId) {
    return instance
      .delete(`worlds/${worldId}/tribes/${tribeId}/remove_user/${userId}`)
  },

  user_add (worldId, data) {
    return instance
      .post(`worlds/${worldId}/add_user`, data)
  },

  user_remove (worldId, userId) {
    return instance
      .delete(`worlds/${worldId}/remove_user/${userId}`)
  },

  coupon_check (worldId, coupon) {
    return instance
      .post(`worlds/${worldId}/coupon_check`, {
        coupon: coupon
      })
  },

  subscription (worldId, data) {
    let dataToSend = {
      subscription_id: data.subscriptionId,
    }
    if (data.coupon) {
      dataToSend.coupon = data.coupon
    }
    return instance
      .post(`worlds/${worldId}/subscription`, dataToSend)
  },

  subscription_cancel (worldId) {
    return instance
      .delete(`worlds/${worldId}/subscription`)
  },

  permissions (worldId) {
    return instance
      .get(`worlds/${worldId}/permissions`)
  },

  permissions_update (worldId, permissions) {
    return instance
      .put(`worlds/${worldId}/permissions`, { permissions })
  },

  trial_extend (worldId) {
    return instance
      .post(`worlds/${worldId}/trial_extend`, {})
  },

  modules (worldId) {
    return instance
      .get(`worlds/${worldId}/modules`)
  },

  module_enable (worldId, module) {
    return instance
      .post(`worlds/${worldId}/module_enable`, { module })
  },

}
