import actions from '@/router/actions'
import store from '@/store'
import i18n from '@/i18n'

/**
* @vuese
*  need auth and permission true to marketplace
*/
let action = {
  needAccessToMarketplace: (to, from, next) => {
    if (store.getters['auth/isAuthenticated'] && store.getters['auth/getWorld'].modules.marketplace == true) {
      next()
      return
    }
    // If false
    next('/')
  }
}

const routes = [
  {
    path: '/world/:world/marketplace',
    name: 'marketplace.index',
    component: () => import(/* webpackChunkName: "module-marketplace" */ './pages/Index.vue'),
    beforeEnter: actions.needAuthComplete && action.needAccessToMarketplace && action.needSuperAdmin,
    meta: {
      title: i18n.t('modules.marketplace.title'),
      module: true,
      moduleName: "marketplace",
      icon: 'cart',
    }
  },
  {
    path: '/world/:world/marketplace/:productId/:target/product',
    name: 'marketplace.Detail',
    component: () => import(/* webpackChunkName: "module-marketplace" */ './pages/Details.vue'),
    beforeEnter: actions.needAuthComplete && action.needAccessToMarketplace && action.needSuperAdmin,
    meta: {
      title: i18n.t('modules.marketplace.title'),
      module: true,
      moduleName: "marketplace",
      icon: 'cart',
      // sidebar: true,
      id: null,
      search: false
    }
  }
];

export default routes
