import actions from '../../src/router/actions'

const routes = [
  {
    path: '/world/:world/quest',
    name: 'quest.index',
    component: () => import(/* webpackChunkName: "module-quest" */ './pages/Index.vue'),
    beforeEnter: actions.needAuthComplete,
    props: true,
    meta: {
      icon: 'earth',
    }
  }
];

export default routes
