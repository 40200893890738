import store from '../../src/store'

let user = store.getters['auth/getProfile']

let links = [
  {
    to: { name: 'dev.index' },
    module: 'core',
    owner_only: true,
    condition: (user && user.roles_slugs && user.roles_slugs.includes('admin')) ? true : false,
    title: 'modules.dev.title',
    weight: 999,
    group: 'admin',
    image: require('../../src/assets/images/support.png'),
    active: true,
  },
]

export default links
