import { render, staticRenderFns } from "./_Navbar.vue?vue&type=template&id=2ca967f6&scoped=true&"
import script from "./_Navbar.vue?vue&type=script&lang=js&"
export * from "./_Navbar.vue?vue&type=script&lang=js&"
import style0 from "./_Navbar.vue?vue&type=style&index=0&id=2ca967f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca967f6",
  null
  
)

export default component.exports