import actions from '../../src/router/actions'

const routes = [
  {
    path: '/world/:world/templates',
    name: 'templates.index',
    component: () => import('./pages/TemplatesIndex.vue'),
    beforeEnter: actions.needWorldOwner,
    meta: {
      icon: 'versions',
    },
  },
];

export default routes
