import actions from '../../src/router/actions'
import store from '../../src/store'
import i18n from '@/i18n'

/**
* @vuese
* This route accessible just by user partnership
*/
const needAuthCompleteAndPartnership = (to, from, next) => {

  // If user owns the world, need phone to be verified
  let phoneOK = false
  if (store.getters['auth/isWorldOwner'] === false) {
    phoneOK = true
  } else if (store.getters['auth/isWorldOwner'] && store.getters['auth/isPhoneVerified']) {
    phoneOK = true
  }

  if (store.getters['auth/isAuthenticated'] && store.getters['auth/isWorldOwner'] && phoneOK && store.getters['auth/getWorld'].modules.partnership) {
    next()
    return
  } else if(store.getters['auth/isAuthenticated'] && !phoneOK) {
    next('/phone_verification')
    return
  }
  // If false
  next('/login')
}

const routes = [
  // Link partnership for Dashboard
  {
    path: '/world/:world/partnership/dashboard',
    name: 'partnership.index',
    component: () => import(/* webpackChunkName: "module-partnership" */ './pages/PartnershipDashboard.vue'),
    beforeEnter: needAuthCompleteAndPartnership,
    meta: {
      icon: 'home4',
      module: true,
      moduleName: "partnership"
    },
    params: {
      module: "partnership"
    }
  },
  {
    path: '/world/:world/partnership/directory',
    name: 'partnership.directory_profile',
    component: () => import(/* webpackChunkName: "module-partnership" */ './pages/PartnershipDirectoryProfile.vue'),
    beforeEnter: needAuthCompleteAndPartnership,
    module: "partnership",
    meta: {
      icon: 'user',
      module: true,
      moduleName: "partnership"
    }
  },
  {
    path: '/register/:token',
    name: 'Register Partnership',
    component: () => import(/* webpackChunkName: "app-anonymous" */ '@/pages/auth/RegisterForm.vue'),
    beforeEnter: actions.needAnonymous,
    meta: {
      title: i18n.t('user.register.title'),
      layout: 'authfake'
    }
  },
];

export default routes
