/* eslint-disable promise/param-names */

import {
PROCESS_GET_PROCESSTEMPLATES,
PROCESS_GET_PROCESSTEMPLATE,
PROCESS_GET_PROCESSTEMPLATERESULTS,
PROCESS_CREATE_PROCESSTEMPLATE,
PROCESS_UPDATE_PROCESSTEMPLATE,
PROCESS_ADD_MEDIA_PROCESSTEMPLATE,
PROCESS_GET_PROCESSS,
PROCESS_GET_PROCESS,
PROCESS_CREATE_PROCESS,
PROCESS_UPDATE_PROCESS,

PROCESS_GET_FORMTEMPLATES,
PROCESS_GET_FORMTEMPLATE,
PROCESS_GET_FORMTEMPLATERESULTS,
PROCESS_CREATE_FORMTEMPLATE,
PROCESS_UPDATE_FORMTEMPLATE,
PROCESS_ADD_MEDIA_FORMTEMPLATE,
PROCESS_GET_FORMS,
PROCESS_GET_FORM,
PROCESS_CREATE_FORM,
PROCESS_UPDATE_FORM,
} from './mutations-types'
import Api from '../../src/api'

const state = {
}

const getters = {
}

const actions = {

  // Process

  [PROCESS_GET_PROCESSTEMPLATES]:({}, worldId) => {
    return Api.process.process.template.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_GET_PROCESSTEMPLATE]:({}, data) => {
    return Api.process.process.template.get(data.worldId, data.templateId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_GET_PROCESSTEMPLATERESULTS]:({}, data) => {
    return Api.process.process.template.results(data.worldId, data.templateId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_CREATE_PROCESSTEMPLATE]:({}, data) => {
    return Api.process.process.template.create(data.worldId, data)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_UPDATE_PROCESSTEMPLATE]:({}, data) => {
    return Api.process.process.template.update(data.worldId, data.templateId, data)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_ADD_MEDIA_PROCESSTEMPLATE]:({}, data) => {
    return Api.process.process.template.addMedia(data.worldId, data.templateId, data.base64, data.replace)
    .then(resp => {
      return resp.data.success
    })
  },


  [PROCESS_GET_PROCESSS]:({}, worldId) => {
    return Api.process.process.process.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_GET_PROCESS]:({}, data) => {
    return Api.process.process.process.get(data.worldId, data.processId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_CREATE_PROCESS]:({}, data) => {
    return Api.process.process.process.create(data.worldId, data)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_UPDATE_PROCESS]:({}, data) => {
    return Api.process.process.process.update(data.worldId, data.processId, data)
    .then(resp => {
      return resp.data.success
    })
  },

  // Forms

  [PROCESS_GET_FORMTEMPLATES]:({}, worldId) => {
    return Api.process.form.template.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_GET_FORMTEMPLATE]:({}, data) => {
    return Api.process.form.template.get(data.worldId, data.templateId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_GET_FORMTEMPLATERESULTS]:({}, data) => {
    return Api.process.form.template.results(data.worldId, data.templateId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_CREATE_FORMTEMPLATE]:({}, data) => {
    return Api.process.form.template.create(data.worldId, data)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_UPDATE_FORMTEMPLATE]:({}, data) => {
    return Api.process.form.template.update(data.worldId, data.templateId, data)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_ADD_MEDIA_FORMTEMPLATE]:({}, data) => {
    return Api.process.form.template.addMedia(data.worldId, data.templateId, data.base64, data.replace)
    .then(resp => {
      return resp.data.success
    })
  },


  [PROCESS_GET_FORMS]:({}, worldId) => {
    return Api.process.form.form.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_GET_FORM]:({}, data) => {
    return Api.process.form.form.get(data.worldId, data.processId)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_CREATE_FORM]:({}, data) => {
    return Api.process.form.form.create(data.worldId, data)
    .then(resp => {
      return resp.data.success
    })
  },

  [PROCESS_UPDATE_FORM]:({}, data) => {
    return Api.process.form.form.update(data.worldId, data.processId, data)
    .then(resp => {
      return resp.data.success
    })
  },
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
