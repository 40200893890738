import instance from '../../src/api/_instance'

export default {

  categories: {

    get (worldId) {
      return instance
        .get(`worlds/${worldId}/wiki/categories`)
    },

  },

  content: {

    index (worldId) {
      return instance
        .get(`worlds/${worldId}/wiki/content`)
    },
    get (worldId, contentId) {
      return instance
        .get(`worlds/${worldId}/wiki/content/${contentId}`)
    },
    clone (worldId, contentId) {
      return instance
        .get(`worlds/${worldId}/wiki/content/${contentId}/clone`)
    },
    useTemplate (worldId, contentId) {
      return instance
        .get(`worlds/${worldId}/wiki/content/${contentId}/useTemplate`)
    },
    history (worldId, contentId, page) {
      return instance
        .get(`worlds/${worldId}/wiki/content/${contentId}/history?page=${page}`)
    },
    create (data) {
      return instance
        .post(`worlds/${data.world_id}/wiki/content`, data)
    },
    send (data) {
      return instance
        .post(`worlds/${data.world_id}/wiki/content/${data.content_id}/send`, {
          user_id: data.user_id,
        })
    },
    addMedia (worldId, contentId, data) {
      return instance
        .post(`worlds/${worldId}/wiki/content/${contentId}/media`, data)
    },
    set (data) {
      return instance
        .put(`worlds/${data.world_id}/wiki/content/${data.id}`, data)
    },
    delete (data) {
      return instance
        .delete(`worlds/${data.world_id}/wiki/content/${data.id}`)
    },

  },

  tokens: {

    index (worldId) {
      return instance
        .get(`worlds/${worldId}/wiki/token`)
    },
    create (data) {
      return instance
        .post(`worlds/${data.world_id}/wiki/token`, data)
    },
    set (data) {
      return instance
        .put(`worlds/${data.world_id}/wiki/token/${data.id}`, data)
    },
    delete (data) {
      return instance
        .delete(`worlds/${data.world_id}/wiki/token/${data.id}`)
    },

  },

  onboarding: {

    index (worldId) {
      return instance
        .get(`worlds/${worldId}/wiki/onboarding`)
    },
    get (data) {
      return instance
        .get(`worlds/${data.world_id}/wiki/onboarding/${data.id}`)
    },
    create (data) {
      return instance
        .post(`worlds/${data.world_id}/wiki/onboarding`, data)
    },
    edit (data) {
      return instance
        .post(`worlds/${data.world_id}/wiki/onboarding/${data.id}`, data)
    },
    delete (data) {
      return instance
        .delete(`worlds/${data.world_id}/wiki/onboarding/${data.id}`)
    },
    complete (data) {
      return instance
        .post(`worlds/${data.world_id}/wiki/onboarding/${data.id}/complete`, data)
    },
    getCompleted (worldId) {
      return instance
        .get(`worlds/${worldId}/wiki/onboardingcompleted`)
    },
    getSheets (worldId) {
      return instance
        .get(`worlds/${worldId}/wiki/onboardingsheets`)
    },
    useTemplate (worldId, contentId) {
      return instance
        .get(`worlds/${worldId}/wiki/onboarding/${contentId}/useTemplate`)
    },

  },

}
