import actions from '../../src/router/actions'

const routes = [
  {
    path: '/world/:world/saas/kpi',
    name: 'saas.kpi.index',
    component: () => import('./pages/Index.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
        sidebar: false,
        icon: 'chart',
    }
  }
];

export default routes
