<template>
  <div>

    <el-alert type="info">
      {{ $t('form.world.permissions_owner_msg') }}
    </el-alert>
    <div class="text-center" v-if="loading">
      <loader />
    </div>
    <form @submit.prevent="editPermissions" v-else-if="permissions && !loading">

      <div v-for="moduleKey in moduleList" v-if="world.modules && world.modules[moduleKey] && world.modules[moduleKey] == true">
        <div class="card card-content" v-if="permissions.modules[moduleKey]">
          <div class="card-header py-3">
            <div class="d-md-flex justify-content-between align-items-start">
              <h5 class="mb-0 cursor-pointer d-md-flex align-items-center" @click="moduleKey == expanded ? expanded = null : expanded = moduleKey">
                <div class="d-flex" v-if="menuLinksFormattedByModule[moduleKey] && menuLinksFormattedByModule[moduleKey].length > 0">
                  <div class="d-flex align-items-center" v-for="(link, linkIndex) in menuLinksFormattedByModule[moduleKey]">
                    <i :class="`mr-1 text-primary icon-${link.icon}`"/>
                    <el-card-title class="" :title="link.title" />
                    <span class="text-slate small p-1" v-if="(linkIndex+1) < menuLinksFormattedByModule[moduleKey].length">&</span>
                  </div>
                </div>
                <div v-else>
                  <i :class="`pt-1 small mr-1 text-primary icon-${permissions.modules[moduleKey].icon}`"/>
                  <el-card-title  class="" :title="$te(`modules.${moduleKey}.title`) ? $t(`modules.${moduleKey}.title`) : moduleKey" />
                </div>
                <div>
                  <span class="badge badge-light mr-2" v-for="(page, pageKey, index) in permissions.modules[moduleKey].pages" v-if="(page.permissions != false) && $t(`modules.${moduleKey}.${pageKey}.title`) != $t(`modules.${moduleKey}.title`)">
                    {{ $t(`modules.${moduleKey}.${pageKey}.title`) }}
                  </span>
                </div>
              </h5>
              <div v-if="expanded != moduleKey">
                <span class="btn btn-sm btn-secondary" @click="expanded = moduleKey">
                  {{ $t('permissions.common.change_permissions') }}
                </span>
              </div>
            </div>
            <div class="row no-gutters" v-if="expanded == moduleKey">
              <div class="col-md-6 text-center d-none d-md-flex" />
              <div class="col-md-6 text-center d-none d-md-flex">
                <div class="row w-100 no-gutters">
                  <div class="col pt-1" v-for="(permission, permissionKey) in permissions.modules[moduleKey].permissions" v-tooltip="$te('permissions.' + permissionKey + '.desc') ? $t('permissions.' + permissionKey + '.desc') : null">
                    <i :class="'mr-1 icon-' + permission.icon" />
                    {{
                       $te(`modules.${moduleKey}.permissions.${permissionKey}`) ?
                         $t(`modules.${moduleKey}.permissions.${permissionKey}`) :
                          $te('permissions.' + permissionKey + '.title') ?
                             $t('permissions.' + permissionKey + '.title') :
                           permissionKey
                    }}
                    <div class="small text-grey">
                      <span v-if="permissionsByKey && permissionsByKey[moduleKey] && permissionsByKey[moduleKey][permissionKey] && permissionsByKey[moduleKey][permissionKey].length == permissions.tribes.length" class="cursor-pointer" @click="checkAllTribes(moduleKey, permissionKey, false)">{{ $t('form.world.permissions_uncheck_all') }}</span>
                      <span v-else class="cursor-pointer" @click="checkAllTribes(moduleKey, permissionKey)">{{ $t('form.world.permissions_check_all') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom border-light p-0" v-if="expanded == moduleKey">
            <div class="container-fluid m-0">
              <div class="row p-2 row-permission" v-for="(tribe, tribeKey) in permissions.tribes" :class="{ 'bg-light': !(tribeKey % 2) }">
                <div class="col-md-6 d-flex align-items-center">
                  <a class="mb-1 mb-md-0 d-inline-block text-dark mb-2 mb-md-0 font-weight-bold" v-tooltip="$t('common.open_new_tab')" :href="`/world/${$route.params.world}/tribes/${tribe.id}`" target="_new">
                    <el-avatar v-if="tribe.avatar && tribe.avatar.thumb" :src="tribe.avatar.thumb"/>
                    <el-avatar v-else-if="tribe.slug" :src="require('@/assets/images/categories/' + tribe.slug + '.svg')"/>
                    <span class="px-1">
                      {{ tribe.name }}
                    </span>
                  </a>
                </div>
                <div class="col-md-6 text-md-center">
                  <div class="row">
                    <div class="col" v-for="(permission, permissionKey) in permissions.modules[moduleKey].permissions">
                      <div class="cursor-pointer" @click="togglePermission(moduleKey, tribe.id, permissionKey)">
                        <i v-if="
                          permissions.permissions[moduleKey] &&
                          permissions.permissions[moduleKey][tribe.id] &&
                          permissions.permissions[moduleKey][tribe.id][permissionKey]
                        " class="icon-checkbox-checked2 mr-1 text-success" />
                        <i v-if="
                          !permissions.permissions[moduleKey] ||
                          !permissions.permissions[moduleKey][tribe.id] ||
                          !permissions.permissions[moduleKey][tribe.id][permissionKey]
                        " class="icon-checkbox-unchecked2 mr-1 text-grey" />
                        <span class="d-md-none">
                          {{
                            $te(`modules.${moduleKey}.permissions.${permissionKey}`) ?
                              $t(`modules.${moduleKey}.permissions.${permissionKey}`) :
                                $te('permissions.' + permissionKey + '.title') ?
                                  $t('permissions.' + permissionKey + '.title') :
                                permissionKey
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="mt-1" v-for="(permission, permissionKey, indexpermission) in permissions.modules[moduleKey].permissions">
              <span class="mr-2 d-inline-block font-weight-bold" v-tooltip="$te('permissions.' + permissionKey + '.desc') ? $t('permissions.' + permissionKey + '.desc') : null">
                {{
                 $te(`modules.${moduleKey}.permissions.${permissionKey}`) ?
                   $t(`modules.${moduleKey}.permissions.${permissionKey}`) :
                    $te('permissions.' + permissionKey + '.title') ?
                      $t('permissions.' + permissionKey + '.title') :
                       permissionKey
                }}&nbsp;: </span>
              <span v-if="permissionsByKey && permissionsByKey[moduleKey] && permissionsByKey[moduleKey][permissionKey] && permissionsByKey[moduleKey][permissionKey].length == permissions.tribes.length" class="text-success font-weight-bold">
                {{ $t('form.world.permissions_all_tribes') }}
              </span>
              <span v-else-if="permissionsByKey && permissionsByKey[moduleKey] && permissionsByKey[moduleKey][permissionKey] && permissionsByKey[moduleKey][permissionKey].length > 0">
                <span v-for="(tribeKey, index) in permissionsByKey[moduleKey][permissionKey]" :key="index" class="avatar-line" v-if="tribesByKey[tribeKey]">
                  <span class="avatar">
                    <el-avatar v-if="tribesByKey[tribeKey].avatar && tribesByKey[tribeKey].avatar.thumb" :src="tribesByKey[tribeKey].avatar.thumb" v-tooltip="tribesByKey[tribeKey].name" />
                    <el-avatar v-else-if="tribesByKey[tribeKey].slug" :src="require('@/assets/images/categories/' + tribesByKey[tribeKey].slug + '.svg')" v-tooltip="tribesByKey[tribeKey].name" />
                  </span>
                </span>
              </span>
              <span v-else class="text-grey">
                {{ $t('form.world.permissions_no_tribe') }}
              </span>
            </div>
          </div>
          <div class="card-footer pt-2" v-if="expanded == moduleKey">
            <button v-if="!saving" class="btn btn-lg btn-success text-white">{{ $t('common.save') }}</button>
            <div v-if="saving" class="bg-white p-2">
              <span class="text-success"><loader /></span>
            </div>
          </div>
        </div>

        <div v-else>
          <el-alert type="danger">Error loading permissions for {{ moduleKey }}</el-alert>
        </div>


      </div>
    </form>

  </div>
</template>

<script>

import Links from './Links'
import _ from 'lodash'
import {
  WORLD_PERMISSIONS_REQUEST,
  WORLD_PERMISSIONS_UPDATE,
} from '@/store/mutations-types'

export default {
  extends: Links,
  name: 'ModulesPermissionForm',
  tag: 'el-modules-permission-form',
  props: {
    moduleToShow: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      expanded: null,
      loading: false,
      saving: false,
      permissions: false,
    }
  },
  mounted () {
    this.loadPermissions()
  },
  computed: {
    moduleList () {
      return this.moduleToShow ?
              [this.moduleToShow] :
              this.permissions.modules ?
                _.keys(this.permissions.modules) :
                []
    },
    world () {
      return this.$store.getters['auth/getWorld']
    },
    tribesByKey () {
      return _.keyBy(this.permissions.tribes, 'id')
    },
    permissionsByKey () {
      let permissions = {}
      _.forEach(this.permissions.permissions, (tribesPermissions, moduleKey) => {
        _.forEach(tribesPermissions, (tribesPermission, tribeKey) => {
          _.forEach(tribesPermission, (permissionValue, permissionKey) => {
            if (permissionValue && permissionValue === true) {
              if (!permissions[moduleKey]) {
                permissions[moduleKey] = {}
              }
              if (!permissions[moduleKey][permissionKey]) {
                permissions[moduleKey][permissionKey] = []
              }
              permissions[moduleKey][permissionKey].push(tribeKey)
            }
          })
        })
      })

      return permissions
    },
  },
  methods: {
    loadPermissions () {
      this.loading = true
      this.$store.dispatch('world/' + WORLD_PERMISSIONS_REQUEST, this.world.id).then(permissions => {
        this.permissions = {...permissions}
        this.loading = false
      })
    },
    checkAllTribes (moduleKey, permissionKey, check = true) {
      if (this.saving) {
        return
      }
      if (Array.isArray(this.permissions.permissions) && this.permissions.permissions.length == 0) {
        this.$set(this.permissions, 'permissions', {})
      }
      if (!this.permissions.permissions[moduleKey]) {
        this.$set(this.permissions.permissions, moduleKey, {})
      }
      _.forEach(this.permissions.tribes, tribe => {
        if (!this.permissions.permissions[moduleKey][tribe.id]) {
          this.$set(this.permissions.permissions[moduleKey], tribe.id, {})
        }
        this.permissions.permissions[moduleKey][tribe.id] = Object.assign({}, this.permissions.permissions[moduleKey][tribe.id],
          {
            [permissionKey]: check
          }
        )
      })
    },
    togglePermission(moduleAlias, tribeId, permissionKey) {
      if (this.saving) {
        return
      }
      if (
        this.permissions.permissions[moduleAlias] &&
        this.permissions.permissions[moduleAlias][tribeId] &&
        this.permissions.permissions[moduleAlias][tribeId][permissionKey]
      ) {
        this.permissions.permissions[moduleAlias][tribeId][permissionKey] = !this.permissions.permissions[moduleAlias][tribeId][permissionKey]
      } else if (
        this.permissions.permissions[moduleAlias] &&
        this.permissions.permissions[moduleAlias][tribeId]
      ) {
        this.permissions.permissions[moduleAlias][tribeId] = Object.assign({}, this.permissions.permissions[moduleAlias][tribeId],
          {
            [permissionKey]: true
          }
        )
      } else if (
        this.permissions.permissions[moduleAlias]
      ) {
        this.permissions.permissions[moduleAlias] = Object.assign({}, this.permissions.permissions[moduleAlias],
          {
            [tribeId]: {
              [permissionKey]: true
            }
          }
        )
      } else {
        this.permissions.permissions = Object.assign({}, this.permissions.permissions,
          {
            [moduleAlias]: {
              [tribeId]: {
                [permissionKey]: true
              }
            }
          }
        )
      }
    },
    editPermissions () {
      this.saving = true
      this.$store.dispatch('world/' + WORLD_PERMISSIONS_UPDATE, {
        worldId: this.$route.params.world,
        permissions: this.permissions.permissions
      }).then(data => {
        this.saving = false
        this.notifSuccess(this.$t('form.world.permissions_edit_success'))
        this.expanded = null
      })
      .catch(error => {
        this.saving = false
        this.notifError(error)
      })
    },
  },
  watch: {
    moduleToShow (newVal) {
      this.loadPermissions()
    },
  },
}
</script>
<style lang="scss" scoped>
.row-permission {
  &:hover {
    background: rgba(black, .05) !important;
  }
}
</style>
