import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import routes from './routes'

import {
    GLOBAL_BANNERS,
    WORLD_USERS_REQUEST,
    WORLD_TRIBES_REQUEST,
    USER_NOTIFICATIONS_GET_UNREAD,
    GLOBAL_TRANSLATIONS,
} from '../store/mutations-types'

Vue.use(Router)

const router = new Router({
  mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {

  // Global path with _ replaced by the current world
  if (to.params && to.params.world && to.params.world === '_' && store.getters['auth/hasWorld']) {
    let params = to.params
    params.world = store.getters['auth/getWorld'].id
    next(router.resolve({
        name: to.name,
        params: params,
        query: to.query,
    }).href)
  }

  let currentTime = new Date().getTime()

  if (store.getters['auth/isAuthenticated'] && store.getters['auth/hasWorld']) {

      let isInternal = store.getters['auth/isWorldInternal']

      const bannersRefreshInterval = 60 // minutes
      let bannersNeedsRefresh = store.getters['global/bannersRefreshTime'] === null || (currentTime - store.getters['global/bannersRefreshTime']) / 1000 / 60 > bannersRefreshInterval
      // Get banners data if expired
      if (bannersNeedsRefresh) {
        store.dispatch('global/' + GLOBAL_BANNERS, store.getters['auth/getWorld'].id)
      }

      const usersRefreshInterval = 60 // minutes
      let usersNeedsRefresh = store.getters['world/usersRefreshTime'] === null || (currentTime - store.getters['world/usersRefreshTime']) / 1000 / 60 > usersRefreshInterval
      // Get users data if expired
      if ((store.getters['world/getUsers'] == null || usersNeedsRefresh) && isInternal) {
        store.dispatch('world/' + WORLD_USERS_REQUEST, store.getters['auth/getWorld'].id)
      }

      const tribesRefreshInterval = 60 // minutes
      let tribesNeedsRefresh = store.getters['world/tribesRefreshTime'] === null || (currentTime - store.getters['world/tribesRefreshTime']) / 1000 / 60 > tribesRefreshInterval
      // Get tribes data if expired
      if (tribesNeedsRefresh && isInternal) {
        store.dispatch('world/' + WORLD_TRIBES_REQUEST, store.getters['auth/getWorld'].id)
      }

      const notificationsRefreshInterval = 2 // minutes
      let notificationsNeedsRefresh = store.getters['auth/notificationsRefreshTime'] === null || (currentTime - store.getters['auth/notificationsRefreshTime']) / 1000 / 60 > notificationsRefreshInterval
      // Get notif data if expired
      if (notificationsNeedsRefresh) {
        store.dispatch('auth/' + USER_NOTIFICATIONS_GET_UNREAD, store.getters['auth/getWorld'].id)
      }

  }

  const translationsRefreshInterval = 120 // minutes
  let translationsNeedsRefresh = store.getters['global/translationsRefreshTime'] === null || (currentTime - store.getters['global/translationsRefreshTime']) / 1000 / 60 > translationsRefreshInterval
  // Get notif data if expired
  if (translationsNeedsRefresh) {
    store.dispatch('global/' + GLOBAL_TRANSLATIONS)
  }


  // Go to next page
  next()
  store.commit('global/changePage', to.path)

})

// Handle missing chunks
router.onError((error) => {
  const pattern = /Loading chunk (.*) failed/gm;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;

  if (isChunkLoadFailed) {
    // console.error(error)
    window.location.reload()
  } else {
    throw error;
  }
});

router.afterEach((to, from) => {

  let metaTitle = process.env.VUE_APP_TITLE
  // Manage page title
  if (store.getters['auth/isAuthenticated'] && store.getters['auth/hasWorld']) {
    metaTitle+= ' | ' + store.getters['auth/getWorld'].name
  }
  if (to.meta && to.meta.title) {
    metaTitle+= ' - ' + to.meta.title
  }
  document.title = metaTitle

  // Loading Hubspot Script
  // START Hubspot Page tracking
  if (process.env.VUE_APP_HUBSPOT_ID && process.env.VUE_APP_HUBSPOT_ID != '') {

      let toPath = to.path
      if (store.getters['auth/hasWorld']) {
          let currentWorld = store.getters['auth/getWorld']
          toPath = toPath.replace('world/' + currentWorld.id, 'world/_')
      }

      // Set the path to '/' and track the page
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', toPath]);
      _hsq.push(['trackPageView']);

  }
  // END Hubspot Page tracking

})

export default router
