/* eslint-disable promise/param-names */

import {
GET_CATEGORIES_LIST,
GET_PRODUCT_REQUEST,
STORE_PRODUCT,
UPDATE_PRODUCT,
DELETE_PRODUCT,
WISHLIST,
STORE_COMMENT,
REQUEST_USERS,
COMMAND_PRODUCT,
LIST_COMMANDS_USER,
CREATE_NEW_TAG
} from './mutations-types'
import Api from '../../src/api'

const state = {
  active: false,
  categories: [],
  tags: [],
  tribes: [],
  products: [],
  wishList: [],
  comments: [],
  users: [],
  commands: [],
  listCommands: [],
  currency: [],
  world_currency: null,
  currentFilters: {}
}

const getters = {

}

const actions = {
  [GET_CATEGORIES_LIST]: ({commit}, world) => {
    return Api.marketplace.getListTags(world)
    .then(resp => {
      commit('setListTags', resp.data.success)
    })
  },
  [GET_PRODUCT_REQUEST]: ({commit}, world) => {
    return Api.marketplace.list(world)
    .then(resp => {
      commit('setCategories', resp.data.success.categories)
      commit('setProducts', resp.data.success.products)
    })
  },
  [STORE_PRODUCT]: ({commit}, data) => {
    return Api.marketplace.storeProduct(data)
    .then(resp => {
      commit('setSingleProduct', resp.data.success.product)
      return resp.data.success
    })
  },
  [UPDATE_PRODUCT]: ({commit}, data) => {
    return Api.marketplace.updateProduct(data)
    .then(resp => {
      commit('updateProduct', resp.data.success.product[0])
      return resp.data.success
    })
  },
  [DELETE_PRODUCT]:({commit}, data) => {
    return Api.marketplace.deleteProduct(data)
    .then(resp => {
      if(resp.data.success['status'] == 1) {
        commit('deleteProduct', data.product)
      }
      return resp.data.success
    })
  },
  [WISHLIST]: ({commit}, data ) => {
    return Api.marketplace.myWishlistUpdate(data)
    .then(resp => {
      commit('setMyWishList', resp.data.success.wishlist)
      return resp.data.success
    })
  },
  [STORE_COMMENT]: ({commit}, data) => {
    return Api.marketplace.storeComment(data)
    .then(resp => {
      commit('SetListComment', resp.data.success.comments)
      return resp.data.success
    })
  },
  [REQUEST_USERS]: ({commit}, data) => {
    return Api.marketplace.getWorldUsers(data)
    .then(resp => {
      commit('setUsers', resp.data.success.users)
      return resp.data.success
    })
  },
  [COMMAND_PRODUCT]: ({commit}, data) => {
    return Api.marketplace.storeCommand(data)
    .then(resp => {
      commit('updateListCommandsUsers', resp.data.success.commands)
      return resp.data.success
    })
  },
  [LIST_COMMANDS_USER]: ({commit}, data) => {
    return Api.marketplace.getListCammand(data)
    .then(resp => {
      commit('setListCommandsUsers', resp.data.success.commands)
      return resp.data.success
    })
  },
  [CREATE_NEW_TAG]: ({commit}, data) => {
    return Api.marketplace.setTag(data)
    .then(resp => {
      commit('refreshTags', resp.data.success)
      return resp.data.success
    })
  }
}

const mutations = {
  setCategories (state, val) {
    state.categories = val
  },
  setListTags (state, val) {
    // state.tags = _.sortBy(val['tags'], 'name')
    state.tribes = val['tribes']
    let keys = Object.keys(val['wishlist'])
    state.wishlist = []
    keys.map(i => {
      state.wishlist.push(val['wishlist'][i])
    })
    state.currency = val['currency']
    state.world_currency = val['world_currency']
  },
  setProducts (state, val) {
    state.products = val
    state.comments = []
    state.products.map(item => {
      if(item.comments) {
        state.comments.push(...item.comments)
      }
    })
  },
  setSingleProduct (state, val) {
    state.products.unshift(...val)
  },
  updateProduct (state, val) {
    state.products = state.products.filter(elem => elem.id != val.id)
    state.products.push(val)
    state.products.sort((a, b) => {return new Date(b.created_at) - new Date(a.created_at)})
  },
  deleteProduct (state, val) {
    state.products = state.products.filter(elem => { return elem.id != val })
  },
  setMyWishList (state, val) {
    state.wishlist = val
  },
  SetListComment (state, val) {
    state.comments.push(val)
  },
  setUsers (state, val) {
    state.users = val
  },
  setListCommandsUsers (state, val) {
    state.listCommands = []
    val.map(item =>  {
      state.listCommands = [...item.product_id]
    })
  },
  updateListCommandsUsers (state, val) {
    state.listCommands = val.product_id
  },
  refreshTags (state, val) {
    state.tags.push(...val.tag)
    state.tags = _.sortBy(state.tags, 'name')
  },
  setCurrentFilters (state, val) {
    state.currentFilters = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
