<template>
  <div>

      <li class="nav-item" v-if="isAuth() && isProfileLoaded() && $store.getters['auth/isWorldOwner'] && world.modules && world.modules.academy">

        <router-link :to="{name: 'academy.index', params: { world: world.id }}" class="navbar-nav-link" v-tooltip.bottom="$t('modules.academy.title')">
          <i :class="`icon icon-` + $router.resolve({name: 'academy.index'}).route.meta.icon"/>
          <span class="d-md-none pl-2">
            {{ $t('modules.academy.title') }}
          </span>
        </router-link>

      </li>

  </div>
</template>

<script>

export default {
  tag: 'academy-navbar',
  name: 'Academy-navbar',
  props: ['data'],
  data () {
    return {
      value: null,
      appName: process.env.VUE_APP_TITLE
    }
  },
  computed: {
    /**
    * @vuese
    *  get world object
    */
    world () {
      return this.$store.getters['auth/getWorld']
    },
    isPhoneVerified () {
      return this.$store.getters['auth/isPhoneVerified']
    },
    worldHasSubscription () {
      return this.$store.getters['auth/worldHasSubscription'] || this.$store.getters['auth/worldOnTrial']
    },
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.icon {
  font-size: 1.4em;
}
</style>
