<template>

  <el-notification
    :notification="notification"
    :module="notifModule"
    :date="notification.created_at"
    :title="isEntityAccessRequest ?
      $t(`notifications.ask_access_entity`, { from_user: getUser(notification.data.data.from_user_id).full_name }) :
      $t(`notifications.ask_access_module`, { from_user: getUser(notification.data.data.from_user_id).full_name, module: notifModule })
    "
  >
    <div slot="actions">
      <el-notification-button
        :notification="notification"
        :callback="goToContent"
        :title="isEntityAccessRequest ?
          $t(`notifications.ask_access_entity_btn`) :
          $t(`notifications.ask_access_module_btn`)
        "
      />
    </div>
  </el-notification>

</template>

<script>

export default {
  tag: 'el-notification-core-ask_access',
  name: 'NotifCoreAskAccess',
  props: {
    notification: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    goToContent (notification) {

      // Manage module permissions links
      if (!this.isEntityAccessRequest) {
        return this.$router.push({name: 'world.permissions', params: { world: this.world.id }})
      }

      // Manage Wiki Links
      if (notification.data.data.module == 'wiki' && notification.data.data.entity == 'Content') {
        return this.$router.push({name: 'wiki.content', params: { world: this.world.id, content: notification.data.data.entity_id }})
      }
      // Manage proces links
      else if (notification.data.data.module == 'process' && notification.data.data.entity == 'ProcessTemplate') {
        let defaultType = 'process'
        if (this.notification.data.data.entitySubType) {
          defaultType = this.notification.data.data.entitySubType
        }
        return this.$router.push({name: defaultType + '.template.page', params: { world: this.world.id, template: notification.data.data.entity_id }})
      }
    },
    getUser (id) {
      return this.users.find(user => {
        return user.id == id
      })
    },
  },
  computed: {
    isEntityAccessRequest () {
      return this.notification.data.data.entity_id && this.notification.data.data.entity_id != null
    },
    users () {
      let users = this.$store.getters['world/getUsers'] ? this.$store.getters['world/getUsers'] : [];
      return users
    },
    world () {
      return this.$store.getters['auth/getWorld']
    },
    notifModule () {
      if (!this.notification.data.data.entitySubType) {
        return this.$t(`modules.${this.notification.data.data.module}.title`)
      } else {
        return this.$t(`modules.${this.notification.data.data.module}.${this.notification.data.data.entitySubType  }.title`)
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>
