<template>

  <div>

    <div class="w-100 d-flex align-items-center" :id="'step-' + id">
      <i :class="`icon-arrow-right8 pt-1`"></i>
      <span :class="`font-weight-bold pl-1 text-${classColorName} data`">{{ data }}</span>
    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-element-step',
  props: ['data', 'id', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
i {
  font-size: 1em;
}
.data {
  font-size: 1.8em;
}
</style>
