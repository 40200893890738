import actions from '../../src/router/actions'

const routes = [
  {
    path: '/world/:world/orgchart',
    name: 'orgchart.index',
    component: () => import(/* webpackChunkName: "module-orgchart" */ './pages/Index.vue'),
    beforeEnter: actions.needAuth,
    meta: {
        sidebar: false,
        icon: 'tree7',
    }
  }
];

export default routes
