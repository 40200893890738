import store from '@/store'
import actions from '@/router/actions'

const routes = [
  {
    path: '/world/:world/actionsboard/actions',
    name: 'actionsboard.index',
    component: () => import(/* webpackChunkName: "module-actionsboard" */ './pages/ActionsIndex.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      module: true,
      moduleName: "actionsboard",
      icon: 'check'
    }
  },
  {
    path: '/world/:world/actionsboard/fasts',
    name: 'actionsboard.fastsindex',
    component: () => import(/* webpackChunkName: "module-actionsboard" */ './pages/FastsIndex.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      module: true,
      moduleName: "actionsboard",
      icon: 'stack-star'
    }
  }
];

export default routes
