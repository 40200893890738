/* eslint-disable promise/param-names */

import {
MODULE_REQUEST,
MODULE_REQUEST_ARCHIVES,
MODULE_STORE,
MODULE_UPDATE,
MODULE_REMOVE,
MODULE_AUTOCOMPLETE,
MODULE_SET_DATA_FORM,
MODULE_RESET_DATA_FORM,
MODULE_REFRESH_ACCESSKEY,
MODULE_REFRESH_PUBLICKEY,
MODULE_CANCEL_PUBLICKEY,
MODULE_GET_BY_PUBLICKEY,
MODULE_SET_TEMPLATE,
MODULE_SET_STATUS,

MODULE_COMMENT_INDEX,
MODULE_COMMENT_STORE,
MODULE_COMMENT_UPDATE,
MODULE_COMMENT_DESTROY,
} from '../mutations-types'
import Api from '../../api'

const state = {
  dataForm: {}
}

const getters = {
  getDataForm: state => state.dataForm
}

const actions = {
  [MODULE_REQUEST]: ({}, data) => {
    return Api.module.list(data.worldId, data.moduleId, data.entityType, data.page)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_REQUEST_ARCHIVES]: ({}, data) => {
    return Api.module.archives(data.worldId, data.moduleId, data.entityType, data.page)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_STORE]: ({}, data) => {
    return Api.module.add(data.worldId, data.moduleId, data.entityType, data.data)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_UPDATE]: ({}, data) => {
    return Api.module.edit(data.worldId, data.moduleId, data.entityType, data.data)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_REMOVE]: ({}, data) => {
    return Api.module.remove(data.worldId, data.moduleId, data.entityType, data.data.id)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_AUTOCOMPLETE]: ({}, data) => {
    return Api.module.autocomplete(data.worldId, data.moduleId, data.entityType, data)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_REFRESH_ACCESSKEY]: ({}, data) => {
    return Api.module.refreshAccessKey(data.worldId, data.entityId)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_REFRESH_PUBLICKEY]: ({}, data) => {
    return Api.module.refreshPublicKey(data.worldId, data.entityId)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_CANCEL_PUBLICKEY]: ({}, data) => {
    return Api.module.cancelPublicKey(data.worldId, data.entityId)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_GET_BY_PUBLICKEY]: ({}, data) => {
    return Api.module.getByPublicKey(data.worldId, data.publickey)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_SET_TEMPLATE]: ({}, data) => {
    return Api.module.setTemplate(data.worldId, data.moduleId, data.entityId, data.data)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_SET_STATUS]: ({}, data) => {
    return Api.module.setStatus(data.worldId, data.moduleId, data.entityId, data.data)
      .then(resp => {
        return resp.data.success
      })
  },

  [MODULE_COMMENT_INDEX]: ({}, data) => {
    return Api.module.comments.index(data.worldId, data.moduleId, data.entityType, data.entityId)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_COMMENT_STORE]: ({}, data) => {
    return Api.module.comments.store(data.worldId, data.moduleId, data.entityType, data.entityId, {comment: data.comment})
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_COMMENT_DESTROY]: ({}, data) => {
    return Api.module.comments.destroy(data.worldId, data.moduleId, data.entityType, data.entityId, data.commentId)
      .then(resp => {
        return resp.data.success
      })
  },
  [MODULE_COMMENT_UPDATE]: ({}, data) => {
    return Api.module.comments.update(data.worldId, data.moduleId, data.entityType, data.entityId, data.commentId, {comment: data.comment})
      .then(resp => {
        return resp.data.success
      })
  },
}

const mutations = {
  [MODULE_RESET_DATA_FORM]: (state) => {
    state.dataForm = {}
  },
  [MODULE_SET_DATA_FORM]: (state, val) => {
    state.dataForm = val
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
