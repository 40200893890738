import actions from '../../src/router/actions'

const routes = [
  {
    path: '/world/:world/hostings',
    name: 'hosting.index',
    component: () => import('./pages/Index.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
        sidebar: false,
        icon: 'server',
    },
  },
  {
    path: '/world/:world/hostings/:hosting',
    name: 'hosting.page',
    component: () => import('./pages/Page.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
        sidebar: false,
    },
  },
  // {
  //   path: '/world/:world/hostings/:hosting/spaces',
  //   name: 'hosting.spaces',
  //   component: () => import('./pages/hosting/Spaces.vue'),
  //   beforeEnter: actions.needAuthComplete,
  // },
  // {
  //   path: '/world/:world/hostings/:hosting/spaces/:space',
  //   name: 'hosting.space',
  //   component: () => import('./pages/hosting/Space.vue'),
  //   beforeEnter: actions.needAuthComplete,
  // },
];

export default routes
