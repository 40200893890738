import axios from 'axios'
import store from '../store'
import NProgress from 'nprogress'
import Vue from 'vue'

let baseUrl = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_API_PREFIX + '/'
let tiemout = 30000

const instance = axios.create({
  baseURL: baseUrl,
  timeout: tiemout,
  onUploadProgress: (progressEvent) => {
    const { loaded, total } = progressEvent
    let progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total ) / 100

    if (progress < 1) {
      NProgress.set(0.2)
    } else {
      NProgress.done()
    }
  },
  headers: {
    'Content-Type': 'application/json'
  },
})

import { AUTH_REFRESH } from '../store/mutations-types'

const UNAUTHORIZED = 401
const NEEDACCESS = 412
const NOTFOUND = 404

instance.interceptors.request.use(
    config => {

      NProgress.set(0.2)

      if (store.getters['auth/isAuthenticated']) {
        config.headers['Authorization'] = 'Bearer ' + store.getters['auth/authToken']
      }

      config.metadata = { startTime: new Date()}
      return config;
    },
    error => Promise.reject(error)
)
instance.interceptors.response.use(
    response => {
      // response.config.metadata.endTime = new Date()
      // response.duration = response.config.metadata.endTime - response.config.metadata.startTime
      // console.info('>>> ' + response.config.method.toUpperCase() +' ' + response.config.url.replace(response.config.baseURL, ''), response.duration / 1000)
      NProgress.done()
      return response
    },
    error => {
      NProgress.done()

      if (error.code === 'ECONNABORTED') {
        Vue.prototype.$snotify.error(error.message);
      }

      const {status} = error.response
      if (status === UNAUTHORIZED && store.getters['auth/isAuthenticated']) {
        if (store.getters['auth/worldHasSubscription']) {
          Vue.prototype.$snotify.error(error.message);
        }
        store.dispatch('auth/' + AUTH_REFRESH)
      }
      else if (status === NOTFOUND) {
        Vue.prototype.$snotify.error(error.message);
      }
      return Promise.reject(error)
    }
)

export default instance
