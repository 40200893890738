<template>
  <div>
    <container :is="componentKey" v-for="(component, componentKey) in components">
    </container>
  </div>
</template>

<script>

import _ from 'lodash'

let components = {}
const requireComponent = require.context(
  // The relative path of the components folder
  '../../../Modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /global-message.vue$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  let tag = componentConfig.default.tag

  components[tag] = componentConfig.default || componentConfig
})


export default {
  components,
  tag: 'el-global-message-modules',
  name: 'Global-message-modules',
  data () {
    return {
      components: components
    }
  },
  computed: {
  },
}
</script>

<style scoped>
</style>
