import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']

let links = [
  {
    to: { name: 'academy.index', params: { world: world.id } },
    module: 'academy',
    owner_only: true,
    title: 'modules.academy.title',
    weight: 30,
    group: 'handbook',
    hide_in_sidebar: true,
    image: require('./assets/images/module-academy.svg'),
    description: 'modules.academy.module_desc',
  },
]

export default links
