import actions from '../../src/router/actions'

const routes = [
// Process
  {
    path: '/world/:world/process/template',
    name: 'process.template.index',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/ProcessTemplateList.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      icon: 'file-play2',
    }
  },
  {
    path: '/world/:world/process/template/:template',
    name: 'process.template.page',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/ProcessTemplate.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      sidebar: true,
      sidebar_close: true,
      icon: 'pencil',
    }
  },
  {
    path: '/world/:world/process/template/:template/results',
    name: 'process.template.results',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/ProcessTemplateResults.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      sidebar: false,
      icon: 'table2',
    }
  },
  {
    path: '/world/:world/process/process/:process',
    name: 'process.process.page',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/ProcessPage.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      sidebar: true,
    }
  },
  // Form
  {
    path: '/world/:world/form/template',
    name: 'form.template.index',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/ProcessTemplateList.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      icon: 'insert-template',
    }
  },
  {
    path: '/world/:world/form/template/:template',
    name: 'form.template.page',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/ProcessTemplate.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      sidebar: true,
      sidebar_close: true,
      icon: 'pencil',
    }
  },
  {
    path: '/world/:world/form/template/:template/results',
    name: 'form.template.results',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/ProcessTemplateResults.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      sidebar: false,
      icon: 'table2',
    }
  },
  {
    path: '/world/:world/form/form/:process',
    name: 'form.form.page',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/ProcessPage.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      sidebar: true,
    }
  },
  {
    path: '/public/:world/form/:publickey',
    name: 'public.form.form.page',
    component: () => import(/* webpackChunkName: "module-process" */ './pages/PublicProcessPage.vue'),
    meta: {
      layout: 'anonymous',
    }
  },
];

export default routes
