<template>

  <div class="loader-bar" :style="'animation-delay: .'+ animationDelay +'s'"></div>

</template>

<script>

/**
 * @group Core
 * Display spinning loader
 */
export default {
  tag: 'loader-bar',
  name: 'LoaderBar',
  props: {
    // Additional classes
    classes: {
      type: String
    }
  },
  data () {
    return {
      animationDelay: 1,
    }
  },
  mounted () {
    this.animationDelay = this.randomNumber(1,5)
  },
  methods: {
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  computed: {
  },
}
</script>

<style scoped>
.loader-bar {
  width: 100%;
  height: 1em;
  border-radius: 4px;
  background: #ccc;
  animation: loaderAnimation 2s infinite;
}

@keyframes loaderAnimation {
  0%   { opacity:.8; }
  50%  { opacity:0.4; }
  100% { opacity:.8; }
}
</style>
