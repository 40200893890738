/* eslint-disable promise/param-names */

import { WORLD_INVITATION_REQUEST, WORLD_INVITATION_REMOVE } from '../mutations-types'
import Api from '../../api'

const state = {
}

const getters = {
}

const actions = {
  [WORLD_INVITATION_REQUEST]: ({commit, dispatch}, token) => {
    return Api.world_invitation.get(token)
      .then(resp => {
        return resp.data.success
      })
  },

  [WORLD_INVITATION_REMOVE]: ({commit, dispatch}, data) => {
    return Api.world_invitation.remove(data.worldId, data.invitationId)
      .then(resp => {
        return resp.data.success
      })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
