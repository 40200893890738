import store from '../../src/store'
import Vue from '@/main.js'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']

let links = [
  {
    to: { name: 'marketplace.index', params: { world: world.id } },
    module: 'marketplace',
    title: 'groups.marketplace',
    // title: 'modules.marketplace.product.title',
    weight: 100,
    // group: 'marketplace',
    group: 'GROUP_BOTTOM',
    image: require('./assets/images/module.svg'),
    description: 'modules.marketplace.module_desc',
    condition: (Vue.userHasPermission('marketplace', 'view_module') || Vue.userHasPermission('marketplace', 'manage_module')),
  },
]

export default links
