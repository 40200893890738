import instance from '../../src/api/_instance'

export default {

    index (worldId, year, week) {
        let url = `worlds/${worldId}/presence/get`
        if (year && week) {
            url+= `/${year}/${week}`
        }
        return instance
        .get(url)
    },
    set (worldId, data) {
        return instance
        .post(`worlds/${worldId}/presence/set`, data)
    },

}
