export const GET_CATEGORIES_LIST = 'MarketPlace categories'
export const GET_PRODUCT_REQUEST = 'obtenir list product'
export const STORE_PRODUCT = "Ajouter nouveau produit"
export const UPDATE_PRODUCT = "Edit un produit"
export const DELETE_PRODUCT = "Delete un produit"
export const WISHLIST = "Wishlist user"
export const STORE_COMMENT = "Store Comment"
export const REQUEST_USERS = "get list users in world"

export const COMMAND_PRODUCT = "Command Product"
export const LIST_COMMANDS_USER = "lsit Commands users"


export const CREATE_NEW_TAG = "Cree une nouveau tag"
export const VOTE = "vote les produit"
