import instance from '../../src/api/_instance'

export default {

  questProgress (worldId) {
    return instance
      .get(`worlds/${worldId}/quest`)
  },

  questNextCard (worldId) {
    return instance
      .get(`worlds/${worldId}/quest_next`)
  },

  questAnswerCard (worldId, sheetId, status) {
    return instance
      .post(`worlds/${worldId}/quest`, {
        sheet: sheetId,
        status: status
      })
  },

}
