<template>
  <div class="text-center">
    <img class="mb-5" src="@/assets/images/emptyboard.svg">
    <h4 class="mb-3 font-weight-semibold" v-if="title">{{ title }}</h4>
    <p class="text-grey" v-if="text">{{ text }}</p>
    <div class="mt-3" v-if="getBanners(currentRouteName, 'EMPTY_LIST')"><el-banners :banners="getBanners(currentRouteName, 'EMPTY_LIST')"/></div>
  </div>
</template>

<script>
export default {
  tag: 'el-empty',
  props: [
    'title',
    'text',
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  width: 300px;
}
</style>
