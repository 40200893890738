<template>
  <div class="page-title d-flex">
    <h2>
      <!-- <i v-if="data.back" class="back icon-arrow-left52 mr-2" @click="$router.go(-1)"></i> -->
      <span class="font-weight-500">{{ title }}</span>
      <span v-if="titleSuffix" class=""> : {{ titleSuffix }}</span>
      <span v-if="$route.path != '/' && favorites">
        <i
          v-tooltip.right="$t('common.favorites_add')"
          v-if="!loading && !isCurrentPageFavorite"
          class="favorite icon-star-full2 ml-2 text-grey cursor-pointer"
          @click.prevent="addFavorite"
        />
        <span class="pl-2 text-grey" v-if="loading"><loader></loader></span>
        <i
          v-tooltip.right="$t('common.favorites_remove')"
          v-if="!loading && isCurrentPageFavorite"
          class="favorite icon-star-full2 ml-2 text-yellow cursor-pointer"
          @click.prevent="removeFavorite"
        />
      </span>
    </h2>
  </div>
</template>

<script>

import { USER_UPDATE } from '../../../store/mutations-types'

export default {
  tag: 'el-header-title',
  name: 'Header-title',
  props: {
    titleSuffix: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    favorites: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    addFavorite() {
      let favorites = {...this.user.favorites}
      this.loading = true
      if (!favorites) {
        favorites = {}
      }
      favorites[this.$route.path] = {
        title: this.title,
        icon: this.$route.meta.icon ? this.$route.meta.icon : null,
        world_id: this.world.id,
        suffix: this.titleSuffix,
      }
      this.$store.dispatch('auth/' + USER_UPDATE, {
        user_id: this.user.id,
        favorites: favorites
      }).then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
    },
    removeFavorite() {
      let favorites = {...this.user.favorites}
      this.loading = true
      if (favorites && favorites[this.$route.path]) {
        delete favorites[this.$route.path]
      }
      this.$store.dispatch('auth/' + USER_UPDATE, {
        user_id: this.user.id,
        favorites: favorites
      }).then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/getProfile'];
    },
    world () {
      return this.$store.getters['auth/getWorld'];
    },
    isCurrentPageFavorite () {
      return (this.user && this.user.favorites && this.user.favorites[this.$route.path]) ? true : false;
    }
  }
}
</script>

<style scoped lang="scss">
.back {
  cursor: pointer;
}
.favorite {
  position: relative;
  top: -2px;
}
.icon-star-full2 {
  transform: scale(1.2);
  &.text-grey {
    opacity: .5;
  }
}
</style>
