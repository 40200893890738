<template>
  <div id="app" :class="classes">
    <component :is="layout" :sidebar="sidebar" v-if="app_loaded">
      <router-view />
    </component>

    <vue-snotify></vue-snotify>

  </div>
</template>

<script>
const $ = require('jquery')
window.$ = $

export default {
  name: 'App',
  data () {
    return {
      app_loaded: false
    }
  },
  computed: {
    layout () {
      let default_layout = "anonymous"
      if (this.isAuth()) {
        default_layout = "auth";
      }
      return (this.$route.meta.layout || default_layout) + '-layout'
    },
    sidebar () {
      return this.$route.meta.sidebar ? this.$route.meta.sidebar : false
    },
    classes () {
      let classes = [
        'env-' + process.env.NODE_ENV,
      ]
      return classes.join(' ')
    }
  },
  mounted () {
    this.app_loaded = true

    let that = this;

    document.addEventListener('keyup', function (evt) {
        if (evt.keyCode === 27) {
            that.closeAllModalsButMandatory()
        }
    })
  },
  watch: {
    ['$route']: {
      deep: true,
      handler: function (newRoute, oldRoute) {
        if (newRoute.meta.layout != 'authfake') {
          this.closeAllModals()
        }
      },
    },
  },
}
</script>

<style>
</style>
