<template>

  <div>

    <div class="w-100 text-center py-1 mb-2 ">

      <img v-if="hasExistingImage" :src="entity.medias[data.id].url.full" class="p-2 img-preview img-preview-existing" />

    </div>

  </div>

</template>

<script>

export default {
  name: 'process-template-addon-image',
  props: ['data', 'entity', 'classColorName'],
  data () {
    return {
      hasImageUploaded: false,
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    hasExistingImage () {
      return this.data && this.data.id && this.data.id != null
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ {
  .img-preview {
    max-height: none;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}
</style>
