<template>

  <span :class="getColorClass(status)">
    <i :class="getIconClass(status)"></i>
    <span class="font-weight-bold d-inline-block status-text">{{ $t('modules.hosting.fields.status.options.' + status) }}</span>
  </span>

</template>

<script>

export default {
  tag: 'hosting-status',
  name: 'Hosting-status',
  props: ['status'],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    getIconClass(status) {
      if (status === 'DRAFT') {
        return 'icon-cog'
      }
      else if (status === 'ONLINE') {
        return 'icon-checkmark-circle'
      }
      else if (status === 'test') {
        return 'icon-puzzle'
      }
      else if (status === 'ARCHIVED') {
        return 'icon-box'
      }
      return 'icon-help'
    },
    getColorClass(status) {
      if (status === 'DRAFT') {
        return 'text-info'
      }
      else if (status === 'ONLINE') {
        return 'text-success'
      }
      return 'text-grey'
    }
  }
}
</script>

<style scoped>
.status-text {
  margin-left: .7em;
}
</style>
