<template>
  <li class="nav-item">
      <slot></slot>
  </li>
</template>

<script>
/**
 * @group Core
 * Display Tab
 * https://getbootstrap.com/docs/4.0/components/navs/#tabs
 */
export default {
  name: 'Tab',
  tag: 'el-tab',
  props: {
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
a, .nav-link {
    padding-left: 14px;
    padding-right: 14px;
    font-weight: normal;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9em;
    border: 0;
    border-radius: 4px;
    &:before {
        display: none;
        bottom: 0;
    }
}
</style>
