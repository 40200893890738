<template>

  <div>

    <div class="w-100 py-1 mb-2 ">
      <label class="font-weight-bold mb-1" :for="inputId">{{ data }}</label>

      <div class="py-1">
        <span v-for="star in 10" class="pr-1 cursor-pointer" @click.prevent="changeVal(star)">
          <i :class="(value >= star) ? 'icon-star-full2 text-yellow' : 'text-slate icon-star-full2'"></i>
        </span>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-addon-rating',
  props: ['value', 'data', 'id', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    }
  },
  computed: {
    inputId () {
      return 'input_' + this.id
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-star-full2 {
  font-size: 1.6em;
}
.text-slate.icon-star-full2 {
  opacity: .3;
}
</style>
