/* eslint-disable promise/param-names */

import {
WIKI_GET_CONTENTS,
WIKI_GET_CONTENT,
WIKI_CREATE_CONTENT,
WIKI_SET_CONTENT,
WIKI_GET_CATEGORIES,
WIKI_CREATE_TOKEN,
WIKI_SEND_CONTENT,
WIKI_CLONE_CONTENT,
WIKI_GET_HISTORY_CONTENT,
WIKI_GET_TOKENS,
WIKI_SET_TOKEN,
WIKI_DELETE_CONTENT,
WIKI_DELETE_TOKEN,
WIKI_ADD_MEDIA_CONTENT,
WIKI_USE_CONTENT_TEMPLATE,
EVENT_WIKI_EDIT_TOKEN,
WIKI_GET_ONBOARDINGS,
WIKI_GET_ONBOARDING,
WIKI_SET_ONBOARDING,
WIKI_CREATE_ONBOARDING,
WIKI_EDIT_ONBOARDING,
WIKI_DELETE_ONBOARDING,
WIKI_COMPLETE_ONBOARDING,
WIKI_GET_COMPLETED_ONBOARDINGS,
WIKI_ONBOARDING_GET_SHEETS,
WIKI_USE_ONBOARDING_TEMPLATE,
} from './mutations-types'
import Api from '../../src/api'

const state = {
  edit_token: null,
}

const getters = {
  getEditToken: state => state.edit_token ? state.edit_token : null,
}

const actions = {

   // CONTENT
  [WIKI_GET_CONTENTS]:({}, worldId) => {
    return Api.wiki.content.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_GET_CONTENT]:({}, data) => {
    return Api.wiki.content.get(data.worldId, data.contentId)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_GET_HISTORY_CONTENT]:({}, data) => {
    return Api.wiki.content.history(data.worldId, data.contentId, data.page)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_CLONE_CONTENT]:({}, data) => {
    return Api.wiki.content.clone(data.worldId, data.contentId)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_USE_CONTENT_TEMPLATE]:({}, data) => {
    return Api.wiki.content.useTemplate(data.worldId, data.templateId)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_ADD_MEDIA_CONTENT]:({}, data) => {
    return Api.wiki.content.addMedia(data.get('worldId'), data.get('contentId'), data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_CREATE_CONTENT]:({}, data) => {
    return Api.wiki.content.create(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_SEND_CONTENT]:({}, data) => {
    return Api.wiki.content.send(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_SET_CONTENT]:({}, data) => {
    return Api.wiki.content.set(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_DELETE_CONTENT]:({}, data) => {
    return Api.wiki.content.delete(data)
    .then(resp => {
      return resp.data.success
    })
  },

   // CATEGORIES
  [WIKI_GET_CATEGORIES]:({}, worldId) => {
    return Api.wiki.categories.get(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

   // TOKENS
  [WIKI_CREATE_TOKEN]:({}, data) => {
    return Api.wiki.tokens.create(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_GET_TOKENS]:({}, worldId) => {
    return Api.wiki.tokens.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_SET_TOKEN]:({}, data) => {
    return Api.wiki.tokens.set(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_DELETE_TOKEN]:({}, data) => {
    return Api.wiki.tokens.delete(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [EVENT_WIKI_EDIT_TOKEN]:({commit}, token_key) => {
    commit(EVENT_WIKI_EDIT_TOKEN, token_key)
  },

  // ONBOARDING
  [WIKI_CREATE_ONBOARDING]:({}, data) => {
    return Api.wiki.onboarding.create(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_EDIT_ONBOARDING]:({}, data) => {
    return Api.wiki.onboarding.edit(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_GET_ONBOARDINGS]:({}, worldId) => {
    return Api.wiki.onboarding.index(worldId)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_GET_ONBOARDING]:({}, data) => {
    return Api.wiki.onboarding.get(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_SET_ONBOARDING]:({}, data) => {
    return Api.wiki.onboarding.set(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_DELETE_ONBOARDING]:({}, data) => {
    return Api.wiki.onboarding.delete(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_COMPLETE_ONBOARDING]:({}, data) => {
    return Api.wiki.onboarding.complete(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_GET_COMPLETED_ONBOARDINGS]:({}, worldId) => {
    return Api.wiki.onboarding.getCompleted(worldId)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_ONBOARDING_GET_SHEETS]:({}, worldId) => {
    return Api.wiki.onboarding.getSheets(worldId)
    .then(resp => {
      return resp.data.success
    })
  },
  [WIKI_USE_ONBOARDING_TEMPLATE]:({}, data) => {
    return Api.wiki.onboarding.useTemplate(data.worldId, data.templateId)
    .then(resp => {
      return resp.data.success
    })
  },

}

const mutations = {
  [EVENT_WIKI_EDIT_TOKEN]: (state, token_key) => {
    state.edit_token = token_key
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
