import instance from '../../src/api/_instance'

export default {

  course: {

    index (worldId) {
      return instance
        .get(`worlds/${worldId}/academy`)
    },
    get (data) {
      return instance
        .get(`worlds/${data.world_id}/academy/${data.id}`)
    },
    create (data) {
      return instance
        .post(`worlds/${data.world_id}/academy`, data)
    },
    edit (data) {
      return instance
        .post(`worlds/${data.world_id}/academy/${data.id}`, data)
    },
    delete (data) {
      return instance
        .delete(`worlds/${data.world_id}/academy/${data.id}`)
    },
    complete (data) {
      return instance
        .post(`worlds/${data.world_id}/academy/${data.id}/complete`, data)
    },
    getCompleted (worldId) {
      return instance
        .get(`worlds/${worldId}/academy/completed`)
    },
    getSheets (worldId) {
      return instance
        .get(`worlds/${worldId}/academy/sheets`)
    },

  },

}
