/*
 *  Make your Vuex mutation names friendly
 *  https://medium.com/@rijk/make-your-vuex-mutation-names-friendly-7e4b53597cd0
 */
let icon = ''
let group = ''
function getMutationLabel(icon, group, name) {
  return icon + ' ' + group + ' : ' + name
}

// Global
icon = '🔍'
group = 'Global'
export const GLOBAL_DATA = getMutationLabel(icon, group, 'Data')
export const GLOBAL_SUBSCRIPTIONS = getMutationLabel(icon, group, 'Subscirptions')
export const GLOBAL_BANNERS = getMutationLabel(icon, group, 'Banners')
export const GLOBAL_ACCESS = getMutationLabel(icon, group, 'Ask Access')
export const GLOBAL_TRANSLATIONS = getMutationLabel(icon, group, 'Translations')

// Auth
icon = '🔓'
group = 'Auth'
export const AUTH_REGISTER = getMutationLabel(icon, group, 'Register')
export const AUTH_REQUEST = getMutationLabel(icon, group, 'Request - Login')
export const AUTH_SUCCESS = getMutationLabel(icon, group, 'Success')
export const AUTH_ERROR = getMutationLabel(icon, group, 'Error')
export const AUTH_LOGOUT = getMutationLabel(icon, group, 'Logout')
export const AUTH_VALIDATE_PHONE = getMutationLabel(icon, group, 'Validate Phone')
export const AUTH_UNVALIDATE_PHONE = getMutationLabel(icon, group, 'Unvalidate Phone')
export const AUTH_PASSWORD_RESET = getMutationLabel(icon, group, 'Password Reset')
export const AUTH_PASSWORD_RENEW = getMutationLabel(icon, group, 'Password Renew')
export const AUTH_REFRESH = getMutationLabel(icon, group, 'Refresh')

// User
icon = '🧔'
group = 'User'
export const USER_REQUEST = getMutationLabel(icon, group, 'Request')
export const USER_UPDATE = getMutationLabel(icon, group, 'Update')
export const USER_SUCCESS = getMutationLabel(icon, group, 'Success')
export const USER_ERROR = getMutationLabel(icon, group, 'Error')
export const USER_LOGOUT = getMutationLabel(icon, group, 'Logout')
export const USER_REFRESH = getMutationLabel(icon, group, 'Refresh')
export const USER_SET_WORLD = getMutationLabel(icon, group, 'Set World')
export const USER_NOTIFICATIONS_GET_ALL = getMutationLabel(icon, group, 'Notif Get All')
export const USER_NOTIFICATIONS_GET_UNREAD = getMutationLabel(icon, group, 'Notif Get Unread')
export const USER_NOTIFICATIONS_POST_READ = getMutationLabel(icon, group, 'Notif Post Read')

// User
icon = '🌏'
group = 'World'
export const WORLD_MODULES = getMutationLabel(icon, group, 'Modules')
export const WORLD_MODULE_ENABLE = getMutationLabel(icon, group, 'Module Enable')
export const WORLD_MODULES_RESET = getMutationLabel(icon, group, 'Modules Reset')
export const WORLD_REQUEST = getMutationLabel(icon, group, 'Request')
export const WORLD_UPDATE = getMutationLabel(icon, group, 'Update')
export const WORLD_BILLS = getMutationLabel(icon, group, 'Bills')
export const WORLD_USERS_REQUEST = getMutationLabel(icon, group, 'Users Request')
export const WORLD_USERS_RESET = getMutationLabel(icon, group, 'Users Reset')
export const WORLD_USERS_STORE = getMutationLabel(icon, group, 'Users Store')
export const WORLD_TRIBES_RESET = getMutationLabel(icon, group, 'Tribes Reset')
export const WORLD_TRIBES_STOREX = getMutationLabel(icon, group, 'Tribes Storex')
export const WORLD_USER_REQUEST = getMutationLabel(icon, group, 'User Request')
export const WORLD_INVITATIONS_REQUEST = getMutationLabel(icon, group, 'Invitations Request')
export const WORLD_INVITATION_SEND = getMutationLabel(icon, group, 'Invitation Send')
export const WORLD_INVITATION_REMOVE = getMutationLabel(icon, group, 'Invitation Remove')
export const WORLD_INVITATION_RESPONSE = getMutationLabel(icon, group, 'Invitation Response')
export const WORLD_OWNER_STATUS_CHANGE = getMutationLabel(icon, group, 'Owner Status Change')
export const WORLD_INTERNAL_STATUS_CHANGE = getMutationLabel(icon, group, 'Internal Status Change')
export const WORLD_USER_INFOS_CHANGE = getMutationLabel(icon, group, 'User Infos Change')
export const WORLD_COUPON_CHECK = getMutationLabel(icon, group, 'World Coupon Check')
export const WORLD_SUBSCRIPTION = getMutationLabel(icon, group, 'World Subscription')
export const WORLD_SUBSCRIPTION_CANCEL = getMutationLabel(icon, group, 'World Subscription Cancel')
export const WORLD_USER_ADD = getMutationLabel(icon, group, 'User Add')
export const WORLD_USER_REMOVE = getMutationLabel(icon, group, 'User Remove')
export const WORLD_PERMISSIONS_REQUEST = getMutationLabel(icon, group, 'Permissions Request')
export const WORLD_PERMISSIONS_UPDATE = getMutationLabel(icon, group, 'Permissions Update')
export const WORLD_TRIAL_EXTEND = getMutationLabel(icon, group, 'Trial Extend')

export const WORLD_TRIBES_REQUEST = getMutationLabel(icon, group, 'Tribes Request')
export const WORLD_TRIBES_DETAILS = getMutationLabel(icon, group, 'Tribes Details')
export const WORLD_TRIBES_STORE = getMutationLabel(icon, group, 'Tribe Store')
export const WORLD_TRIBES_UPDATE = getMutationLabel(icon, group, 'Tribe Update')
export const WORLD_TRIBES_REMOVE = getMutationLabel(icon, group, 'Tribe Remove')
export const WORLD_TRIBES_OWNER_STATUS_CHANGE = getMutationLabel(icon, group, 'Tribes Owner Status Change')
export const WORLD_TRIBES_USER_REMOVE = getMutationLabel(icon, group, 'Tribes User Remove')
export const WORLD_TRIBES_USER_ADD = getMutationLabel(icon, group, 'Tribes User Add')

// World Invitation
icon = '🌏 📨'
group = 'World Invitation'
export const WORLD_INVITATION_REQUEST = getMutationLabel(icon, group, 'Request')

// Module
icon = '📄'
group = 'Module'
export const MODULE_REQUEST = getMutationLabel(icon, group, 'Module Request')
export const MODULE_REQUEST_ARCHIVES = getMutationLabel(icon, group, 'Module Request Archives')
export const MODULE_STORE = getMutationLabel(icon, group, 'Module Store')
export const MODULE_UPDATE = getMutationLabel(icon, group, 'Module Update')
export const MODULE_REMOVE = getMutationLabel(icon, group, 'Module Remove')
export const MODULE_AUTOCOMPLETE = getMutationLabel(icon, group, 'Module Autocomplete')
export const MODULE_SET_DATA_FORM = getMutationLabel(icon, group, 'Module Set Data Form')
export const MODULE_RESET_DATA_FORM = getMutationLabel(icon, group, 'Module Reset Data Form')
export const MODULE_REFRESH_ACCESSKEY = getMutationLabel(icon, group, 'Module Refresh Accesskey')
export const MODULE_REFRESH_PUBLICKEY = getMutationLabel(icon, group, 'Module Refresh Publickey')
export const MODULE_CANCEL_PUBLICKEY = getMutationLabel(icon, group, 'Module Cancel Publickey')
export const MODULE_GET_BY_PUBLICKEY = getMutationLabel(icon, group, 'Module Get by Publickey')
export const MODULE_SET_TEMPLATE = getMutationLabel(icon, group, 'Module Set Template')
export const MODULE_SET_STATUS = getMutationLabel(icon, group, 'Module Set Status')

// Module Comment
icon = '💬'
group = 'Module Comment'
export const MODULE_COMMENT_INDEX = getMutationLabel(icon, group, 'Module Comment Index')
export const MODULE_COMMENT_STORE = getMutationLabel(icon, group, 'Module Comment Store')
export const MODULE_COMMENT_UPDATE = getMutationLabel(icon, group, 'Module Comment Update')
export const MODULE_COMMENT_DESTROY = getMutationLabel(icon, group, 'Module Comment Destroy')
