<template>
  <div class="">
    <div class="chart" v-echarts="chartOptions"></div>
  </div>
</template>

<script>

import _ from 'lodash'
import echarts from '../../../directives/echarts'

export default {
  tag: 'el-chart-radar',
  name: 'Chart-radar',
  props: ['data'],
  directives: {
    'echarts': echarts
  },
  data () {
    return {
      chartDefault: {

        // Global text styles
        textStyle: {
          fontFamily: 'Roboto, Arial, Verdana, sans-serif',
          fontSize: 13
        },

        // Add tooltip
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif'
          }
        },

        // Add legend
        legend: {
          orient: 'vertical',
          top: 0,
          left: 0,
          data: [],
          itemHeight: 8,
          itemWidth: 16,
        },

        // Setup polar coordinates
        radar: [{
          radius: '80%',
          center: ['50%', '50%'],
          name: {
            color: '#777'
          },
          indicator: [
          ]
        }],

        // Add series
        series: [{
          type: 'radar',
          symbolSize: 5,
          color: process.env.VUE_APP_TITLE == 'Magic Office' ? '#00BCD4' : '#455A64',
          itemStyle: {
            normal: {
              borderWidth: 2,
              areaStyle: {
                  type: 'default',
              },
            },
          },
          label: {
            position: 'outer',
            alignTo: 'labelLine',
            bleedMargin: 5
          },
          data: [
          ]
        }]

      }
    }
  },
  computed: {
    chartOptions: function () {
      let data = _.cloneDeep(this.chartDefault)
      this.data.axes.forEach(function (item, index, arr) {
        data.radar[0].indicator.push({
          nameTextStyle: {
            fontWeight: "500",
          },
          color: process.env.VUE_APP_TITLE == 'Magic Office' ? '#08a1b4' : '#455A64',
          text: item.title.replace(' ', '\n'),
          max: item.max || 100,
        })
      })
      this.data.values.forEach(function (item, index, arr) {
        data.series[0].data.push({
          value: item.value.split(','),
          name: '' + item.title + '',
        })
        data.legend.data.push(item.title)
      })
      return data
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
.chart {
  min-width: 300px;
  width: 100%;
  height: 28vw;
  max-height: 280px;
}
</style>
