<template>

  <div>

    <img
      v-tooltip.right-end="$t('support.title')"
      v-if="!supportVisible"
      src="@/assets/images/support.png"
      class="support-image cursor-pointer"
      @click="openSupport()"
    />

    <div class="bg-white border-left position-fixed support-wrapper">

      <div :class="{ 'd-block': supportVisible }" class="sidebar sidebar-support sidebar-light border-0 sidebar-right sidebar-expand">

        <!-- Sidebar content -->
        <div class="sidebar-content">

          <div class="card card-body bg-info text-center">
            <div class="">
              <h5 class="m-0">
                <i class="icon-cross2 float-right cursor-pointer" @click="supportVisible = false"></i>
                {{ $t('support.title') }}
              </h5>
            </div>

            <a v-if="isCurrentWorldOwner" target="_new" :href="ticketUrl" class="mt-2 btn bg-white text-dark"><i class="icon-bubble2 mr-1"></i> {{ $t('support.contact_button') }}</a>
          </div>

          <ais-instant-search
            v-if="resultOpened === false"
            :search-client="searchClient"
            index-name="algolia_support"
          >

            <div class="px-2 pt-2 m-1">
              <div class="form-group-feedback form-group-feedback-right">
                <ais-search-box autofocus>
                  <input
                    id="supportSearch"
                    ref="supportSearch"
                    type="search"
                    class="form-control"
                    slot-scope="{ currentRefinement, refine }"
                    :value="currentRefinement"
                    :placeholder="$t('filters.search')"
                    @input="refine($event.currentTarget.value)"
                    @click="refine(' ');refine('')"
                  />
                </ais-search-box>
                <div class="form-control-feedback">
                  <i class="icon-search4 font-size-base text-muted"></i>
                </div>
              </div>
            </div>

            <!-- Results -->
            <div class="card mt-3">
                <ais-hits class="nav nav-sidebar" :transform-items="sortItems">
                  <template slot="item" slot-scope="{ item }" class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link py-2 border-top" @click="resultOpened = item">
                      <ais-highlight :hit="item" attribute="title" />
                    </a>
                  </template>
                </ais-hits>
            </div>
          </ais-instant-search>

          <div
            class="p-3"
            v-if="resultOpened !== false"
          >
            <div class="btn btn-light mb-2" @click="resultOpened = false">
              <i class="icon-arrow-left7"></i>
              {{ $t('common.back') }}
            </div>

            <h4>
              {{ resultOpened.title }}
            </h4>
            <div v-html="resultOpened['body:value']"></div>
          </div>

          <!-- /Results -->

        </div>
        <!-- /sidebar content -->

      </div>

    </div>

  </div>
</template>

<script>

import algoliasearch from 'algoliasearch/lite';
import _ from 'lodash';
const $ = require('jquery')

export default {
  name: 'Support',
  tag: 'el-support',
  data () {
    return {
      resultOpened: false,
      supportVisible: false,
      searchClient: algoliasearch('Y94ARBSQY6', '0bb76346a073f4d9d6bc677c5f85deaa'),
      ticketForm: {
        subject: '',
        content: ''
      }
    }
  },
  methods: {
    sortItems (items) {

      items = _.filter(items, (item) => {
        return item.field_visibility == 'Support'
      })

      return _.sortBy(items, [(o) => {
        if (this.$router.currentRoute.matched[0]) {
          return o.field_support !== this.$router.currentRoute.matched[0].path;
        }
      }]);

    },
    openSupport () {
      this.supportVisible = true
      $('#supportSearch').focus()
    }
  },
  watch: {
    '$route.path': function (route) {
      this.$nextTick(() => {
        $('#supportSearch').click()
        $('#supportSearch').focus()
      })
    }
  },
  computed: {
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    userProfile () {
      return this.$store.getters['auth/getProfile']
    },
    userWorld () {
      return this.$store.getters['auth/getWorld']
    },
    ticketUrl () {
      let page = this.$router.currentRoute.path
      page = page.replace(/\//g, '-')

      let url = 'https://share.hsforms.com/1QfKQ8qiPRSeN0tkXLzk53A2dcda'
      url += `?company=${this.userWorld.name}`
      url += `&firstname=${this.userProfile.first_name}`
      url += `&lastname=${this.userProfile.last_name}`
      url += `&email=${this.userProfile.email}`
      url += `&phone=${this.userProfile.phone_number}`
      url += `&page=${page}`

      return url

    }
  }
}
</script>

<style scoped lang="scss">
.sidebar-support {
  width: 30rem;
  max-width: 100vw;
}

/deep/ mark {
  padding: 0;
  font-weight:bold;
}
/deep/ .ais-Hits-list {
  padding-left: 0;
  list-style-type: none;
}
/deep/ .ais-Hits-item {
  .nav-link {
    padding-right: 2.75rem;
    &:after {
      content: '\E9C7';
      font-family: "icomoon";
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      line-height: 1;
      position: absolute;
      top: 0.75rem;
      margin-top: 0.12502rem;
      right: 1.25rem;
      -webkit-transition: -webkit-transform 0.25s ease-in-out;
      transition: -webkit-transform 0.25s ease-in-out;
      transition: transform 0.25s ease-in-out;
      transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.support-image {
  position: fixed;
  width: 50px;
  bottom: 10px;
  right: -15px;
  transition: all .2s ease;
  &:hover {
    right: 0px;
  }
}

.support-wrapper {
  z-index: 2000;
  right: 0;
  bottom: 0;
  top: 50px;
  overflow: auto;
}
</style>
