<template>

  <div class="d-flex">

    <div class="breadcrumb">
      <router-link tag="a" to="/" class="breadcrumb-item">
        <i :class="'mr-2 icon-home'"></i>
        {{ $t('common.home') }}
      </router-link>
      <router-link v-if="breadcrumb" v-for="(item, key) in breadcrumb" :key="key" tag="a" :to="item.target" class="breadcrumb-item">
        <i v-if="item.icon" :class="'mr-2 icon-' + item.icon"></i>
        {{ item.title }}
      </router-link>
      <router-link v-else-if="$route.meta.breadcrumb" v-for="(item, key) in $route.meta.breadcrumb" :key="key" tag="a" :to="pageUrl(item.target)" class="breadcrumb-item">
        <i v-if="item.icon" :class="'mr-2 icon-' + item.icon"></i>
        {{ pageTitle(item.title) }}
      </router-link>
      <a href="#" class="breadcrumb-item active">
        {{ title }}
        <span v-if="titleSuffix">{{ titleSuffix }}</span>
      </a>
    </div>

  </div>

</template>

<script>

export default {
  tag: 'el-header-breadcrumb',
  name: 'Header-breadcrumb',
  props: ['title', 'titleSuffix', 'breadcrumb'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    pageTitle (routeName) {
      return this.$t('titles.' + _.snakeCase(routeName))
    },
    pageUrl (routeUrl) {
      let routeEx = routeUrl.split('/')
      let routeIm = []

      routeEx.map((elem) => {
        if (elem.charAt(0) === ':') {
          routeIm.push(this.$route.params[elem.replace(':', '')])
        } else {
          routeIm.push(elem)
        }
      })

      return routeIm.join('/')
    }
  }
}
</script>

<style scoped>
</style>
