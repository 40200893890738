import { render, staticRenderFns } from "./_Checkbox.vue?vue&type=template&id=1b7872a2&scoped=true&"
import script from "./_Checkbox.vue?vue&type=script&lang=js&"
export * from "./_Checkbox.vue?vue&type=script&lang=js&"
import style0 from "./_Checkbox.vue?vue&type=style&index=0&id=1b7872a2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b7872a2",
  null
  
)

export default component.exports