import instance from './_instance'

export default {

  get (token) {
    return instance
      .get(`world_invitations/${token}`)
  },

  remove (worldId, invitationId) {
    return instance
      .delete(`worlds/${worldId}/invitations/${invitationId}`)
  },

}
