<template>
  <div>
    <el-navbar />

    <!-- Page content -->
    <div class="page-content">

      <el-sidebar-left :modulesLoaded="modulesLoaded" />

      <div class="content-wrapper position-relative">
        <div class="content pt-sm-5">
          <div class="row align-items-center justify-content-center flex-column flex-md-row">
          </div>
        </div>
      </div>

    </div>

    <el-modal
      :noTitle="true"
      size="lg"
      id="auth-fake-modal"
    >
      <slot></slot>
    </el-modal>

  </div>
</template>

<script>
import { AUTH_REFRESH } from '../store/mutations-types'
export default {
  data () {
    return {
      modulesLoaded: true,
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$store.dispatch('auth/' + AUTH_REFRESH)
    } else {
      this.openModal('auth-fake-modal', true)
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
}
</script>
