<template>

  <a href="#"
    class="btn btn-sm text-white mr-2 ml-md-2 mr-md-0"
    :class="classes"
    :rounded="rounded"
  >
    <i
      v-if="icon"
      :class="{
        'mr-1': label && label != '',
        ['icon-' + icon]: true,
      }"
    ></i>
    {{ label }}
  </a>

</template>

<script>

export default {
  tag: 'el-header-action',
  name: 'Header-action',
  props: {
    icon: {
      type: String,

    },
    label: {
      type: String,

    },
    classes: {
      type: String,
      default: 'btn-success',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.btn {
  padding-top: .3em;
  padding-bottom: .3em;
  &[rounded=true] {
    padding-left: 0;
    padding-right: 0;
    width: 31px;
    text-align: center;
  }
}
</style>
