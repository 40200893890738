<template>

  <div class="filemanager">
  </div>

</template>

<script>

import filemanager from './filemanager/filemanager'

export default {
  tag: 'webix-filemanager',
  name: 'WebixFilemanager',
  props: ['files'],
  data () {
    return {
      fileManager: null
    }
  },
  computed: {
  },
  methods: {
  },
  mounted () {
    // initializes Webix Slider
    this.fileManager = webix.ui({
        // container and scope are mandatory, other properties are optional
        container: this.$el,
        $scope: this,
        view: "filemanager"
    })

    webix.$$(this.fileManager).parse(this.files)
  },
  // memory cleaning
  destroyed () {
    webix.$$(this.fileManager).destructor();
  },
  watch:{
    // updates component when the bound value changes
    files:{
        handler:function(value){
            // webix.$$(this.fileManager).setValue(files);
        }
    }
  },
}
</script>

<style scoped>
@import './filemanager/filemanager.css';
.filemanager {
  min-height: 500px;
}
</style>
