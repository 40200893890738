<template>

  <div>

    <div class="w-100 py-1 mb-2 ">
      <el-select :options="options" v-model="value"></el-select>
    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-element-selectlist',
  props: ['value', 'data', 'id', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal() {
      this.$emit('input', this.value)
    }
  },
  computed: {
    options () {
      let splitted = this.data.match(/[^\r\n]+/g)
      return splitted.map( option => {
        return {
          value: option,
          title: option,
        }
      })
    }
  },
  watch: {
    value () {
      this.changeVal()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
