<template>

  <div>

    <div class="w-100 py-1 mb-2 ">
      <label class="font-weight-bold mb-1" :for="inputId">{{ data }}</label>
      <textarea :id="inputId" :value="value" type="text" class="form-control w-100" @change="changeVal" :placeholder="data" />
    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-addon-textarea',
  props: ['value', 'data', 'id', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    }
  },
  computed: {
    inputId () {
      return 'input_' + this.id
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
