import {
ACTION_REQUEST_TAGS,
ACTION_UPDATE_TAGS,
ACTION_DESTROY_TAGS,
ACTION_STORE_TAGS,
WORLD_TRIBES_REQUEST,
ACTION_REQUEST_ACTIONS_CREATOR,
ACTION_REQUEST_USERS,
ACTION_REQUEST_ACTIONS_STORE,
ACTION_REQUEST_ACTIONS_UPDATE,
ACTION_REQUEST_ACTIONS_DELETE,
ACTION_REQUEST_ACTIONS_UPDATE_DATE,
ACTION_STORE_COMMENT,
ACTION_ACTIVE_OWNER,
ACTION_REQUEST_ACTIONS_CREATOR_RECCURENCE,
ACTION_REQUEST_ACTIONS_CREATOR_HISTORIQ,
ACTION_REQUEST_ACTIONS_CREATOR_ASSIGN,
ACTION_REQUEST_ACTIONS_PARTAGES,
ADD_TAG_TO_ACTION,
ACTION_VIEW_MODE_SET,
ACTIONBOARD_REQUEST_FAST,
ACTIONBOARD_UPDATE_FAST,
ACTIONBOARD_DESTROY_FAST,
ACTIONBOARD_STORE_FAST,
} from './mutations-types'
import Api from '../../src/api'

const state = {
  listUsers: [],
  todos: [],
  typeTag: null,
  tagsTribes: [],
  tagsTag: [],
  tagsList: [],
  tagsProject: [],
  tagsModel: [],
  actionViewMode: 'list',
  filters: {
    sortTaskIsActivited: false,
    activeSort: 'date',
    activeGanttRender: 'Day',
    activeSortDirection: 'ASC',
    filtersByActions: [ { type: ACTION_REQUEST_ACTIONS_CREATOR, value: true } ],
    filterByDate: { value: true, type: "*" },
    filterGlobal: []
  }
}

const actions = {
  [ACTION_REQUEST_TAGS]: ({commit, dispatch}, data) => {
    return Api.actionsboard.getTags(data)
    .then(resp => {
      commit('setTags', resp.data.success.data)
      return resp.data.success
    })
  },
  [ACTION_UPDATE_TAGS]: ({}, data) => {
    return Api.actionsboard.updateTags(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_DESTROY_TAGS]: ({}, data) => {
    return Api.actionsboard.destroyTags(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_STORE_TAGS]: ({}, data) => {
    return Api.actionsboard.storeTags(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [WORLD_TRIBES_REQUEST]: ({commit, dispatch}, data) => {
    return Api.actionsboard.getTribes(data)
    .then(resp => {
      commit('setTagsTribus', resp.data.success)
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_CREATOR]: ({commit, dispatch, rootState}, data) => {
    return Api.actionsboard.getActionCreator(data)
    .then(resp => {
      if (resp.data.success.next_page && rootState.global.currentPageCategory === "actionsboard") { // continue to load actions if next pages & still on actionboard module
        data.page = resp.data.success.next_page
        dispatch('actionsboard/' + ACTION_REQUEST_ACTIONS_CREATOR, data, { root: true })
      }

      if (resp.data.success.current_page == 1) {
        state.todos = resp.data.success.data
        commit('setTodos', resp.data.success.data)
      } else {
        state.todos = state.todos.concat(resp.data.success.data)
        commit('addTodos', resp.data.success.data)
      }
      return resp.data.success
    })
  },
  [ACTION_REQUEST_USERS]: ({commit, dispatch}, data) =>  {
    return Api.actionsboard.getWorldUsers(data)
    .then(resp => {
      commit('setUsers', resp.data.success.data)
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_STORE]: ({}, data) => {
    return Api.actionsboard.storeAction(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_UPDATE]: ({}, data) => {
    return Api.actionsboard.editAction(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_UPDATE_DATE]: ({}, data) => {
    return Api.actionsboard.editActionDate(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_DELETE]: ({}, data) => {
    return Api.actionsboard.deletetAction(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_STORE_COMMENT]: ({}, data) => {
    return Api.actionsboard.commentAction(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_ACTIVE_OWNER]: ({}, data) => {
    return Api.actionsboard.activeAction(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_CREATOR_RECCURENCE]: ({}, data) => {
    return Api.actionsboard.getActionCreatorRecurrent(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_CREATOR_HISTORIQ]: ({}, data) => {
    return Api.actionsboard.getActionCreatorHistiriq(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_CREATOR_ASSIGN]: ({}, data) => {
    return Api.actionsboard.getActionCreatorAssign(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_REQUEST_ACTIONS_PARTAGES]: ({}, data) => {
    return Api.actionsboard.getActionCreatorAssign(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ADD_TAG_TO_ACTION]: ({}, data) => {
    return Api.actionsboard.updateTagAction(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTION_VIEW_MODE_SET]: ({commit}, mode) => {
    commit('setActionViewMode', mode)
  },

  [ACTIONBOARD_REQUEST_FAST]: ({}, data) => {
    return Api.actionsboard.fast.get(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTIONBOARD_UPDATE_FAST]: ({}, data) => {
    return Api.actionsboard.fast.update(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTIONBOARD_DESTROY_FAST]: ({}, data) => {
    return Api.actionsboard.fast.destroy(data)
    .then(resp => {
      return resp.data.success
    })
  },
  [ACTIONBOARD_STORE_FAST]: ({}, data) => {
    return Api.actionsboard.fast.store(data)
    .then(resp => {
      return resp.data.success
    })
  },
}

const mutations = {
  setActionViewMode (state, mode)  {
    state.actionViewMode = mode
  },
  setUsers (state, val)  {
    state.listUsers = val
  },
  setTodos (state, val) {
    state.todos = val
  },
  addTodos (state, val) {
    state.todos = state.todos.concat(val)
  },
  pushTodos (state, val) {
    state.todos.unshift(...val)
    state.todos =  _.sortBy(state.todos, [item => item.startDate, item => parseInt(item.position)])
  },
  refreshTodo (state, val) {
    state.todos.map(todo => {
      if(val.id == todo.id && val.key != "recurrence" && val.key != "comment") {
        let tagExist = ['tag', 'model', 'list', 'project', 'model', 'tribus'].filter(item => item == val.key)
        if(tagExist.length > 0) {
          if(todo[val.key].length == 0) {
            todo[val.key] = []
          }
          if(typeof val.delete != 'undefined' && val.delete == true) {
            todo[val.key] = val.value
          } else {
            todo[val.key].push(val.value)
          }
        } else {
          todo[val.key] = val.value
        }
      } else if(val.id == todo.id && val.key == "recurrence"){
        todo[val.key] = [val.value, val.format]
        todo['actionRecurrence'] = true
      } else if(val.id == todo.id && val.key == "comment") {
        if(todo[val.key] == null) {
          todo[val.key] = []
        }
        todo[val.key].unshift(val.comment)
     }
    })
    if(val.key === 'position' || val.key === 'startDate') {
      state.todos =  _.sortBy(state.todos, [item => item.startDate, item => parseInt(item.position)])
    }
  },
  deleteTodo (state, val) {
    state.todos = state.todos.filter(todo => {
      if(val != todo.id) {
        return todo
      }
    })
  },
  changeTypeTag (state, val) {
    state.typeTag = val
  },
  setTagsTribus (state, val)  {
    state.tagsTribes = val
  },
  setTags (state, val) {
    state.tagsTag = []
    state.tagsList = []
    state.tagsProject = []
    state.tagsModel = []
    val.map(item => {
      if (item.type == 'Tag') {
        state.tagsTag.push(item)
      } else if (item.type == 'List') {
        state.tagsList.push(item)
      } else if (item.type == 'Project') {
        state.tagsProject.push(item)
      } else if (item.type == 'Model') {
        state.tagsModel.push(item)
      }
    })
  },
  setFilters(state, val) {
    state.filters[val.key] = val.value
  }
}

export default {
  namespaced: true,
  actions,
  state,
  mutations
}
