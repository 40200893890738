import instance from './_instance'

export default {

  register (email, password, company = null, phone_number = null) {
    let data = {
      email: email,
      password: password,
      password_confirmation: password
    }

    if (company) {
      data.company = company
    }

    if (phone_number) {
      data.phone_number = phone_number
    }

    return instance
      .post('user/register', data)
  },

  login (email, password) {
    return instance
      .post('user/login', {
        email: email,
        password: password
      })
  },

  password_reset (email) {
    return instance
      .post('user/password', {
        email: email
      })
  },

  password_renew (data) {
    return instance
      .post(`user/password/${data.token}`, {
        password: data.password,
        password_confirmation: data.password_confirmation
      })
  },

  validate_phone (code) {
    return instance
      .post('user/validate_phone', {
        code: code
      })
  },

  profile () {
    return instance
      .get('user')
  },

  update (user) {
    return instance
      .put(`users/${user.user_id}`, user)
  },

  notifications: {
    all (worldId, page) {
      return instance
        .get(`worlds/${worldId}/notifications?page=${page}`)
    },
    read (notifId) {
      return instance
        .post(`notifications/read/${notifId}`)
    },
    unread () {
      return instance
        .get('notifications/unread')
    },
  },

}
