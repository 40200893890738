import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']
let user = store.getters['auth/getProfile']

let links = [
  {
    to: { name: 'hosting.index', params: { world: world.id } },
    module: 'hosting',
    title: 'modules.hosting.module_title',
    weight: 10,
    description: 'modules.hosting.module_desc',
  },
]

export default links
