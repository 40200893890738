/* eslint-disable promise/param-names */

import {
QUEST_PROGRESS_REQUEST,
QUEST_NEXT_CARD_REQUEST,
QUEST_ANSWER_CARD,
} from './mutations-types'
import Api from '../../src/api'

const state = {
}

const getters = {
}

const actions = {
  [QUEST_PROGRESS_REQUEST]:({}, worldId) => {
    return Api.quest.questProgress(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

  [QUEST_NEXT_CARD_REQUEST]:({}, worldId) => {
    return Api.quest.questNextCard(worldId)
    .then(resp => {
      return resp.data.success
    })
  },

  [QUEST_ANSWER_CARD]:({}, data) => {
    return Api.quest.questAnswerCard(data.worldId, data.sheetId, data.status)
    .then(resp => {
      return resp.data.success
    })
  }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
