import instance from '../../src/api/_instance'

export default {
  /**
  * @vuese
  * get all tags-type ( ctagories tribes and tags)
  */
  getListTags  (world) {
    return instance
    .get(`worlds/${world}/marketplace/Tags/list-categories`)
  },
  /**
  * @vuese
  * get all products in world
  */
  list (world) {
    return instance
    .get(`worlds/${world}/marketplace/Product`)
  },
  /**
  * @vuese
  * store new product
  */
  storeProduct (data) {
    return instance
    .post(`worlds/${data.world}/marketplace/Product/store`, data.data)
  },
  /**
  * @vuese
  * update product
  */
  updateProduct (data) {
    return instance
    .post(`worlds/${data.world}/marketplace/Product/${data.data.id}/update`, data.data)
  },
  /**
  * @vuese
  * delete product
  */
  deleteProduct (data) {
    return instance
    .post(`worlds/${data.world}/marketplace/Product/delete`, {id: data.product})
  },
  /**
  * @vuese
  * add or remove form wishlist user
  */
  myWishlistUpdate (data) {
    return instance
    .post(`worlds/${data.world}/marketplace/Wishlist/wishlist`, data.data)
  },
  /**
  * @vuese
  * get all wishlist
  */
  myWishlist (data) {
    return instance
    .get(`worlds/${data.world}/marketplace/Wishlist/wishlist`)
  },  /**
    * @vuese
    * store new commentaire
    */
  storeComment (data) {
    return instance
    .post(`worlds/${data.world}/marketplace/Product/comment`, data.data)
  },  /**
    * @vuese
    * get list users in world
    */
  getWorldUsers (data) {
    return instance
    .post(`worlds/${data}/marketplace/users`)
  },
  /**
    * @vuese
    * stopre new command product
    */
  storeCommand (data) {
    return instance
    .post(`worlds/${data.world}/marketplace/Command/command/store`, data.data)
  },
  /**
    * @vuese
    * get all product commanded by user
    */
  getListCammand (world) {
    return instance
    .get(`worlds/${world}/marketplace/Command/command/list`)
  },
  /**
    * @vuese
    * set new tag
    */
  setTag (data) {
    return instance
    .post(`worlds/${data.world}/marketplace/Tags/set/tag`, data.data)

  }
}
