<template>
  <div>
    <div class="card">

      <div class="card-header py-2 border-bottom pr-2" v-if="title">
        <div v-if="hasSlot('header-right')" class="float-right"><slot name="header-right"></slot></div>
        <el-card-title :title="title" />
      </div>

      <div class="table-wrapper">
        <table
          class="table table-striped table-borderless table-hover"
        >
          <thead>
            <tr>
              <th v-if="hasSlot('thumb') || hasSlot('badge')"></th>
              <th
                :key="columnKey"
                class="cursor-pointer"
                v-for="(columnLabel, columnKey) in columns"
                v-on:click="sortTable(columnKey)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    {{ columnLabel }}
                  </div>
                  <div v-if="columnKey == sortColumn">
                    <i class="pt-1 text-primary" v-bind:class="[ascending ? 'icon-sort-amount-asc' : 'icon-sort-amount-desc']"></i>
                  </div>
                </div>
              </th>
              <th></th>
              <th v-if="hasSlot('actions')"></th>
            </tr>
          </thead>
          <tbody v-if="get_rows().length == 0">
            <tr>
              <td :colspan="nbCol" class="text-center">
                {{ $t('common.no_result') }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="get_rows().length > 0">
            <tr
              :key="key"
              v-for="(row, key) in get_rows()"
              :class="{ 'cursor-pointer' : row.link }"
              @click="row.link && !preventClick ? $router.push(row.link) : null"
            >
              <td v-if="hasSlot('thumb') || hasSlot('badge')">
                <div class="image-col position-relative">
                  <!-- Used to display an image -->
                  <slot
                    name="thumb"
                    :row="row"
                  >
                  </slot>
                  <div class="table-badge" v-if="hasSlot('badge')">
                    <!-- Used to display a notification badge next to the image -->
                    <slot
                      name="badge"
                      :row="row"
                    >
                    </slot>
                  </div>
                </div>
              </td>
              <td
                :key="columnKey"
                v-for="(columnLabel, columnKey) in columns"
                v-html="row[columnKey]"
              >
              </td>

              <td class="py-0">
                <div v-if="row.user_id && usersByKey[row.user_id]" class="small text-slate font-weight-semibold d-flex align-items-center">
                  <!-- Display table row author -->
                  <el-avatar :src="usersByKey[row.user_id].avatar.thumb" size="24" />
                  <div class="ml-1">
                    <div class="small">{{ $t('common.created_by') }}</div>
                    <div>{{ usersByKey[row.user_id].full_name || usersByKey[row.user_id].email }}</div>
                  </div>
                </div>
              </td>

              <td class="py-0 text-right" v-if="hasSlot('actions') || row.link" @mouseenter="preventClick = true" @mouseleave="preventClick = false">
                <!-- Display table row actions -->
                <slot
                  name="actions"
                  :row="row"
                />
                <el-table-action
                  classes="view-link"
                  v-if="row.link"
                  :to="row.link"
                  icon="icon-arrow-right8"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="total" class="p-2 px-3 border-top text-grey">
          {{ $tc('table.title_active', data.length, {nb: data.length}) }}
          <span v-if="total !== Object.keys(data).length">
            <small class="text-grey">{{ $t('table.title_filter', { nb: total }) }}</small>
          </span>
        </div>

      </div>
    </div>

    <div class="text-center" v-if="num_pages() > 1">
      <div class="d-inline-block" v-if="num_pages() > 1">
        <ul class="pagination pagination-flat align-self-center">
          <li
            class="page-item"
            v-bind:class="[1 == currentPage ? 'disabled' : '']"
            v-on:click="1 == currentPage ? null : change_page(currentPage - 1)"
          ><a href="#" class="page-link">←</a></li>
          <li
            class="page-item"
           :key="i"
           v-for="i in num_pages()"
           v-bind:class="[i == currentPage ? 'active' : '']"
           v-on:click="change_page(i)"
          ><a href="#" class="page-link">{{ i }}</a></li>
          <li
            class="page-item"
            v-bind:class="[num_pages() == currentPage ? 'disabled' : '']"
            v-on:click="num_pages() == currentPage ? null : change_page(currentPage + 1)"
          ><a href="#" class="page-link">→</a></li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>

import _ from 'lodash'

/**
 * @group Core
 * Display select plugin
 */
export default {
  name: 'Table',
  tag: 'el-table',
  props: {
    // The table title, optional
    title: {
      type: String,
      required: false
    },
    // The table headers
    columns: {
      type: Object,
      required: true
    },
    // The table data
    data: {
      type: [Array, Object],
      required: true
    },
    // Total number of rows
    total: {
      type: Number,
      required: true
    }
  },
  data () {
    return  {
      preventClick: false,
      currentPage: 1,
      elementsPerPage: 20,
      ascending: false,
      sortColumn: '',
    }
  },
  computed: {
    // @vuese
    // Get total columns of the table
    nbCol () {
      let nbCol = Object.keys(this.columns).length
      if (this.hasSlot('thumb') || this.hasSlot('badge')) {
        nbCol++
      }
      if (this.hasSlot('actions')) {
        nbCol++
      }
      return nbCol
    },
    // @vuese
    // Format data to array
    rows () {
      return _.toArray(this.data)
    },
    users () {
      return this.$store.getters['world/getUsers']
    },
    usersByKey () {
      return _.keyBy(this.users, 'id')
    },
  },
  methods: {
    // @vuese
    // Check if slot is filled (Not working atm)
    hasSlot (slot) {
      return !!this.$scopedSlots[slot]
    },
    // @vuese
    // Sort data
    // @arg Column key
    sortTable (col) {
      if (this.sortColumn === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortColumn = col;
      }

      var ascending = this.ascending;

      this.rows.sort(function(a, b) {
        if (a[col] > b[col] || a[col] === null) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col] || b[col] === null) {
          return ascending ? -1 : 1
        }
        return 0;
      })
    },
    // @vuese
    // Get amount of pages
    num_pages () {
      return Math.ceil(this.rows.length / this.elementsPerPage);
    },
    // @vuese
    // Get current page data rows
    get_rows () {
      var start = (this.currentPage-1) * this.elementsPerPage;
      var end = start + this.elementsPerPage;
      return this.rows.slice(start, end);
    },
    // @vuese
    // Change page
    change_page (page) {
      this.currentPage = page;
    }
  }
}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-x: auto;
  table {
    width: 100%;
    max-width: none;
  }
}
th.cursor-pointer {
  &:hover {
    background:#e5eaee;
  }
}
.table-badge {
  position: absolute;
  right: -12px;
  top: -10px;
  transform: scale(.7);
}
.image-col {
  width: 34px;
}
</style>
