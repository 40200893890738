<template>

  <i class="icon-spinner2 spinner" :class="classes"></i>

</template>

<script>

/**
 * @group Core
 * Display spinning loader
 */
export default {
  tag: 'loader',
  name: 'Loader',
  props: {
    // Additional classes
    classes: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
i {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
</style>
