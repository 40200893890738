<template>

  <div class="card">
    <div class="card-header header-elements-inline pb-2">
      <div>
        <router-link tag="h5" :to="{ name: 'hosting.page', params: { world: $route.params.world, hosting: hosting.id } }" class="cursor-pointer card-title d-inline-block font-weight-semibold">
          <img class="favicon circle" v-if="hosting.domain" :src="'https://www.google.com/s2/favicons?domain=' + hosting.domain">
          {{ hosting.name }}
        </router-link>
        <a :href="'http://' + hosting.domain" target="_new" v-if="hosting.domain" class="d-inline-block ml-3 text-grey border-bottom small"><i class="icon-new-tab small"></i> {{ hosting.domain }}</a>
      </div>
      <div class="header-elements">
        <router-link :to="{ name: 'hosting.page', params: { world: $route.params.world, hosting: hosting.id } }">
          <el-table-action
            icon="icon-arrow-right7"
          />
        </router-link>
      </div>
    </div>

    <div class="card-body">
      <div class="row my-1">
        <div class="pr-sm-3 my-2 my-sm-0 col-sm-4 border-sm-right" v-if="hosting.status">
          <hosting-status :status="hosting.status" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  tag: 'hosting-teaser',
  name: 'Hosting-teaser',
  props: ['hosting'],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
.favicon {
  width: 16px;
  height: 16px;
  position: relative;
  top: -2px;
}
</style>
