<template>
  <div>

    <li class="nav-item dropdown dropdown-user" v-if="isAuth() && isProfileLoaded() && $store.getters['auth/isWorldOwner'] && $store.getters['auth/getWorld'].modules.partnership == true">

      <a href="#" class="navbar-nav-link dropdown-toggle" data-toggle="dropdown">
        <span id="worldname" class="px-0">
          {{ $t("modules.partnership.partenariat.title") }}
        </span>
      </a>

      <div class="dropdown-menu dropdown-menu-right wmin-md-200">

        <router-link
          tag="a"
          v-for="(item, key) in partenariatMenu"
          class="dropdown-item"
          :key="key"
          :to="item.path"
        >
          <i :class="'icon-' + item.icon" v-if="item.icon"></i>
          <i v-if="item.notif" class="notif-icon icon-primitive-dot text-primary"></i>
          {{ item.title }}
        </router-link>

      </div>
    </li>

  </div>
</template>

<script>


import _ from 'lodash'
import { CURRENT_HOSTINGID_SET, CURRENT_SPACEID_SET } from '../mutations-types'

export default {
  tag: 'partnership-navbar',
  name: 'Partnership-navbar',
  props: ['data'],
  data () {
    return {
      value: null,
      appName: process.env.VUE_APP_TITLE
    }
  },
  computed: {
    /**
    * @vuese
    *  get world object
    */
    world () {
      return this.$store.getters['auth/getWorld']
    },
    /**
    * @vuese
    * hasPartenariat is check if this user have world active for Partnership
    */
    hasPartenariat () {
      return (this.world.modules.partnership && this.world.modules.partnership == "1") ? true : false
    },
    /**
    * @vuese
    * Partnership menu route
    */
    partenariatMenu () {
      if (!this.hasPartenariat) {
        return {}
      }
      return [
        {
          title: this.$t("modules.partnership.titles.partnership_index"),
          path: '/world/' + this.world.id + '/partnership/dashboard',
          icon: 'stats-bars',
        },
        {
          title: this.$t("modules.partnership.titles.partnership_directory_profile"),
          path: '/world/' + this.world.id + '/partnership/directory',
          icon: 'magazine',
        }
      ]
    },
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
