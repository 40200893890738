<template>

  <div :class="{ 'sidebar-xs': !isSidebarLeftOpened, 'sidebar-mobile-main': isSidebarLeftOpened }">

    <div class="sidebar sidebar-light border-0 shadow sidebar-main sidebar-expand-md">

      <!-- Sidebar mobile toggler -->
      <div class="sidebar-mobile-toggler text-center bg-dark border-0">
        <a href="#" class="sidebar-mobile-main-toggle">
          <i class="icon-arrow-left8" />
        </a>
      </div>
      <!-- /sidebar mobile toggler -->

      <!-- Sidebar content -->
      <div class="sidebar-content pb-3 pb-md-5">

        <div class="card card-sidebar-mobile position-relative">

          <i
            @click="toggleSidebar"
            :class="{
              'd-none d-md-block btn-sidebar-toggle bg-white shadow btn btn-block no-radius text-right p-0 text-center': true,
              'icon-chevron-left': isSidebarLeftOpened,
              'icon-chevron-right visible': !isSidebarLeftOpened,
            }"
          />

          <div
            v-if="modulesLoaded"
            class="pt-3"
          >

            <ul class="nav nav-sidebar" data-nav-type="accordion" v-for='(group, groupIndex) in menuLinksFormattedActive'>

              <li v-if="groupIndex == NO_GROUP" class="nav-item">
                <router-link v-for="link in group.links" tag="a" :active-class="isAuthFake ? '' : 'active'" :to="link.to" class="py-2 nav-link cursor-pointer" v-tooltip.right="isSidebarLeftOpened ? '' : link.title">
                  <i :class="'icon-' + link.icon" />
                  <span>{{ link.title }}</span>
                  <span v-if="ifLinkNew(link)" class="badge p-1 bg-info align-self-center ml-auto">NEW</span>
                </router-link>
              </li>

              <li
                v-else-if="groupIndex != GROUP_BOTTOM"
                :class="{
                  'nav-item nav-item-submenu': true,
                  'nav-item-open': openedSubMenus[groupIndex],
                }"
              >
                <a :class="{
                  'nav-link cursor-pointer': true,
                  'bg-light': groupIndex == activeGroup,
                }" @click="toggleSubMenu(groupIndex)">
                  <i :class="{
                    ['icon-' + groupConfig[groupIndex].icon]: true,
                    'text-primary': groupIndex == activeGroup,
                  }" />
                  <span>
                    {{ groupConfig[groupIndex].title }}
                  </span>
                </a>
                <ul class="nav nav-group-sub" :data-submenu-title="groupConfig[groupIndex].title">
                  <li v-for="link in group.links" class="nav-item">
                    <router-link
                      tag="a"
                      :active-class="isAuthFake ? '' : 'active'"
                      :to="link.to"
                      class="nav-link cursor-pointer"
                      v-if="!link.hide_in_sidebar"
                    >
                      <i :class="'icon-' + link.icon" />
                      <span>{{ link.title }}</span>
                      <span v-if="ifLinkNew(link)" class="badge p-1 bg-info align-self-center ml-auto">NEW</span>
                    </router-link>
                  </li>
                </ul>
              </li>

              <li v-if="groupIndex == GROUP_BOTTOM" class="nav-item">
                <router-link v-for="link in group.links" tag="a" :active-class="isAuthFake ? '' : 'active'" :to="link.to" class="py-2 nav-link cursor-pointer" v-tooltip.right="isSidebarLeftOpened ? '' : link.title">
                  <i :class="'icon-' + link.icon" />
                  <span>{{ link.title }}</span>
                  <span v-if="ifLinkNew(link)" class="badge p-1 bg-info align-self-center ml-auto">NEW</span>
                </router-link>
              </li>

            </ul>
          </div>
          <div v-else class="text-center py-4"><loader/></div>

        </div>

        <div class="card card-sidebar-mobile" v-if="anyFavorites && modulesLoaded">
          <ul class="nav nav-sidebar" data-nav-type="accordion">
            <!-- Main -->
            <li class="nav-item-header">
              <div class="text-uppercase">
                {{ $t('common.favorites') }}
              </div>
              <i v-tooltip.right="$t('common.favorites')" class="icon-star-full2 text-yellow" :title="$t('common.favorites')" />
            </li>

            <li v-if="user" v-for="(item, index) in worldFavorites" :key="index" class="nav-item">
              <router-link tag="a" :to="index" :active-class="isAuthFake ? '' : 'active'" class="nav-link" v-if="item.title.indexOf('World') === -1 || item.world_id === world.id" v-tooltip.right="isSidebarLeftOpened ? '' : item.title">
                <i :class="'icon-' + item.icon" v-if="item.icon" />
                <i class="icon-star-full2" v-if="!item.icon" />
                <span>
                  {{ item.title }}
                  <span class="font-weight-light" v-if="item.suffix">{{ item.suffix }}</span>
                </span>
              </router-link>
            </li>

          </ul>
        </div>
      </div>
      <!-- /sidebar content -->

    </div>
  </div>

</template>

<script>

import _ from 'lodash'

import Links from './Links'

export default {
  extends: Links,
  tag: 'el-sidebar-left',
  name: 'Sidebar-left',
  props: ['modulesLoaded'],
  data () {
    return {
      appName: process.env.VUE_APP_TITLE,
      openedSubMenus: {},
    }
  },
  mounted () {
    if (this.isAuthFake) {
      this.toggleSubMenu('people')
    }
  },
  computed: {
    isSidebarLeftOpened () {
      return this.$store.getters['global/isSidebarLeftOpened']
    },
    worldFavorites () {
      if (!this.user || !this.user.favorites) {
        return {}
      }
      return _.pickBy(this.user.favorites, (elem) => {
        return elem.world_id == this.world.id
      })
    },
    anyFavorites () {
      return _.size(this.worldFavorites) > 0
    },
  },
  methods: {
    toggleSubMenu (id) {
      if (this.openedSubMenus[id]) {
        this.$set(this.openedSubMenus, id, false)
      } else {
        this.$set(this.openedSubMenus, id, true)
      }
    },
    getPageTitle (str) {
      return this.$t('titles.' + _.snakeCase(str))
    },
    toggleSidebar () {
      this.$store.commit('global/changeSidebarLeft', !this.isSidebarLeftOpened)
    }
  },
  watch: {
    activeGroup (newVal) {
      this.$set(this.openedSubMenus, newVal, true)
    }
  }
}
</script>

<style scoped lang="scss">
.nav-sidebar .nav-item-header {
    font-size: 1.25em;
    font-weight: 700;
    letter-spacing: .1em;
}

.sidebar-xs .sidebar-main .nav-sidebar > .nav-item-header {
    width: 100%;
}

.nav-item-open.nav-item-submenu > .nav-group-sub {
  display: block !important;
}

.sidebar-content {
  .btn-sidebar-toggle {
    transform: scale(0);
    position: absolute;
    z-index: 2;
    right: -10px;
    top: 20px;
    border-radius: 50px !important;
    line-height: 28px;
    height: 28px;
    width: 28px;
    border: 1px solid #ccc !important;
    transition: all .2s ease;
    opacity: 0;
    &:hover {
      border-color: #999 !important;
    }
    &.visible {
      transform: scale(1);
      opacity: 1;
    }
  }
  &:hover {
    .btn-sidebar-toggle {
      transform: scale(1);
      opacity: 1;
    }
  }
}
</style>
