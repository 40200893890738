import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']

let links = [
  {
    to: { name: 'process.template.index', params: { world: world.id } },
    module: 'process',
    permission: {
        'module': 'process',
        'name': 'view_module',
    },
    title: 'modules.process.process.templates.title',
    weight: 40,
    group: 'handbook',
    image: require('./assets/images/process.svg'),
    description: 'modules.process.process.module_desc',
  },
  {
    to: { name: 'form.template.index', params: { world: world.id } },
    module: 'process',
    permission: {
        'module': 'process',
        'name': 'view_module',
    },
    title: 'modules.process.form.templates.title',
    weight: 50,
    group: 'handbook',
    image: require('./assets/images/form.svg'),
    description: 'modules.process.form.module_desc',
  },
]

export default links
