<template>

  <div
    :class="`d-inline-block btn rounded px-1 py-0 btn-sm btn-${color}`"
    @click="btnClick"
  >
    <i v-if="icon" :class="`icon-${icon}`"/>
    {{ title }}
  </div>

</template>

<script>
import { USER_NOTIFICATIONS_POST_READ } from '../../store/mutations-types'

export default {
  tag: 'el-notification-button',
  name: 'Notification-button',
  props: {
    notification: {
      type: Object,
      required: true,
    },
    icon: {
      // type: [String, null],
      default: null,
    },
    title: {
      required: true,
      type: String,
    },
    color: {
      type: String,
      default: 'success',
    },
    callback: {
      type: Function,
    },
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    markAsRead () {
      if (this.notification.read_at == null) {
        this.$store.dispatch('auth/' + USER_NOTIFICATIONS_POST_READ, this.notification.id)
      }
    },
    btnClick () {
      this.markAsRead()
      if (typeof(this.callback) == 'function') {
        this.callback(this.notification)
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
