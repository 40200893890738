/* eslint-disable promise/param-names */

import {
GLOBAL_DATA,
GLOBAL_SUBSCRIPTIONS,
GLOBAL_BANNERS,
GLOBAL_ACCESS,
GLOBAL_TRANSLATIONS,
} from '../mutations-types'

import Api from '../../api'

const state = {
  sidebarSearch: '',
  sidebarFilters: {},
  modalOpened: null,
  currentPageCategory: null,
  lastPageCategory: null,
  sidebarLeft: true,
  sidebarRight: true,
  modules: null,
  banners: null,
  translations: null,
  bannersRefreshTime: null,
  translationsRefreshTime: null,
}

const getters = {
  getcurrentPageCategory: state => state.currentPageCategory,
  getBanners: state => state.banners,
  getTranslations: state => state.translations,
  bannersRefreshTime: state => state.bannersRefreshTime,
  translationsRefreshTime: state => state.translationsRefreshTime,
  currentModal: state => state.modalOpened,
  currentFilters: state => state.sidebarFilters,
  isSidebarLeftOpened: state => state.sidebarLeft,
  isSidebarRightOpened: state => state.sidebarRight,
  areFiltersClean: state => {
    let clean = true
    if(state.sidebarSearch != '') {
      clean = false
    } else {
      Object.keys(state.sidebarFilters).forEach( elem => {
        if(state.sidebarFilters[elem].length > 0) {
          clean = false
        }
      })
    }
    return clean
  }
}

const actions = {

  [GLOBAL_DATA]: () => {
    return Api.global.data()
      .then(resp => {
        return resp.data.success
      })
  },

  [GLOBAL_SUBSCRIPTIONS]: () => {
    return Api.global.subscriptions()
      .then(resp => {
        return resp.data.success
      })
  },

  [GLOBAL_TRANSLATIONS]: ({commit}) => {
    return Api.global.translations()
      .then(resp => {
        commit(GLOBAL_TRANSLATIONS, resp.data.success)
        return resp.data.success
      })
  },

  [GLOBAL_ACCESS]: ({}, data) => {
    return Api.global.askAccess(data)
      .then(resp => {
        return resp.data.success
      })
  },

  [GLOBAL_BANNERS]: ({commit}, worldId) => {
    return Api.global.banners(worldId)
      .then(resp => {
        commit(GLOBAL_BANNERS, resp.data.success)
        return resp.data.success
      })
  },
}

const mutations = {
  changePage (state, path) {

    if (window.innerWidth < 768) {
      state.sidebarLeft = false
    }

    state.lastPageCategory = state.currentPageCategory

    const newPath = path.replace(/^\//, '').split('/')

    let category = null

    if (newPath[0] != 'world') {
      category = newPath[0]
    } else if (newPath[2] == 'm') {
      category = newPath[3]
    } else {
      category = newPath[2]
    }

    state.currentPageCategory = category
    if (state.currentPageCategory != state.lastPageCategory) {
      state.sidebarSearch = ''
      state.sidebarFilters = {}
    }
  },
  changeSidebarSearch (state, val) {
    state.sidebarSearch = val
  },
  changeSidebarFilters (state, val) {
    state.sidebarFilters = val
  },
  addSidebarFilter (state, filter) {
    state.sidebarFilters[filter.key].push(filter.value)
  },
  changeSidebarLeft (state, val) {
    state.sidebarLeft = val
  },
  changeSidebarRight (state, val) {
    state.sidebarRight = val
  },
  openModal (state, id) {
    state.modalOpened = id
  },
  [GLOBAL_BANNERS]: (state, val) => {
    state.banners = val
    state.bannersRefreshTime = new Date().getTime()
  },
  [GLOBAL_TRANSLATIONS]: (state, val) => {
    state.translations = val
    state.translationsRefreshTime = new Date().getTime()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
