import instance from './_instance'

export default {

  data () {
    return instance
      .get('data')
  },

  subscriptions () {
    return instance
      .get('subscriptions')
  },

  askAccess (data) {
    return instance
      .post('access', data)
  },

  banners (worldId) {
    return instance
      .get(`worlds/${worldId}/banners/banner`)
  },

  translations (worldId) {
    return instance
      .get('translations')
  },

}
