<template>

  <select
    class="form-control select"
    :multiple="multiple"
  >
    <option v-if="nullOption"></option>
    <option
      v-for="(option, optionKey) in options"
      :key="(keys && keys.value) ? option[keys.value] : option[defaultKey.value]"
      :value="(keys && keys.value) ? option[keys.value] : option[defaultKey.value]"
      :data-avatar="(keys && keys.avatar) ? option[keys.avatar] : option[defaultKey.avatar]"
      :data-icon="(keys && keys.icon) ? option[keys.icon] : option[defaultKey.icon]"
      :data-detail="(keys && keys.detail) ? option[keys.detail] : option[defaultKey.detail]"
      :data-color="(keys && keys.color) ? option[keys.color] : option[defaultKey.color]"
    >
      {{ keys && keys.title ? option[keys.title] : option[defaultKey.title] }}
    </option>
  </select>

</template>

<script>
import select2 from 'select2'
import _ from 'lodash'

function formatTemplate(elem) {
    let label = ''
    if ($(elem.element).data('detail') && $(elem.element).data('detail') !== '[object Object]') {
      let color = 'light'
      if ($(elem.element).data('color')) {
        color = $(elem.element).data('color')
      }
      label+= '<span class="float-right badge badge-' + color + '">' + $(elem.element).data('detail') + '</span>'
    }
    if ($(elem.element).data('icon') && $(elem.element).data('icon') !== '[object Object]') {
      label+= '<i class="icon-' + $(elem.element).data('icon') + '"></i>'
    }
    if ($(elem.element).data('avatar') && $(elem.element).data('avatar') !== '[object Object]') {
      label+= '<img class="mr-1 avatar rounded-circle" src="' + $(elem.element).data('avatar') + '"/>'
    }
    label+= elem.text;
    return label
}

/**
 * @group Core
 * Display select plugin
 */
export default {
  tag: 'el-select',
  name: 'Select2',
  props: {
    theme: {
      type: String,
      default: '',
    },
    tags: {
      type: Boolean,
    },
    createTag: {
      type: Function,
    },
    callback: {
      type: Function,
    },
    multiple: {
      type: Boolean,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    nullOption: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Array, Object],
    },
    value: {
      type: [String, Number, Boolean, Array],
    },
    placeholder: {
      type: String,
    },
    keys: {
      type: [Array, Object],
    }
  },
  data () {
    return {
      externalChange: false,
      defaultKey: {
        title: 'title',
        value: 'value',
        avatar: 'avatar',
        icon: 'icon',
        detail: 'detail',
        color: 'color',
      }
    }
  },
  mounted () {
    this.setPlugin()
  },
  computed: {
     _() {
       return _
    },
    optionsKeys () {
      let options = _.toArray(this.options)
      return options.map(option => {
        let val = (this.keys && this.keys.value) ? option[this.keys.value] : option[this.defaultKey.value]
        return val.toString()
      })
    },
    optionsWatch () {
      return Object.assign({}, this.options)
    }
  },
  methods: {
    // @vuese
    // Remove select2 jQuery plugin
    unsetPlugin () {
      $(this.$el).off().select2('destroy')
    },
    // @vuese
    // Initialize select2 jQuery plugin
    setPlugin () {

      let select = $(this.$el)
      // init select2
      select
        .select2({
          placeholder: this.placeholder ? this.placeholder : this.$t('common.select'),
          allowClear: this.allowClear,
          tags: this.tags,
          tokenSeparators: [','],
          templateResult: formatTemplate,
          templateSelection: formatTemplate,
          theme: this.theme,
          escapeMarkup: function(m) { return m; }
        })
        .val(this.value)
        .trigger("change")
        // emit event on change.
        .on('change', (e) => {
          if (this.externalChange) {
            return
          }

          if (this.callback && select.val() != null && select.val() != '') {
            this.callback(select.val())
          }

          // Fire when the value is changed
          // @arg The argument is the selected value
          this.$emit('input', select.val())
        })
        .on("select2:select", e => {

          if (!_.includes(this.optionsKeys, e.params.data.id) && this.createTag) {
            this.createTag(e.params.data.id)
          }

        })
    },
    resetPlugin () {
      this.unsetPlugin()
      this.setPlugin()
    }
  },
  watch: {
    // @vuese
    // Watch value to sync internal and external changed
    value: function (value) {
      this.externalChange = true
      // update value
      $(this.$el)
        .val(value)
        .trigger('change')
      this.externalChange = false
    },
    options: {
      deep: true,
      handler: function (val, oldVal) {
        this.unsetPlugin()
        this.$forceUpdate()
        this.setPlugin()
      },
    },
  },
  // @vuese
  // Completely remove select jQuery plugin
  destroyed: function () {
    $(this.$el).off().select2('destroy')
  },
}
</script>

<style lang="scss">
.select2-container {
  width: 100% !important;
  .avatar {
    width: 22px;
    height: 22px;
    object-fit: cover;
  }
}
.select2-results__option {
  min-height: 36px;
}
.select2-results__option {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select2-container--simplified {
  .select2-selection {
    border: none;
    padding: 0;
    .select2-selection__rendered {
      padding: 0 2px;
    }
    .select2-selection__arrow:after {
      right: 2px;
    }
  }
  .select2-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .select2-search {
      padding: 0;
      .select2-search__field {
        border-width: 0;
        border-bottom-width: 1px;
        padding-left: 32px;
      }
    }
    .select2-search--dropdown:after {
        left: 10px;
    }
  }
}
.select2-container--emoji {
  .select2-container--simplified {
    .select2-selection {
      border: none;
      padding: 0;
      .select2-selection__rendered {
        padding: 0 2px;
      }
    }
  }
}
</style>
