export const ACADEMY_COURSE_INDEX = 'Academy Get Courses Index'
export const ACADEMY_COURSE_GET = 'Academy Get Course'
export const ACADEMY_COURSE_SET = 'Academy Set Course'
export const ACADEMY_COURSE_CREATE = 'Academy Create Course'
export const ACADEMY_COURSE_EDIT = 'Academy Edit Course'
export const ACADEMY_COURSE_DELETE = 'Academy Delete Course'
export const ACADEMY_COURSE_COMPLETE = 'Academy Complete Course'
export const ACADEMY_COURSE_GET_COMPLETED = 'Academy Get Completed Courses'
export const ACADEMY_COURSE_GET_SHEETS = 'Academy Get Sheets'

