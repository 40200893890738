import actions from '../../src/router/actions'

const routes = [
  {
    path: '/world/:world/presence/list',
    name: 'presence.index',
    component: () => import(/* webpackChunkName: "module-presence" */ './pages/PresenceList.vue'),
    beforeEnter: actions.needAuthComplete,
    meta: {
      icon: 'calendar2',
    }
  },
];

export default routes
