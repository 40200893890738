import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']
import Vue from '@/main.js'

let links = [
  {
    to: { name: 'presence.index', params: { world: world.id } },
    module: 'presence',
    title: 'modules.presence.title',
    weight: 40,
    group: 'people',
    description: 'modules.presence.module_desc',
    condition: (Vue.userHasPermission('presence', 'view_module') || Vue.userHasPermission('presence', 'manage_own') || Vue.userHasPermission('presence', 'manage_module')),
  },
]

export default links
