<template>

  <fragment>
    <container :is="component.tag" :key="componentKey" v-for="(component, componentKey) in components" />
  </fragment>

</template>

<script>

import _ from 'lodash'

let components = {}
const requireComponent = require.context(
  // The relative path of the components folder
  '../../../Modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /navbar.vue$/
)

requireComponent.keys().reverse().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  let tag = componentConfig.default.tag
  components[tag] = componentConfig.default || componentConfig
})

export default {
  components,
  tag: 'el-navbar-modules',
  name: 'Navbar-modules',
  data () {
    return {
      components: components
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
