export const HOSTINGS_SET = 'Hostings Set'
export const HOSTING_SET = 'Hosting Set'
export const HOSTING_GET = 'Hosting Get'
export const HOSTINGS_REQUEST = 'Hostings Request'
export const HOSTING_STORE = 'Hosting Store'
export const HOSTING_UPDATE = 'Hosting Update'
export const HOSTING_REMOVE = 'Hosting Remove'
export const HOSTING_REVISIONS = 'Hosting Revision'

export const HOSTING_BACKUP_NEW = 'Hosting Backup New'
