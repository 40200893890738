import store from '../store'
import qs from 'qs'

let actions = {
  needAuthComplete: (to, from, next) => {

    // check subscription
    // if (
    //   store.getters['auth/hasWorld'] &&
    //   !store.getters['auth/worldOnTrial'] &&
    //   !store.getters['auth/worldHasSubscription']
    // ) {
    //   if (store.getters['auth/isWorldOwner']) {
    //     next('/world/' + store.getters['auth/getWorld'].id + '/subscription')
    //   }
    // }

    // If user owns the world, need phone to be verified
    let phoneOK = false
    if (store.getters['auth/isWorldOwner'] === false) {
      phoneOK = true
    } else if (store.getters['auth/isWorldOwner'] && store.getters['auth/isPhoneVerified']) {
      phoneOK = true
    }

    let phoneNeedsValidation = false

    if (store.getters['auth/isAuthenticated'] && (phoneOK || !phoneNeedsValidation)) {
      next()
      return
    } else if(store.getters['auth/isAuthenticated'] && !phoneOK) {
      next('/phone_verification')
      return
    }
    // If false

    let queryStringFilters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (queryStringFilters.redirect && queryStringFilters.redirect != '') {
      next('/login?redirect=' + queryStringFilters.redirect)
    } else {
      let prevRoutePath = from ? from.path : '/'
      next('/login?redirect=' + prevRoutePath.substring(1))
    }

  },

  needAnonymous: (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
      next()
      return
    }

    let queryStringFilters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (queryStringFilters.redirect && queryStringFilters.redirect != '') {
      next('/' + queryStringFilters.redirect)
      return
    }

    if (store.getters['auth/isAuthenticated']) {
      next('/')
      return
    } else {
      next('/login?redirect=' + to.path.substring(1))
    }

  },

  needAuthWithoutPhone: (to, from, next) => {
    if(store.getters['auth/isAuthenticated'] && !store.getters['auth/isPhoneVerified']) {
      next()
      return
    }

    if (store.getters['auth/isAuthenticated']) {
      next('/')
      return
    } else {
      next('/login?redirect=' + to.path.substring(1))
    }

  },

  needWorldOwner: (to, from, next) => {
    if(store.getters['auth/isAuthenticated'] && store.getters['auth/isWorldOwner']) {
      next()
      return
    }

    if (store.getters['auth/isAuthenticated']) {
      next('/')
      return
    } else {
      next('/login?redirect=' + to.path.substring(1))
    }
  },

  needSuperAdmin: (to, from, next) => {
    if(store.getters['auth/isAuthenticated'] && store.getters['auth/getProfile'].roles_slugs && store.getters['auth/getProfile'].roles_slugs.includes('admin')) {
      next()
      return
    }

    if (store.getters['auth/isAuthenticated']) {
      next('/')
      return
    } else {
      next('/login?redirect=' + to.path.substring(1))
    }

  },

}
export default actions
